import { TariffFormData } from '../types';
import { Season, SeasonFormData } from './SeasonConfigurationFormDialog';

/**
 * Maps values from a generic `TariffFormData` structure to one supported by the season configuration form
 *
 * @param values - The values from the original form
 * @returns The values mapped to the season configuration form
 */
export function mapValuesToSeasonForm(values: TariffFormData): SeasonFormData {
  return {
    shouldApplySeasons: values.shouldApplySeasons,
    seasons: values.seasons.map((s) => {
      const fromDate = new Date();
      fromDate.setFullYear(2021);
      fromDate.setMonth(s.fromMonth);
      fromDate.setDate(s.fromDate);
      fromDate.setHours(0, 0, 0, 0);
      const toDate = new Date();
      toDate.setFullYear(2021);
      toDate.setMonth(s.toMonth);
      toDate.setDate(s.toDate);
      toDate.setHours(0, 0, 0, 0);

      return {
        name: s.name,
        fromDate,
        toDate,
      };
    }),
  };
}

/**
 * Validates that the seasons cover the entire year without overlap.
 * This handles cases where seasons cross the year boundary.
 * This function is primarily intended to be used with yup.
 *
 * @param seasons - The seasons to validate.
 * @returns A boolean determining whether validation was successful.
 */
export function validateSeasons(seasons: Season[]) {
  // Normalize seasons so that yearly overlap is captured by incrementing the "to date"'s year
  const normalizedSeasons = seasons.map((season) => {
    const newToDate = new Date(season.toDate);
    return {
      ...season,
      normalizedToDate:
        season.fromDate.getMonth() > season.toDate.getMonth()
          ? new Date(newToDate.setFullYear(newToDate.getFullYear() + 1))
          : season.toDate,
    };
  });
  // Sort by earliest -> latest from dates
  normalizedSeasons.sort((a, b) => a.fromDate.getTime() - b.fromDate.getTime());

  // Used to track full 365 day coverage in a year
  let totalCoverageDays = 0;

  let index = 0;
  for (const season of normalizedSeasons) {
    // Convert covered period to days
    const seasonCoverage = (season.normalizedToDate.getTime() - season.fromDate.getTime()) / (1000 * 60 * 60 * 24) + 1;
    totalCoverageDays += seasonCoverage;

    if (index > 0) {
      const previousSeason = normalizedSeasons[index - 1];

      if (season.fromDate >= previousSeason.fromDate && season.fromDate <= previousSeason.toDate) {
        console.log('Season overlap detected');
        return false;
      }
    }

    index++;
  }

  // Check if the total coverage spans exactly 365 days (not accounting for leap years explicitly)
  if (totalCoverageDays !== 365) {
    console.log('Seasons do not cover full year');
    return false;
  }

  return true;
}
