import { parse } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

import { PreciseRangeValueObject } from '../calculations/date-utils';

/**
 * Formats a period object into a string formatted as:
 * {years} Year/s, {months} Month/s, {day} Day/s
 *
 * Note that where a value is 0, it is omitted, e.g. one year and 2 days instead of one year, 0 months, 2 days
 *
 * @param period - The period object, provided by DateFns.
 */
export function formatDaysMonthsYears(period: PreciseRangeValueObject): string {
  let result = formatMonthsYears(period);

  if (period.days > 0) {
    result += `${period.years > 0 || period.months > 0 ? ', ' : ''}${period.days} Day${period.days === 1 ? '' : 's'}`;
  }

  return result;
}

/**
 * Formats a period object into a string formatted as:
 * {years} Year/s, {months} Month/s
 *
 * @param period - The period object, provided by DateFns.
 */
export function formatMonthsYears(period: PreciseRangeValueObject): string {
  let result = '';

  if (period.years > 0) {
    result += `${period.years} Year${period.years === 1 ? '' : 's'}`;
  }

  if (period.months > 0) {
    result += `${period.years > 0 ? ', ' : ''}${period.months} Month${period.months === 1 ? '' : 's'}`;
  }

  return result;
}

/**
 * Formats a full period object, including hours, minutes and seconds.
 *
 * @param period - The period object to format.
 */
export function formatFullPeriod(period: PreciseRangeValueObject): string {
  let result = formatDaysMonthsYears(period);

  if (period.hours > 0) {
    result += `${period.days > 0 ? ', ' : ''}${period.hours} hour${period.hours === 1 ? '' : 's'}`;
  }

  if (period.minutes > 0) {
    result += `${period.hours > 0 ? ', ' : ''}${period.minutes} minute${period.minutes === 1 ? '' : 's'}`;
  }

  if (period.seconds > 0) {
    result += `${period.minutes > 0 ? ', ' : ''}${period.seconds} second${period.seconds === 1 ? '' : 's'}`;
  }

  if (!result) {
    result = '0 seconds';
  }

  return result;
}

/**
 * Formats a date to 12 hour time.
 *
 * @param date
 */
export function formatTo12HourTime(date: Date) {
  const minutes = date.getMinutes();
  let hours = date.getHours();
  let isPM = false;

  if (hours >= 12) {
    isPM = true;
    hours -= 12;
  }

  if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${('0' + minutes).slice(-2)} ${isPM ? 'pm' : 'am'}`;
}

/**
 * Formats a `Date` object, according to the string structure provided from the API.
 *
 * @param date - The date object to format.
 */
export function formatDate(date: Date): string {
  return `${date.getFullYear()}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
    date
  )}-${new Intl.DateTimeFormat('en', {
    day: '2-digit',
  }).format(date)}`;
}

/**
 * Formats a `Date` object, according to the date time string structure provided from the API.
 *
 * @param date - The date object to format.
 */
export function formatDateTime(date: Date): string {
  const dateString = formatDate(date);
  const timeString = `${date.getHours()}:${date.getMinutes().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${date.getSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;

  return `${dateString} ${timeString}`;
}

/**
 * Converts a datetime object or string from one specified timezone to another.
 *
 * @param datetime - The original datetime to convert, as a string or Date.
 * @param originalTimezone - The timezone to convert from.
 * @param newTimezone - The timezone to convert to.
 */
export function convertBetweenTimezones(datetime: Date | string, originalTimezone: string, newTimezone: string): Date {
  if (typeof datetime === 'string' && !/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/.test(datetime))
    throw new Error('Invalid date time string supplied.');
  // Convert from specified timezone to UTC, then from UTC to local timezone
  const dateInUTC = fromZonedTime(datetime, originalTimezone);
  return toZonedTime(dateInUTC, newTimezone);
}

/**
 * Formats the time value on a `Date` object, ensuring consistency across browsers as `toLocaleTimeString` won't.
 *
 * @param date - The date object to format into a string.
 */
export function formatTime(date: Date, includeSeconds = true): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const secs = date.getSeconds();
  const formattedSecs = secs < 10 ? '0' + secs.toString() : secs;
  const amOrPM = hours < 12 ? 'am' : 'pm';

  if (hours > 12) {
    hours -= 12;
  }

  return `${hours}:${minutes < 10 ? '0' + minutes.toString() : minutes}${
    includeSeconds ? `:${formattedSecs}` : ''
  } ${amOrPM}`;
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param toCapitalize - String to capitalize.
 */
export function capitalizeFirst(toCapitalize: string): string {
  return toCapitalize.charAt(0).toUpperCase() + toCapitalize.slice(1);
}

export enum DateStringFormatType {
  DayShortMonthYear,
  DayLongMonthYear,
  DayMonthYear,
  DayLongMonthNoYear,
}

export const dateTypeToFormatOpts: Record<DateStringFormatType, object> = {
  [DateStringFormatType.DayShortMonthYear]: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [DateStringFormatType.DayLongMonthNoYear]: {
    day: 'numeric',
    month: 'long',
  },
  [DateStringFormatType.DayLongMonthYear]: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  [DateStringFormatType.DayMonthYear]: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
};

/**
 * Formats a date string from the API to a nice format, specified by the `format` parameter.
 *
 * @param dateString - The date string type.
 * @param format - The format enum type.
 * @param locales - One or more locales to format the date string with. Optional.
 */
export function formatNiceDateFromString(
  dateString: string | null,
  format: DateStringFormatType = DateStringFormatType.DayShortMonthYear,
  locales?: string | string[]
): string {
  if (!dateString) return '';

  // First, format the date string so it's uniformly formatted across browsers. Safari sucks.
  const formattedDateString = dateString.replace(/-/g, '/');
  return formatNiceDate(new Date(formattedDateString), format, locales);
}

/**
 * Formats a `Date` object to a nice format, specified by the `format` parameter.
 *
 * @param date - The `Date` object to format.
 * @param format - The format enum type.
 * @param locales - One or more locales to format the date string with. Optional.
 */
export function formatNiceDate(
  date: Date | null,
  format: DateStringFormatType = DateStringFormatType.DayShortMonthYear,
  locales?: string | string[]
): string {
  if (!date) return '';

  return date.toLocaleDateString(locales, dateTypeToFormatOpts[format]);
}

/**
 * This parses a date string into a Date object.
 * This should work across all browsers, unlike the quirks of Date constructor.
 *
 * @param dateString - the date string to parse
 * @param dateFormat - optional, defaults to 'yyyy-MM-dd'
 * @returns a Date object
 */
export function parseDate(dateString: string, dateFormat = 'yyyy-MM-dd') {
  return parse(dateString, dateFormat, new Date());
}
