import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ImInfo } from 'react-icons/im';
import { useSelector } from 'react-redux';

import Card from 'clipsal-cortex-ui/src/components/card/Card';

import GenericErrorCard from '../../../common/components/GenericErrorCard';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { selectSite } from '../../site/siteSlice';
import { useGetSiteBillsQuery } from '../billsApi';
import { NoBills, PaginatedBillsList } from './PaginatedBillsList';

interface Props {
  readonly onOpenUploadBillDialog: () => void;
}

export default function PastBills({ onOpenUploadBillDialog }: Props) {
  const site = useSelector(selectSite);
  const { data: bills, isLoading, isError } = useGetSiteBillsQuery(site.site_id);
  const { isDesktopViewport } = useViewportType();

  const billsListContents = bills?.length ? (
    <PaginatedBillsList bills={bills} />
  ) : (
    <NoBills onOpenUploadBillDialog={onOpenUploadBillDialog} />
  );

  if (isError) {
    return <GenericErrorCard>Error loading past bills. Try reloading the page.</GenericErrorCard>;
  }

  return (
    <Card>
      <Flex justify="space-between">
        <Flex align={'flex-start'}>
          <Heading mr={2} size={'md'}>
            Past Bills
          </Heading>

          {isDesktopViewport ? (
            <PastBillsInfoPopover onOpenUploadBillDialog={onOpenUploadBillDialog} />
          ) : (
            <PastBillsInfoModal onOpenUploadBillDialog={onOpenUploadBillDialog} />
          )}
        </Flex>

        {!isLoading && bills && bills.length > 0 && (
          <Button onClick={onOpenUploadBillDialog} mb={2} rounded={20} colorScheme="dusk100" isDisabled={IS_DEMO_LOGIN}>
            Upload Bills
          </Button>
        )}
      </Flex>

      {!isLoading ? (
        billsListContents
      ) : (
        <>
          <Skeleton my={1} w={'15%'} h={3} isLoaded={false} />
          <Skeleton my={1} w={'100%'} h={5} isLoaded={false} />
          <Skeleton my={1} w={'100%'} h={5} isLoaded={false} />
          <Skeleton my={1} w={'15%'} h={3} isLoaded={false} />
          <Skeleton my={1} w={'100%'} h={5} isLoaded={false} />
          <Skeleton my={1} w={'100%'} h={4} isLoaded={false} />
          <Skeleton mt={4} w={'100%'} h={4} isLoaded={false} />
        </>
      )}
    </Card>
  );
}

function PastBillsInfoPopover({ onOpenUploadBillDialog }: { onOpenUploadBillDialog: () => void }) {
  return (
    <Popover trigger={'hover'}>
      <PopoverTrigger>
        <Box mt={1} as={'button'}>
          <Box as={ImInfo} color={'customBlue.500'} size="18px" />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxWidth="400px" data-testid="past-bills-popover" p={2} rounded={20} w="inherit" zIndex={4}>
          <PopoverArrow />
          <PopoverBody>
            <PastBillsInfoContents onOpenUploadBillDialog={onOpenUploadBillDialog} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

function PastBillsInfoModal({ onOpenUploadBillDialog }: { onOpenUploadBillDialog: () => void }) {
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure();

  const handleOpenUploadBillDialog = () => {
    onClose();
    onOpenUploadBillDialog();
  };
  return (
    <>
      <Box mt={1} onClick={onOpen} as={'button'}>
        <Box as={ImInfo} color={'customBlue.500'} size="18px" />
      </Box>

      <Modal motionPreset="slideInBottom" isCentered scrollBehavior={'inside'} isOpen={isModalOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent data-testid="past-bills-modal" rounded={20}>
          <ModalHeader />
          <ModalCloseButton data-testid="past-bills-modal-close-btn" />
          <ModalBody>
            <PastBillsInfoContents onOpenUploadBillDialog={handleOpenUploadBillDialog} isInModal />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}

function PastBillsInfoContents({
  isInModal,
  onOpenUploadBillDialog,
}: {
  isInModal?: boolean;
  onOpenUploadBillDialog: () => void;
}) {
  return (
    <Box textAlign="center">
      <Heading mb={2} size="md">
        Upload bills to show bill history
      </Heading>
      <Flex height={'100%'} direction={'column'} justify={'center'} align={'center'}>
        <Text mb={4}>Upload your bills to get more bill data displayed</Text>

        <Button
          width={isInModal ? '100%' : undefined}
          onClick={onOpenUploadBillDialog}
          mb={2}
          rounded={20}
          colorScheme="dusk100"
          isDisabled={IS_DEMO_LOGIN}
        >
          Upload Bills
        </Button>
      </Flex>
    </Box>
  );
}
