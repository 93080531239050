import { Retailer } from './api-retailer';

export const TARIFF_TYPES = ['FLAT', 'TIERED', 'TOU', 'REAL_TIME'] as const;
export type TariffType = (typeof TARIFF_TYPES)[number];

export const CHARGE_TYPES = [
  'FIXED_PRICE',
  'CONSUMPTION_BASED',
  'DEMAND_BASED',
  'MINIMUM',
  'MAXIMUM',
  'TAX',
  'REAL_TIME',
] as const;
export type ChargeType = (typeof CHARGE_TYPES)[number];

export const CHARGE_CLASSES = [
  'SUPPLY',
  'DEDICATED_CIRCUIT_1',
  'DEDICATED_CIRCUIT_2',
  'TRANSMISSION',
  'DISTRIBUTION',
  'FEES',
] as const;
export type ChargeClass = (typeof CHARGE_CLASSES)[number];

export const TRANSACTION_TYPES = ['BUY', 'SELL', 'NET', 'BUY_IMPORT', 'SELL_EXPORT'] as const;
export type TransactionType = (typeof TRANSACTION_TYPES)[number];

export const CHARGE_PERIODS = ['DAILY', 'MONTHLY', 'QUARTERLY', 'ANNUALLY'] as const;
export type ChargePeriod = (typeof CHARGE_PERIODS)[number];

export const DAY_OF_WEEK = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'] as const;
export type DayOfWeek = (typeof DAY_OF_WEEK)[number];

export const TOU_RATE_TYPE = ['PEAK', 'PARTIAL_PEAK', 'OFF_PEAK', 'SINGLE'] as const;
export type TOURateType = (typeof TOU_RATE_TYPE)[number];

export const DAY_TYPES = ['weekday', 'weekend', 'allWeek'] as const;
export type DayType = (typeof DAY_TYPES)[number];

export type RateBand = {
  id?: number;
  sequence_number: number;
  rate: number;
  has_consumption_limit?: boolean;
  consumption_upper_limit?: number | null;
  has_demand_limit?: boolean;
  demand_upper_limit?: number | null;
};

export type TOUPeriod = {
  days: DayOfWeek[];
  from_hour: number; // 0-24
  to_hour: number; // 0-24
  from_minute: number; // 0-59
  to_minute: number; // 0-59
  public_holiday?: boolean;
};

export type TimeOfUse = {
  tou_name: string;
  tou_rate_type?: TOURateType | null;
  periods: TOUPeriod[];
};

export type TariffRate = {
  id?: number;
  season_index?: number; // @TODO: this can't ever exist here can it?
  season?: TariffSeason | null;
  charge_period?: ChargePeriod | null;
  charge_type: ChargeType;
  charge_class: ChargeClass;
  transaction_type?: TransactionType | null;
  time_of_use?: TimeOfUse | null;
  rate_bands: RateBand[];
};

// NOTE: 'from_date' and 'from_month' are always EXCLUSIVE in the API
export type TariffSeason = {
  name: string;
  tariff_id?: number;
  season_index: number;
  from_month: number;
  from_date: number;
  to_month: number;
  to_date: number;
};

export type SiteTariff = {
  id?: number;
  tariff_effective_date: string;
  tariff: Tariff;
};

export type SiteTariffToSave = {
  id?: number;
  tariff_effective_date: string;
  tariff: Partial<Tariff> & Required<Pick<Tariff, 'rates'>>;
};

export type Tariff = {
  id?: number;
  plan_name?: string;
  is_default_tariff: boolean;
  tariff_type: TariffType;
  seasons?: TariffSeason[];
  rates: TariffRate[];
  retailer_id?: number | null;
  retailer: Retailer;
  distributor_id?: number;
  distributor: Distributor;
  holiday_country?: string | null;
  holiday_subdiv?: string | null;
  line_item_modifiers?: LineItemModifier[];
};

export type Distributor = {
  id: number;
  distributor_name?: string;
};

export type LineItemModifier = {
  id?: number;
  amount: number;
  name: string;
  discount_type: DiscountType | null;
  modifier_type: 'TAX' | 'DISCOUNT';
};

export type DiscountType = 'USAGE' | 'TOTAL_BILL' | 'SUPPLY';

export type Utility = {
  eid: number;
  name: string;
  state: string;
};

export type RetailRate = {
  id: string;
  rate_structure: {
    name: string;
    frequency: 'hourly' | 'daily' | 'monthly' | 'annual';
    availability: string[];
  }[];
  utility_id: number;
  rate_name: string;
  utility_name: string;
  tariff_code: string;
  zone: string;
};
