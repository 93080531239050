import React from 'react';
import { Box } from '@chakra-ui/react';
import { chakraComponents, GroupBase, MenuProps, Select } from 'chakra-react-select';
import { Controller, useFormContext } from 'react-hook-form';

import { CHARGE_PERIOD_SELECT_OPTIONS } from './constants';
import { ChargePeriodSelectOption, TieredTariffFormData } from './types';

type Props = {
  seasonIndex: number;
};

export default function ChargePeriodSelect({ seasonIndex }: Props) {
  const { control } = useFormContext<TieredTariffFormData>();

  return (
    <Controller
      control={control}
      name={`seasons.${seasonIndex}.chargePeriod`}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <Box data-testid={`season-${seasonIndex}-charge-period`}>
            <Select
              ref={ref}
              components={{
                // Overrides the ID for the menu and options to facilitate testing
                Menu: (menuProps: MenuProps<ChargePeriodSelectOption, true, GroupBase<ChargePeriodSelectOption>>) => (
                  <chakraComponents.Menu className="discount-select-menu portal-react-select-menu" {...menuProps} />
                ),
              }}
              onChange={onChange}
              isSearchable={false}
              placeholder={'Select a charge period..'}
              onBlur={onBlur}
              // Not specifying portal target can cause the menu to become hidden.
              menuPortalTarget={document.body}
              options={CHARGE_PERIOD_SELECT_OPTIONS}
              value={value}
              useBasicStyles
            />
          </Box>
        );
      }}
    />
  );
}
