import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { selectSite } from '../../../site/siteSlice';
import { selectUser } from '../../../user/userSlice';
import { useTariffFormContext } from '../tariff-form-context';
import { BasicDetailsFormData } from './types';

const DEFAULT_BASIC_DETAILS_FORM_VALUE: Partial<BasicDetailsFormData> = {
  tariffType: 'FLAT',
};

/**
 * Helps discern the initial form values, which can sometimes be pre-saved in local storage.
 */
export function useInitialFormValues(): Partial<BasicDetailsFormData> {
  const site = useSelector(selectSite);
  const {
    formData: { basicDetails: basicDetailsData },
  } = useTariffFormContext();

  let initialFormValues = basicDetailsData;

  const localStorageValue = localStorage.getItem(`siteNewTariffCachedFormData__${site.site_id}`);
  if (localStorageValue) {
    // NOTE: Need to turn the serialized date string back into a `Date` object
    const deserializedResult = JSON.parse(localStorageValue);
    if (deserializedResult.startDate) deserializedResult.startDate = new Date(deserializedResult.startDate);
    initialFormValues = deserializedResult;
  }

  return initialFormValues ?? DEFAULT_BASIC_DETAILS_FORM_VALUE;
}

/**
 * Sends an Amplitude event whenever this page is loaded for a new tariff.
 */
export function useTariffEntryAmplitudeEvent() {
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const { tariffId } = useParams<{ tariffId: string }>();
  const {
    formData: { basicDetails },
  } = useTariffFormContext();

  useEffect(() => {
    if (tariffId === 'new' && !basicDetails) {
      const journeyIdentifier = uuid();
      localStorage.setItem('tariffFormJourneyIdentifier', journeyIdentifier);

      const eventProperties: Record<string, string> = {
        'Site ID': site.site_id.toString(),
        'User ID': user.userID.toString(),
        'Tariff Form Journey Identifier': journeyIdentifier,
        'Is New Tariff?': 'true',
      };

      amplitude.logEvent('Tariff Form Started', eventProperties);
    }
  }, [basicDetails, site.site_id, tariffId, user.userID]);
}
