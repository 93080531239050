// AWS-SDK fix for `global is not defined`
// The problem is because vite doesn't define a global
// field in `Window` object like webpack does.
// And some libraries rely on it since webpack is much
// more older than vite.
// https://stackoverflow.com/a/73208485
(window as any).global ||= window;

// fix for --isolatedModules error
export {};
