import React from 'react';
import { useBlocker } from 'react-router-dom';

import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

type Props = React.PropsWithChildren<{
  onChangeRoute: () => boolean;
}>;

/**
 * This component offers a lot of similar behaviour to react-router v5's `Prompt` component, with 2 major improvements:
 *
 * 1. `Prompt`'s `when` prop requires a boolean which must be synchronised with React's render cycle. This component
 *    provides an alternative by supplying a callable which returns the boolean value, which can be optimised to avoid
 *    potentially unnecessary memoization (compute the boolean at route change time, instead of eagerly computing this
 *    value beforehand and memoizing it or placing it into state).
 * 2. `Prompt` requires usage of the native browser `window.alert` dialog, which creates an inconsistent UI/UX. This
 *    component uses our stylized alert dialog instead.
 *
 * @param children - Child JSX.
 * @param onChangeRoute - A function which returns a boolean indicating whether there are unsaved changes.
 */
export default function RouteChangeBlocker({ children, onChangeRoute }: Props) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => onChangeRoute() && currentLocation.pathname !== nextLocation.pathname
  );

  return (
    <>
      {children}
      {blocker.state === 'blocked' ? (
        <AlertDialogModal
          header="You have unsaved changes"
          subHeader="If you leave, your unsaved changes will be lost. Are you sure you want to exit?"
          isOpen
          onClose={() => blocker.reset()}
          confirmButtonTextColor={'customRed.500'}
          onConfirm={() => blocker.proceed()}
          confirmButtonName="Confirm"
        />
      ) : null}
    </>
  );
}
