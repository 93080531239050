import { useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUser } from '../../features/user/userSlice';
import openLinkInCortexApp from '../../utils/native/browser';
import setupPushNotifications from '../../utils/native/push-notifications';
import { IS_DEMO_LOGIN } from '../constants';

// Configures the setup of push notifications for the app.
export function usePushNotificationSetup() {
  const navigate = useNavigate();
  // Android seems to set up PNs multiple times in the simulator, use this to avoid that
  const isPushNotificationSetupComplete = useRef(false);
  const user = useSelector(selectUser);

  // Handles the call to our API to register the device's tokens for push notifications.
  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    async function setupPNs() {
      await setupPushNotifications();
      isPushNotificationSetupComplete.current = true;
    }

    // The user needs to be logged in and have their details set by Clippy before setting up PNs.
    /* istanbul ignore next -- @preserve */
    if (
      user.isLoggedIn &&
      user.email &&
      !isPushNotificationSetupComplete.current &&
      Capacitor.isNativePlatform() &&
      !IS_DEMO_LOGIN
    ) {
      setupPNs();
    }
  }, [user]);

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    if (Capacitor.isNativePlatform()) {
      /**
       * @NOTE
       * This method of listening for deep links is used because setting the app up for deep links as per the Capacitor
       * documentation is not actually possible, because serving a file without an extension from the public/* directory
       * does not work at all. There is no known solution to this issue and as such deep links cannot work with this app
       * in iOS. Instead, we can just hook onto an action performed on a PN to redirect the user - this is a hacky
       * solution, and if a solution to the above issue with the `apple-app-site-association` file is found at some
       * point the preferred listener for `appUrlOpen` should be implemented instead. Ejecting from CRA at some point
       * might also give more flexibility for accessing static resources, but this is currently out of scope.
       *
       * See: https://bit.ly/3xyrDpl
       */
      PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
        if (action.actionId === 'tap') {
          let route: string | undefined;
          let url: string | undefined;

          if (Capacitor.getPlatform() === 'ios') {
            route = action.notification?.data?.aps?.alert?.data?.route;
            url = action.notification?.data?.aps?.alert?.data?.url;
          } else {
            route = action.notification?.data?.route;
            url = action.notification?.data?.url;
          }

          if (route) {
            console.log('going to route: ' + route);
            navigate(route);
            return;
          }
          if (url) {
            console.log('going to url: ' + url);
            openLinkInCortexApp(url);
            return;
          }
        }
      });
    }
  }, [navigate]);
}
