import React from 'react';
import { Center } from '@chakra-ui/react';

import VerticalSliderSelect from './VerticalSliderSelect';

type Props = {
  onChange: (value: { hours?: number; minutes?: number; amOrPm?: 'AM' | 'PM' }) => void;
  gradientColor: string;
  borderColor: string;
  width?: string | number;
  sliderColumnWidth?: string | number;
  minuteOptions?: (string | number)[];
} & State;

type State = {
  defaultHours: number;
  defaultMinutes: number | string;
  defaultAmOrPm: 'AM' | 'PM';
};

// 60 minutes by default, 0 -> 59
const DEFAULT_MINUTE_OPTIONS = Array.from(Array(60)).map((_, i) => {
  return i < 10 ? `0${i}` : i;
});

export default function TimePicker({
  defaultHours,
  defaultMinutes,
  defaultAmOrPm,
  onChange,
  gradientColor,
  borderColor,
  width = '100%',
  sliderColumnWidth,
  minuteOptions = DEFAULT_MINUTE_OPTIONS,
}: Props) {
  return (
    <Center
      pos="relative"
      w={width}
      height={'200px'}
      _after={{
        pos: 'absolute',
        left: 0,
        right: 0,
        content: "''",
        display: 'block',
        height: 'calc(50% - 32px / 2)',
        zIndex: 1,
        pointerEvents: 'none',
        bottom: '-0.5px',
        borderTop: `1px solid ${borderColor}`,
        background: `linear-gradient(to bottom, rgba(${gradientColor}, 0.65) 0%, rgba(${gradientColor}, 1) 100%)`,
      }}
      _before={{
        pos: 'absolute',
        left: 0,
        right: 0,
        content: "''",
        display: 'block',
        height: 'calc(50% - 32px / 2)',
        zIndex: 1,
        pointerEvents: 'none',
        top: '-0.5px',
        borderBottom: `1px solid ${borderColor}`,
        background: `linear-gradient(to top, rgba(${gradientColor}, 0.65) 0%, rgba(${gradientColor}, 1) 100%)`,
      }}
    >
      <VerticalSliderSelect
        width={sliderColumnWidth}
        defaultValue={defaultHours}
        items={Array.from(Array(12)).map((_, i) => i + 1)}
        loop
        onSelectItem={(hours) => {
          onChange({
            hours: hours as number,
          });
        }}
        data-testid="select-hours"
        fontSize={'24px'}
      />
      <VerticalSliderSelect
        width={sliderColumnWidth}
        defaultValue={defaultMinutes}
        items={minuteOptions}
        loop
        onSelectItem={(minutes) => {
          onChange({
            minutes: Number(minutes),
          });
        }}
        data-testid="select-minutes"
        fontSize={'24px'}
      />
      <VerticalSliderSelect
        width={sliderColumnWidth}
        defaultValue={defaultAmOrPm}
        loop={false}
        items={['AM', 'PM']}
        onSelectItem={(amOrPm) => {
          onChange({
            amOrPm: amOrPm as 'AM' | 'PM',
          });
        }}
        data-testid="select-am-or-pm"
        fontSize={'24px'}
      />
    </Center>
  );
}
