import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export type UnmeasuredAppliancesState = {
  unmeasuredAppliances: Record<number, { power: number }>;
};

const initialState: UnmeasuredAppliancesState = {
  // stores extra power data that comes from other device-specific APIs that don't have associated load CTs
  unmeasuredAppliances: {},
};

export const unmeasuredAppliancesSlice = createSlice({
  name: 'unmeasuredAppliances',
  initialState,
  reducers: {
    updateUnmeasuredAppliancesData: (state, action: PayloadAction<{ siteDeviceId: number; power: number }>) => ({
      ...state,
      unmeasuredAppliances: {
        ...state.unmeasuredAppliances,
        [action.payload.siteDeviceId]: { power: action.payload.power },
      },
    }),
  },
});

export const { updateUnmeasuredAppliancesData } = unmeasuredAppliancesSlice.actions;

export const selectUnmeasuredAppliances = (state: RootState) => state.unmeasuredAppliances.unmeasuredAppliances;

export default unmeasuredAppliancesSlice.reducer;
