export const VIEWPORT_TYPE_TO_FONT_SIZES = {
  mobile: { selected: '12px', normal: '11px' },
  tablet: { selected: '14px', normal: '12px' },
  desktop: { selected: '15px', normal: '13px' },
  'desktop-lg': { selected: '16px', normal: '14px' },
};

export const VIEWPORT_TYPE_TO_YAXIS_MARGINS = {
  mobile: 5,
  tablet: 5,
  desktop: 15,
  'desktop-lg': 20,
};
