import React from 'react';
import { Capacitor } from '@capacitor/core';
import { ChevronRightIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import Card from 'clipsal-cortex-ui/src/components/card/Card';

import clipsalLogo from '../../assets/images/clipsal_logo_icon.svg';
import { BOTTOM_NAV_HEIGHT } from '../../common/constants';
import { useViewportType } from '../../common/hooks/use-viewport-type';
import { ENV_TYPE_SHORTHAND } from '../../env-type';
import { selectSite } from '../site/siteSlice';
import { selectUser } from '../user/userSlice';
import { AddressPinOutlinedIcon } from './icons';
import { PROFILE_SUB_ROUTES } from './profile-helpers';
import { useNearmapImage } from './profileApi';
import { toggleExperimentalFeaturesModal } from './profileSlice';

const COMMON_ICON_PROPS = {
  width: '22px',
  height: '22px',
  fill: 'none',
};

export const ProfileOutlet = () => {
  const { isDesktopViewport } = useViewportType();
  const background = useColorModeValue('gray.50', 'gray.900');

  return isDesktopViewport ? (
    <Card mt={4} cardBackground={background} maxW={700} mx="auto">
      <Outlet />
    </Card>
  ) : (
    <Outlet />
  );
};

const Profile = () => {
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const siteImage = useNearmapImage();
  const { isDesktopViewport } = useViewportType();
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const background = useColorModeValue('white', 'dusk100.800');
  const buttonBorder = useColorModeValue('gray.300', 'whiteAlpha.200');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'black');
  const dispatch = useDispatch();

  const handleNavigate = (route: string) => {
    navigate(route);
    window.scrollTo(0, 0);
  };

  return (
    <Box pb={isDesktopViewport ? undefined : `calc(${BOTTOM_NAV_HEIGHT} + 16px)`} data-testid="profile-page">
      <Box
        data-testid="nearmap-image"
        background={siteImage ? `url(${siteImage})` : background}
        backgroundSize="cover"
        backgroundPosition="center"
        height={isDesktopViewport ? '40vh' : '30vh'}
        // super admin has a larger header for additional information
        minHeight={user.role === 'SUPER_ADMIN' ? 280 : 220}
        position={'relative'}
      >
        <VStack
          position={'absolute'}
          bottom={0}
          left={0}
          right={0}
          top={0}
          background={'rgba(0, 0, 0, 0.4)'}
          zIndex={2}
          align={'flex-start'}
          justify={'center'}
        >
          <Box mt="auto" px={4} ml={4} mb={4}>
            <Image
              cursor={'pointer'}
              alignSelf={'center'}
              width={50}
              src={clipsalLogo}
              alt="Tenant branding logo"
              mt={4}
            />
            <Heading color={'white'} fontSize={24} my={3}>
              {site.site_name}
            </Heading>
            <Center justifyContent={'flex-start'}>
              <AddressPinOutlinedIcon w={8} h={10} fill="none" color={'white'} />
              <VStack align={'flex-start'} spacing={0} ml={2}>
                <Text color={'white'}>{site.address}</Text>
                <Button variant="link" color="customBlue.400" onClick={() => navigate('/sites')} my={1}>
                  Switch Site
                </Button>
              </VStack>
            </Center>
            {user.role === 'SUPER_ADMIN' && (
              <Box padding={3} rounded={16} border={'1px solid white'} mt={2}>
                <Text data-private fontWeight={700} color={'white'}>
                  {site.site_id}: {site.site_name} ({ENV_TYPE_SHORTHAND === 'PROD' ? 'Production' : 'Staging'} mode)
                </Text>
                {/* Show the last build commit hash on production web builds */}
                {!Capacitor.isNativePlatform() && import.meta.env?.MODE === 'production' && (
                  <Text color={'white'}>Last build commit: {import.meta.env?.['VITE_SENTRY_RELEASE'] ?? 'N/A'}</Text>
                )}
              </Box>
            )}
          </Box>
        </VStack>
      </Box>
      {PROFILE_SUB_ROUTES.map(({ Icon, title, route }) => {
        return (
          <Box
            bg={background}
            _hover={{ bg: hoverColor }}
            key={title}
            data-testid={route}
            cursor={'pointer'}
            onClick={() => handleNavigate(route)}
          >
            <Flex
              borderBottom={'1px solid'}
              borderColor={buttonBorder}
              justify={'space-between'}
              align={'center'}
              py={8}
              px={6}
            >
              <Center>
                <Box _dark={{ color: 'white' }} mr={4}>
                  <Icon {...COMMON_ICON_PROPS} />
                </Box>
                <Heading fontSize={18}>{title}</Heading>
              </Center>
              <ChevronRightIcon color={'textGrey.500'} w={'26px'} h={'26px'} />
            </Flex>
          </Box>
        );
      })}
      <Box
        bg={background}
        _hover={{ bg: hoverColor }}
        mt={4}
        data-testid="account-details"
        cursor={'pointer'}
        onClick={() => handleNavigate('account-details')}
      >
        <Flex
          borderBottom="1px solid"
          borderColor={buttonBorder}
          justify={'space-between'}
          align={'center'}
          py={8}
          px={6}
        >
          <Heading fontSize={18}>Account Details</Heading>
          <ChevronRightIcon color={'textGrey.500'} w={'26px'} h={'26px'} />
        </Flex>
      </Box>

      <Box bg={background} _hover={{ bg: hoverColor }} data-testid="color-mode-toggle" cursor={'pointer'}>
        <Flex
          borderBottom="1px solid"
          borderColor={buttonBorder}
          justify={'space-between'}
          align={'center'}
          py={8}
          px={6}
        >
          <Center>
            <Heading fontSize={18} mr={2}>
              Light
            </Heading>
            <SunIcon />
          </Center>
          <Switch
            size="lg"
            isChecked={colorMode === 'dark'}
            onChange={() => toggleColorMode()}
            colorScheme={colorMode === 'dark' ? 'whiteAlpha' : 'blackAlpha'}
          />
          <Center>
            <Heading fontSize={18} mr={2}>
              Dark
            </Heading>
            <MoonIcon />
          </Center>
        </Flex>
      </Box>

      {/* This feature is only available to SUPER_ADMIN users. It is hidden from other users. */}
      {user.role === 'SUPER_ADMIN' && (
        <Box
          bg={background}
          _hover={{ bg: hoverColor }}
          data-testid="experimental-features"
          cursor={'pointer'}
          onClick={() => {
            dispatch(toggleExperimentalFeaturesModal(true));
          }}
        >
          <Flex
            borderBottom="1px solid"
            borderColor={buttonBorder}
            justify={'space-between'}
            align={'center'}
            py={8}
            px={6}
          >
            <Heading fontSize={18}>Experimental Features</Heading>
            <ChevronRightIcon color={'textGrey.500'} w={'26px'} h={'26px'} />
          </Flex>
        </Box>
      )}

      <Box
        bg={background}
        _hover={{ bg: hoverColor }}
        cursor={'pointer'}
        onClick={() => navigate('/logout')}
        data-testid="logout"
      >
        <Flex
          borderBottom="1px solid"
          borderColor={buttonBorder}
          color="red.500"
          justify={'space-between'}
          align={'center'}
          py={8}
          px={6}
        >
          <Heading fontSize={18}>Logout</Heading>
        </Flex>
      </Box>
    </Box>
  );
};

export default Profile;
