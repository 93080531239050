import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, Heading, Skeleton, Text, useTheme } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ErrorExclamationIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { formatDollarsNoCents, getOrdinalSuffix } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { GenericErrorNoCard } from '../../../common/components/GenericErrorCard';
import { selectSite } from '../../site/siteSlice';
import { BillsStatus, useGetSiteBillsStatusQuery, useGetSiteRetailPlanCheckQuery } from '../billsApi';

const CURRENT_RANK_POSITION_TO_MARGIN_RIGHT: Record<number, string> = {
  0: '2px',
  100: '-2px',
};

type RPCProps = {
  isParentLoaded: boolean;
  isInPlanComparison: boolean;
};

// This is the only thing exported here, but the plugin still complains? Supressing the error for now.
// eslint-disable-next-line react-refresh/only-export-components
export default function RPC({ isInPlanComparison }: RPCProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const site = useSelector(selectSite);
  const { data: rpcData, isLoading: isRPCLoading, isError: isRPCError } = useGetSiteRetailPlanCheckQuery(site.site_id);
  const {
    data: billsStatus,
    isLoading: isBillsStatusLoading,
    isError: isBillsStatusError,
  } = useGetSiteBillsStatusQuery(site.site_id);
  const isLoading = isRPCLoading || isBillsStatusLoading;
  const isError = isRPCError || isBillsStatusError;

  // Loading UI controlled by parent component
  if (isLoading) return <></>;

  if (isError) {
    return <GenericErrorNoCard>Error loading RPC. Please refresh the page to try again.</GenericErrorNoCard>;
  }

  // If there is no RPC, don't render anything for this component.
  if (!isLoading && !isError && !rpcData) return <></>;

  const currentRankPosition = Math.round(((rpcData!.rank - 1) / (rpcData!.num_plans - 1)) * 100);

  return (
    <Box fontSize={['sm', 'sm', 'sm', 'md']}>
      <Divider my={4} borderColor={'textGrey.500'} borderWidth={1} />

      <Skeleton mb={2} isLoaded={!isLoading}>
        <Flex justify={'space-between'}>
          <Text fontSize={'md'} fontWeight={'bolder'} data-testid={'your-energy-plan'}>
            Your energy plan ranks
          </Text>

          {billsStatus?.status === BillsStatus.NewBillExpected && (
            <Flex align={'center'}>
              <ErrorExclamationIcon w={'22px'} h={'22px'} mr={1} color={'red.500'} />
              <Heading size={'sm'}>Out of date</Heading>
            </Flex>
          )}
        </Flex>
      </Skeleton>

      <Skeleton isLoaded={!isLoading}>
        <Flex mb={2} align={'flex-end'}>
          <Heading fontSize={30} lineHeight={'30px'}>
            {rpcData?.rank}
          </Heading>
          <Heading fontSize={22} lineHeight={'24px'}>
            {getOrdinalSuffix(rpcData!.rank)}
          </Heading>
          <Text fontWeight={'bold'} ml={1} fontSize={20} lineHeight={'20px'}>
            / {rpcData?.num_plans}
          </Text>
          <Text ml={2} color={'textGrey.500'} fontSize={16} lineHeight={'16px'}>
            based on cost
          </Text>
        </Flex>
      </Skeleton>

      <Skeleton isLoaded={!isLoading}>
        <Flex mb={3} color={'textGrey.500'}>
          <Text>
            Bill period: {rpcData!.start_date} to {rpcData!.end_date}&nbsp;
          </Text>
          <Text fontWeight={'bolder'}>
            ({rpcData!.current_bill_data?.num_days}
            &nbsp;
          </Text>
          <Text>days)</Text>
        </Flex>
      </Skeleton>

      <Box mb={3}>
        <Skeleton isLoaded={!isLoading}>
          <Flex
            justify={'flex-end'}
            width={`${currentRankPosition}%`}
            ml={CURRENT_RANK_POSITION_TO_MARGIN_RIGHT[currentRankPosition] || 0}
          >
            <Flex direction="column" mr={['-20px', '-25px']}>
              <Flex
                justify={'center'}
                align={'center'}
                rounded={5}
                fontWeight={'bolder'}
                height={'30px'}
                background={'black'}
                color={'white'}
                width={['40px', '50px']}
              >
                ${rpcData!.current_bill_data?.cost}
              </Flex>
              <Flex justify={'center'} width={['40px', '50px']}>
                <Box
                  borderTop={'10px solid black'}
                  borderRight={'10px solid transparent'}
                  borderLeft={'10px solid transparent'}
                />
              </Flex>
            </Flex>
          </Flex>
        </Skeleton>

        <Skeleton isLoaded={!isLoading}>
          {/* Gradient bar */}
          <Box
            width={'100%'}
            height={'10px'}
            rounded={20}
            background={`linear-gradient(to left, red, yellow, ${theme.colors.primaryBranding[500]});`}
          />

          <Flex mt={1} fontWeight={'bolder'} justify={'space-between'}>
            <Text>{formatDollarsNoCents(Math.floor(rpcData!.min_cost))}</Text>
            <Text>{formatDollarsNoCents(Math.ceil(rpcData!.max_cost))}</Text>
          </Flex>
        </Skeleton>
      </Box>

      <Skeleton isLoaded={!isLoading}>
        <Flex>
          <Text>{rpcData?.explanation_text}</Text>
        </Flex>
      </Skeleton>

      <Skeleton isLoaded={!isLoading}>
        {!isInPlanComparison && (
          <Button
            px={0}
            variant={'ghost'}
            mt={2}
            onClick={() => {
              navigate(pathname + '/compare_plans');
            }}
            color={'customBlue.500'}
            data-testid="compare-plans-button"
          >
            <Flex fontWeight={'bold'} align={'center'}>
              Compare plans <ChevronRightIcon w={5} h={5} />
            </Flex>
          </Button>
        )}
      </Skeleton>
    </Box>
  );
}
