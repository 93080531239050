import { useSelector } from 'react-redux';

import {
  AvailableVINs,
  TeslaConnection,
  TeslaConnectionStatus,
  TeslaVehicleToCreate,
} from 'clipsal-cortex-types/src/api/api-tesla';

import { baseApi } from '../../../../app/services/baseApi';
import { selectSite } from '../../../site/siteSlice';

export const teslaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getConnectionStatus: build.query<TeslaConnectionStatus, number>({
      query: (siteId) => `/v1/sites/${siteId}/tesla_connection`,
      providesTags: ['TeslaAccountConnection'],
    }),
    deleteConnection: build.mutation<void, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/tesla_connection`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TeslaAccountConnection'],
    }),
    createConnection: build.mutation<TeslaConnectionStatus, { siteId: number; body: TeslaConnection }>({
      query: ({ siteId, body }) => ({
        url: `/v1/sites/${siteId}/tesla_connection`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['TeslaAccountConnection'],
    }),
    getAvailableVehicles: build.query<AvailableVINs, number>({
      query: (siteId) => `/v1/sites/${siteId}/available_tesla_vehicles`,
      providesTags: ['AvailableTeslaVehicles'],
    }),
    createTeslaVehicleSiteDevice: build.mutation<void, { siteId: number; vin: string; model: string }>({
      query: ({ siteId, vin, model }) => {
        const body: TeslaVehicleToCreate = {
          vehicle_id: vin,
          model_number: model,
          manufacturer_name: 'Tesla',
        };

        return {
          url: `/v1/sites/${siteId}/evs`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAvailableVehiclesQuery,
  useCreateTeslaVehicleSiteDeviceMutation,
  useGetConnectionStatusQuery,
  useDeleteConnectionMutation,
  useCreateConnectionMutation,
} = teslaApi;

export function useAvailableVehicles(skip = false) {
  const { site_id: siteId } = useSelector(selectSite);
  const { data: vins, ...rest } = useGetAvailableVehiclesQuery(siteId, { skip });

  return {
    availableVINs: vins?.available_vins ?? [],
    ...rest,
  };
}
