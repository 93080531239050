import React from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { InputGroup } from '@chakra-ui/input';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputRightAddon,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import Dialog from '../../../../../../common/components/Dialog';
import CustomAddButton from '../../../rates/common/CustomAddButton';
import { useTariffFormContext } from '../../../tariff-form-context';
import { DISCOUNT_OPTIONS, DISCOUNT_TYPE_TO_IMAGE, EMPTY_DISCOUNT_TEMPLATE } from './constants';
import DiscountSelect from './DiscountSelect';
import { Discount, DiscountsFormData } from './types';

const discountsFormSchema = yup.object().shape({
  discounts: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.number().optional(),
          type: yup
            .object()
            .shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
            .typeError('This field is required')
            .required(),
          value: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(0)
            .typeError('This field is required')
            .required(),
        })
        .required()
    )
    .min(1, 'At least one discount is required'),
});

export default function DiscountsFormDialog({ isOpen, onClose }: DialogProps) {
  const {
    formData: {
      additionalRatesAndDiscounts: { discounts },
    },
    onUpdateFormData,
  } = useTariffFormContext();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<DiscountsFormData>({
    resolver: yupResolver(discountsFormSchema),
    defaultValues: discounts ?? {
      discounts: [EMPTY_DISCOUNT_TEMPLATE],
    },
  });
  const {
    append,
    fields: discountFields,
    remove,
  } = useFieldArray({
    control,
    name: 'discounts',
  });
  const { isOpen: isAlertDialogOpen, onClose: onCloseAlertDialog, onOpen: onOpenAlertDialog } = useDisclosure();

  async function handleSubmitDiscountsForm(discountsFormData: DiscountsFormData) {
    onUpdateFormData((p) => ({
      ...p,
      additionalRatesAndDiscounts: { ...p.additionalRatesAndDiscounts, discounts: discountsFormData },
    }));
    onClose();
  }

  function handleClearForm() {
    reset({
      discounts: [EMPTY_DISCOUNT_TEMPLATE],
    });
    onUpdateFormData((p) => ({
      ...p,
      additionalRatesAndDiscounts: { ...p.additionalRatesAndDiscounts, discounts: null },
    }));
    onClose();
  }

  return (
    <>
      <Dialog showCloseButton title="Discounts" isOpen={isOpen} onClose={onClose}>
        <DiscountsInfo />
        <Box data-testid="discounts-form" mx={4} as="form" onSubmit={handleSubmit(handleSubmitDiscountsForm)}>
          {discountFields.map((discountField, discountIndex) => {
            return (
              <React.Fragment key={discountField.id}>
                {discountFields.length > 1 && (
                  <Flex align="center">
                    <Heading size="md">Discount {discountIndex + 1}</Heading>
                    <IconButton
                      ml={1}
                      size="sm"
                      onClick={() => remove(discountIndex)}
                      color="customRed.500"
                      variant="ghost"
                      icon={<DeleteIcon />}
                      aria-label={`Delete discount ${discountIndex + 1}`}
                      data-testid={`delete-discount-${discountIndex}-btn`}
                    />
                  </Flex>
                )}
                <Flex flexDir={['column', 'column', 'row']} align="center">
                  <FormControl mb={[3, 3, 0]} mr={[0, 0, 5]} isInvalid={!!errors?.discounts?.[discountIndex]?.type}>
                    <FormLabel>Type</FormLabel>

                    <DiscountSelect options={DISCOUNT_OPTIONS} discountIndex={discountIndex} control={control} />
                    <FormErrorMessage data-testid="discount-error-message">This field is required</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors?.discounts?.[discountIndex]?.value}>
                    <FormLabel>Discount</FormLabel>
                    <InputGroup>
                      <Input
                        data-testid={`discount-${discountIndex}-value`}
                        {...register(`discounts.${discountIndex}.value`)}
                        type={'number'}
                        onWheel={(e) => e.currentTarget.blur()}
                        min={0}
                        step="0.0000000001"
                      />
                      <InputRightAddon>%</InputRightAddon>
                    </InputGroup>
                    <FormErrorMessage data-testid={`discount-${discountIndex}-error-message`}>
                      {errors?.discounts?.[discountIndex]?.value?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                {discountIndex !== discountFields.length - 1 && <Divider my={5} />}
              </React.Fragment>
            );
          })}

          <CustomAddButton
            data-testid="add-another-discount-btn"
            mt={4}
            onClick={() => {
              append(EMPTY_DISCOUNT_TEMPLATE);
            }}
          >
            Add Another Discount
          </CustomAddButton>

          <Center flexDirection="column" mt={6}>
            <Button
              data-testid="submit-discount-form-btn"
              type="submit"
              w={['75%', '75%', '50%']}
              rounded={20}
              colorScheme="dusk100"
            >
              Add
            </Button>

            <Button
              data-testid="clear-discounts-btn"
              onClick={onOpenAlertDialog}
              mt={2}
              variant="ghost"
              w={['75%', '75%', '50%']}
              colorScheme={'red'}
            >
              Clear All
            </Button>
          </Center>
        </Box>
      </Dialog>

      <AlertDialogModal
        header={`Are you sure you want to clear your discount${discountFields.length > 1 ? 's' : ''}?`}
        subHeader=" "
        isOpen={isAlertDialogOpen}
        onClose={onCloseAlertDialog}
        confirmButtonTextColor={'customRed.500'}
        onConfirm={handleClearForm}
        confirmButtonName="Clear"
      />
    </>
  );
}

function DiscountsInfo() {
  return (
    <Center flexDirection="column" mb={3} textAlign="left">
      <UnorderedList>
        <Heading mb={3} size="sm">
          Discount Types
        </Heading>
        <ListItem mb={3} listStyleType="none">
          <Flex align="center">
            <Image
              mr={2}
              w={12}
              h={12}
              src={DISCOUNT_TYPE_TO_IMAGE[Discount.SUPPLY_CHARGE_DISCOUNT]}
              alt={`Value rebate image`}
            />
            <Box>
              <Text fontWeight="bold">Supply Charge Discount</Text>
              <Text>A percentage (%) off the supply charge rate.</Text>
            </Box>
          </Flex>
        </ListItem>
        <ListItem mb={3} listStyleType="none">
          <Flex align="center">
            <Image
              mr={2}
              w={12}
              h={12}
              src={DISCOUNT_TYPE_TO_IMAGE[Discount.TOTAL_BILL]}
              alt={`Total bill discount image`}
            />
            <Box>
              <Text fontWeight="bold">Total Bill Discount</Text>
              <Text>A percentage (%) off the total bill charge.</Text>
            </Box>
          </Flex>
        </ListItem>
        <ListItem listStyleType="none">
          <Flex align="center">
            <Image
              mr={2}
              w={12}
              h={12}
              src={DISCOUNT_TYPE_TO_IMAGE[Discount.USAGE_CHARGE_DISCOUNT]}
              alt={`Usage charge discount image`}
            />
            <Box>
              <Text fontWeight="bold">Usage Charge Discount</Text>
              <Text>A percentage (%) off your usage rates.</Text>
            </Box>
          </Flex>
        </ListItem>
      </UnorderedList>
      <Divider mt={3} />
    </Center>
  );
}
