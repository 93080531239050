export type EnvironmentType = 'development' | 'production' | 'test' | 'staging';
export type ShorthandEnvironmentType = 'STAGING' | 'PROD' | 'TEST';
export let ENV_TYPE_SHORTHAND: ShorthandEnvironmentType = import.meta.env?.MODE === 'production' ? 'PROD' : 'STAGING';

if (window.Cypress) {
  ENV_TYPE_SHORTHAND = 'STAGING';
}

// Check local storage for a different env setting (users can override this)
let fromStorage = localStorage.getItem('cortexEnvType');
// Short-term fix to re-assign DEV to STAGING
if (fromStorage && fromStorage === 'DEV') fromStorage = 'STAGING';

if (fromStorage) {
  ENV_TYPE_SHORTHAND = fromStorage as ShorthandEnvironmentType;
}
