import {
  SiteSolarSpongeConfig,
  SiteSolarSpongeConfigCreate,
  SiteSolarSpongeConfigWrite,
} from 'clipsal-cortex-types/src/api/api-site-solar-sponge-config';

import { baseApi } from '../../../app/services/baseApi';

export const siteSolarSpongeConfigApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSiteSolarSpongeConfig: build.query<SiteSolarSpongeConfig, number>({
      query: (siteId) => `/v1/sites/${siteId}/solar_sponges`,
      providesTags: ['SiteSolarSpongeConfig'],
    }),
    createSiteSolarSpongeConfig: build.mutation<SiteSolarSpongeConfig, SiteSolarSpongeConfigCreate>({
      query: (siteSolarSpongeConfig) => {
        return {
          url: `/v1/sites/${siteSolarSpongeConfig.site_id}/solar_sponges`,
          method: 'POST',
          body: siteSolarSpongeConfig,
        };
      },
      invalidatesTags: ['SiteSolarSpongeConfig'],
    }),
    updateSiteSolarSpongeConfig: build.mutation<SiteSolarSpongeConfig, SiteSolarSpongeConfigWrite>({
      query: (siteSolarSpongeConfig) => {
        return {
          url: `/v1/sites/${siteSolarSpongeConfig.site_id}/solar_sponges`,
          method: 'PUT',
          body: siteSolarSpongeConfig,
        };
      },
      invalidatesTags: ['SiteSolarSpongeConfig'],
    }),
  }),
});

export const {
  useGetSiteSolarSpongeConfigQuery,
  useCreateSiteSolarSpongeConfigMutation,
  useUpdateSiteSolarSpongeConfigMutation,
} = siteSolarSpongeConfigApi;
