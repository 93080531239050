import React from 'react';
import {
  Box,
  Button,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

export default function TermsAndConditions() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hoverColor = useColorModeValue('white', 'gray.900');

  return (
    <>
      <Box
        data-testid="terms-conditions-btn"
        onClick={onOpen}
        py={3}
        px={4}
        borderBottomRightRadius={20}
        borderBottomLeftRadius={20}
        _hover={{ bg: hoverColor }}
        cursor={'pointer'}
      >
        Terms and Conditions
      </Box>

      <TermsAndConditionsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export function TermsAndConditionsModal({ isOpen, onClose }: DialogProps) {
  return (
    <Modal scrollBehavior={'inside'} size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="terms-conditions-modal-body">
        <ModalHeader>Terms & Conditions</ModalHeader>
        <ModalCloseButton data-testid="terms-conditions-close-btn" />
        <ModalBody>
          <TermsAndConditionsText />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="primaryBranding" mr={3} onClick={onClose} data-testid="terms-conditions-close-button">
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function TermsAndConditionsText() {
  return (
    <Box>
      <Text mb={2}>
        Welcome to Clipsal Cortex! These terms and conditions (the “Terms of Use”) form a contract between New Energy
        Group Pty Ltd ABN 54 629 717 595 trading as New Energy Group Pty Ltd (“Clipsal Cortex”, “we”, or “us”) and You.
        Clipsal Cortex provides:
      </Text>
      <Text mb={2}>
        • the electronic applications available at <Link href="https://clipsalsolar.com">https://clipsalsolar.com</Link>
        , <Link href="https://clipsalcortex.com">https://clipsalcortex.com</Link> and the Apple App Store (the “Sites”)
        as well as a number of related websites, social network sites and services (the “Services”) to our users (“You”
        or “Your”). Our Services include resources and information about energy consumption in residences and small
        businesses. By accessing the Services you agree to be bound by the Terms of Use. Clipsal Cortex may change these
        Terms of Use at any time. It is Your responsibility to check these Terms of Use for changes. Your continued use
        of the Sites following any changes constitutes Your acceptance of those changes.
      </Text>
      <Heading size={'md'} mb={2}>
        Clipsal Cortex System
      </Heading>
      <Text mb={2}>
        1. The Sites and Services will provide you with valuable insights on energy consumption in your home or small
        business premises and will assist you to:
      </Text>
      <Text mb={2}>• track savings;</Text>
      <Text mb={2}>• manage energy invoices.</Text>
      <Text mb={2}>
        The Sites and Services will provide You with analytics, alerts and visibility to energy usage to facilitate
        daily energy management.
      </Text>
      <Heading mb={2} size={'md'}>
        Users
      </Heading>
      <Text mb={2}>
        2. You may browse the Sites (a “Visitor”), or You may become a registered user of the Sites (“Clipsal Cortex
        User”). Some features of the Sites are limited to Clipsal Cortex Users.
      </Text>
      <Text mb={2}>3. In the event You become a Clipsal Cortex User:</Text>
      <Text mb={2}>
        • you agree to pay the subscription fee or fees and to provide us with true, current and complete information.
        If Clipsal Cortex believes that Your information is not true, current or complete, we may terminate Your access
        to the Sites or Services. Visitors are not required to provide us with information, but if You do so, You agree
        that any information will be true, current and complete; and
      </Text>
      <Text mb={2}>
        • you warrant that you will only use the Site and Services for measuring energy consumption in a residence or
        small business.
      </Text>
      <Heading mb={2} size={'md'}>
        Subscriptions
      </Heading>{' '}
      <Text mb={2}>
        4. Your subscription to the Sites and Services and purchase is subject to these Terms of Use. There are various
        payment and subscription options available for using the Services. These options can be found on the Sites and
        may change from time to time. Once you register and your initial payment is processed, your subscription has
        begun, and you can immediately access the Services. Your subscription may automatically renew. This means that
        once you become a subscribing member, your subscription will automatically renew based on the subscription
        program you chose. You will be charged the rate stated at the time of purchase (plus applicable taxes, such as
        GST) at the beginning of each billing term of your subscription via the billing method you have provided to us.
        Please ensure that your billing information is correct to prevent your subscription from lapsing. Prices and
        terms for subscriptions may change at any time. New prices and terms may apply to renewals. If you do not want
        to renew your subscription under new prices or terms, you may cancel your subscription in accordance with clause
        27 of these Terms of Use associated with your account.
      </Text>
      <Heading size={'md'} mb={2}>
        Registration
      </Heading>
      <Text mb={2}>
        5. To become a Clipsal Cortex User, You must provide Your name, email address and select a password (Your
        “Account Credentials”) and such other information as is specified on the Sites. If someone accesses our Sites or
        Services using Your Account Credentials, we will assume that it is You or Your representative. You are solely
        responsible for all use of Your Account Credentials and all activities that occur under or in connection with
        Your account. Clipsal Cortex reserves the right to take any action deemed necessary to ensure the security of
        the Sites and Your Account Credentials, including terminating Your access to the Sites or Services, changing
        Your password, or requesting additional information to authorise activities related to Your registration. Under
        no circumstances will Clipsal Cortex be liable to You for any damages arising out of: (a) any action or inaction
        of Clipsal Cortex under this provision, (b) any compromise of the confidentiality of Your Account Credentials,
        and (c) any unauthorised access to or use of Your Account Credentials.
      </Text>{' '}
      <Heading mb={2} size={'md'}>
        Equipment
      </Heading>
      <Text mb={2}>
        6. If You purchase or obtain equipment, such as devices to measure and monitor energy use from Clipsal Cortex or
        another supplier (“Equipment”), the Equipment is subject to the terms and conditions (including any warranty)
        under which the Equipment was purchased or obtained from Clipsal Cortex or that other supplier.
      </Text>
      <Heading size={'md'} mb={2}>
        Social Media
      </Heading>
      <Text mb={2}>
        7. As a Clipsal Cortex User, You may choose to share energy consumption data with members of social media
        networks that you belong to (such as Facebook, Twitter, etc). You understand that you are solely responsible for
        any data or other information posted by You on social media networks and in no event will Clipsal Cortex or its
        officers, employees, affiliates or licensors be liable for loss or damage arising out of or related to your use
        of, posting of data or information or access to social networks and regardless of whether such damages are based
        in equity, at common law, under warranty, statute or otherwise.
      </Text>
      <Heading size={'md'} mb={2}>
        Ownership
      </Heading>
      <Text mb={2}>
        8. You acknowledge that the software, code, proprietary methods and systems used to provide the Sites or
        Services (“Our Technology”) are copyright and owned by us or our licensors. Our Technology may not be copied or
        distributed in any way without our prior written permission. Certain information made available or displayed on
        the Sites or sent to You through the Services, and any derivative works, whether made by us or You (but in all
        cases excluding Your Content) are proprietary to us or our licensors and should be considered our confidential
        information (“Content”). Subject to these Terms of Use, Clipsal Cortex grants You a limited, non-exclusive,
        non-transferable license to use the Content solely for Your personal purposes and in accordance with these Terms
        of Use. You may not: (a) use the Content to develop products similar to the products of Clipsal Cortex; (b)
        reproduce, or modify the Content; or (c) make the Content available to others. Clipsal Cortex retains all right,
        title and interest in and to the Content. Clipsal Cortex reserves all rights not granted in these Terms of Use.
        You acknowledge You may be exposed through the Sites or Services to Content that violates our policies or is
        offensive. You access our Sites and Services at Your own risk. Clipsal Cortex may, but is not obligated to,
        remove Content from the Sites for any reason, including if determined that such Content violates these Terms of
        Use. Clipsal Cortex merely acts as a conduit for such distribution and takes no responsibility for Your exposure
        to Content on the Sites or through the Services, whether it violates our content policies or not.
      </Text>
      <Heading size={'md'} mb={2}>
        Your Content
      </Heading>
      <Text mb={2}>
        9. Clipsal Cortex has access to your electricity consumption data, usage data (login frequency, most-used
        functions, etc) and information such as browser type, operating system, screen resolution, tracking applications
        use and understanding how Sites and Services are used by cookie and other tracking technology (“Your Content”).
        Clipsal Cortex will not without Your permission disclose or sell your personal information to third parties,
        except where disclosure is required by law. You agree that Clipsal Cortex may use certain of Your Content:
      </Text>
      <Text mb={2}>• for improving user experiences</Text>
      <Text mb={2}>• for providing You with indicative information on energy consumption</Text>
      <Text mb={2}>• for providing You with indicative information on energy prices</Text>
      <Text mb={2}>
        • for defining anonymous consumption profiles for data analytics, machine learning models and anonymous reports
        and trends of aggregated residences or small businesses. Your Content is stored in databases located in
        Australia.
      </Text>
      <Heading mb={2} size={'md'}>
        Emails
      </Heading>
      <Text mb={2}>
        10. Clipsal Cortex may send newsletters or other material using the email address You provide, and may contact
        You periodically about electricity usage activities, including surveys. Email communication is intended only for
        the use of the addressee(s) and is confidential. If You are not an intended recipient any disclosure,
        reproduction or other use of the email is strictly prohibited. If You receive an email communication in error,
        please notify Clipsal Cortex immediately and delete the email.
      </Text>
      <Heading size={'md'} mb={2}>
        Rules of User Conduct
      </Heading>
      <Text mb={2}>11. You agree not to, and represent and warrant that You:</Text>
      <Text mb={2}>(a) will not use, copy or exploit any portion of or your use of the Sites or Services;</Text>
      <Text mb={2}>(b) only use the Sites and Services for the purposes for which they are intended;</Text>
      <Text mb={2}>(c) will not distribute or print anything that may be harmful to minors;</Text>
      <Text mb={2}>
        (d) will not attempt to reverse engineer or jeopardize the functioning of the Site or attempt to derive the
        source code of the software that enables or underlies the Site;
      </Text>
      <Text mb={2}>(e) will not attempt to gain access to secured portions of the Sites or Services;</Text>
      <Text mb={2}>(f) will not transmit any form of virus, worm, Trojan horse, or other malicious code;</Text>
      <Text mb={2}>(g) will not use the Sites or Services to stalk, harass or harm another individual;</Text>
      <Text mb={2}>
        (h) will not use any process to access, search or harvest information from the Sites or Services (including
        robots, spiders or scripts);
      </Text>
      <Text mb={2}>
        (i) will not interfere in any way with the functioning of the Sites and Services or interfere with or disrupt
        any servers or networks connected to the Sites or Services, or disobey any procedures or policies of networks
        connected to the Sites or Services;
      </Text>
      <Text mb={2}>
        (j) will not use any robot, spider, other automatic device, or manual process to extract, “screen scrape,”
        monitor, “mine,” or copy any static or dynamic web page on the Sites or the Content contained on any such web
        page for commercial use.
      </Text>
      <Heading size={'md'} mb={2}>
        Feedback and Blogs
      </Heading>
      <Text mb={2}>
        12. If You provide us any feedback related to the Sites or the Services, You agree Clipsal Cortex may use the
        feedback to modify our Services and that You will not be due any compensation related to the product or service
        that incorporates Your feedback. You grant to us a worldwide, free license to use and exploit the feedback in
        any form whether now known or developed in the future and to allow others to do the same. Our discussion forums
        and blogs may be public, so others may read Your question or comments.
      </Text>
      <Heading size={'md'} mb={2}>
        Privacy
      </Heading>
      <Text mb={2}>
        13. Clipsal Cortex has created a privacy policy (the “Privacy Policy”) that describes our collection, use and
        disclosure practices regarding any personal information that You provide to us. Clipsal Cortex reserves the
        right to cooperate with government authorities in investigations of, or to report, improper or unlawful
        activities and this may require the disclosure of Your personal information.
      </Text>
      <Heading size={'md'} mb={2}>
        Modifications to the Site or Services
      </Heading>
      <Text mb={2}>
        14. Clipsal Cortex reserves the right to modify or discontinue the Sites or Services. Clipsal Cortex will not be
        liable to You or any third party should we exercise our right to modify or discontinue the Sites or Services. If
        You object to any such changes, Your sole recourse will be to cease access to the Sites or Services. Continued
        access to the Sites or Services following notice of any changes is deemed Your acceptance of such changes. You
        agree that we may immediately terminate Your access to the Sites and Services at any time, for any reason, in
        our sole discretion. You agree that we will not be liable to you or any other party for any termination of your
        access to the site or Services.
      </Text>
      <Heading size={'md'} mb={2}>
        Third Party Content
      </Heading>
      <Text mb={2}>
        15. Content from third parties (“Third Party Items”) may be made available to You through the Sites or the
        Services. We are not responsible for those Third Party Items. Clipsal Cortex assumes no responsibility for
        inaccurate, misleading or unlawful content made available by third parties or violation of any rights related to
        such content. The Sites and Services may contain links or hyperlinks to websites not operated by us. Clipsal
        Cortex is not responsible for the content, or practices (including privacy practices) of such websites. Clipsal
        Cortex provides hyperlinks for Your convenience only and we do not control such websites, our inclusion of links
        to such websites does not imply any endorsement of the third party websites or their operators. You may be
        exposed to third-party websites that You find offensive, indecent or objectionable. Clipsal Cortex accepts no
        responsibility for the content of third party websites accessible by hyperlink. You agree that in no event will
        we be liable to you in connection with any websites or practices of any third party.
      </Text>
      <Heading size={'md'} mb={2}>
        Disclaimer
      </Heading>
      <Text mb={2}>
        16. Clipsal Cortex makes no representations or warranties of any kind as to the operation of this Site or the
        Content or Services made available to you including, but not limited to, any warranties of merchantability or
        fitness for a particular purpose. You expressly agree that your use of the Sites and Services is at your sole
        risk. Clipsal Cortex makes no warranty that the Sites or Services will meet Your requirements, or that the Sites
        or Services will be uninterrupted, secure, or error free; nor do we make any warranty as to the results that may
        be obtained from the use of the Sites or Services, or that defects in the Sites or Services will be corrected.
        You understand and agree that You will be solely responsible for any damage to Your computer or other equipment
        or loss of data that results from the download of any material or Content. No advice or information obtained by
        You from us through the Sites or Services will create any warranty or representation not stated in these Terms
        of Use.
      </Text>
      <Heading mb={2} size={'md'}>
        Limitation of Liability
      </Heading>
      <Text mb={2}>
        17. You acknowledge and agree that we are only willing to provide access to the Sites and Services if you agree
        to certain limitations of our liability to you and to third parties. You understand that in no event will
        Clipsal Cortex or its officers and employees be liable for any indirect, incidental, special or consequential
        damages including, but not limited to, damages for loss of profits, goodwill, data, lost opportunities or
        business interruptions or other intangible losses arising out of or related to your use of or access to, or the
        inability to use or to access, the Sites, the Services or the equipment. Our total liability to you for all
        claims arising from or related to the site, services or equipment is limited, in aggregate, to the cost of
        subscription to the site. Some of the above limitations and disclaimers may not apply to You as a matter of law.
        To the extent that Clipsal Cortex may not, as a matter of law, disclaim any implied warranty or limit
        liabilities, the scope and duration of such warranty and the extent of our liability will be the minimum
        permitted under such laws. Without limiting the foregoing, under no circumstances will Clipsal Cortex be liable
        for any delay or failure in performance resulting from acts of nature, forces, or causes beyond our control.
      </Text>
      <Heading size={'md'} mb={2}>
        Indemnity
      </Heading>
      <Text mb={2}>
        18. You agree to indemnify and hold harmless Clipsal Cortex, its affiliates, directors, employees and agents
        from and against any third-party claims, damages, costs and expenses that arise from: (a) your use of the Sites
        or Services, including any Your Content; (b) your breach of these Terms of Use; (c) your violation of any rights
        of any other person; or (d) any viruses, malware or other similar harmful programming routines input by You into
        the Sites or Services.
      </Text>
      <Heading size={'md'} mb={2}>
        Release
      </Heading>
      <Text mb={2}>
        19. You release Clipsal Cortex, its officers and employees from all claims and demands and all losses, damages
        and actions of any kind including personal injuries, death and property damage arising from Your use of the
        Sites and Services.
      </Text>
      <Heading size={'md'} mb={2}>
        Electronic Communications
      </Heading>
      <Text mb={2}>
        20. You consent to Clipsal Cortex giving You Communications electronically and in order to enter into this
        agreement. You: (a) consent to receive communications from us in an electronic form; and (b) agree that all
        notices and other communications that we provide to You electronically satisfy any legal requirement that they
        be in writing.
      </Text>
      <Heading size={'md'} mb={2}>
        Cybersecurity
      </Heading>
      <Text mb={2}>21. You understand and agree that:</Text>
      <Text mb={2}>
        (a) the Sites and the Services are vulnerable to security breaches and are not immune from viruses, security
        threats or other vulnerabilities;
      </Text>
      <Text mb={2}>(b) your use of our Sites and Services is at Your own risk; and</Text>
      <Text mb={2}>
        (c) there are risks associated with the transmission or loss of data, including the interception of data by
        third parties. You are responsible for the security of Your Content and your own equipment. Under no
        circumstances will Clipsal Cortex or its officers, employees, affiliates or licensors be liable for loss or
        damage arising from cyberattacks or Your failure to take steps to prevent cyberattacks.
      </Text>
      <Heading size={'md'} mb={2}>
        Applicable Law
      </Heading>
      <Text mb={2}>
        22. You are responsible for compliance with all applicable laws. The Terms of Use and our relationship is
        governed by the laws of the State of New South Wales, Australia. Any legal action, suit or proceeding arising
        out of or relating to the Terms of Use, or Your use of the Services must be instituted exclusively in New South
        Wales, Australia and in no other jurisdiction.
      </Text>
      <Heading size={'md'} mb={2}>
        Rights Personal
      </Heading>
      <Text mb={2}>
        23. These Terms of Use are personal to You, and You may not transfer Your rights and duties under these Terms of
        Use to anyone else.
      </Text>
      <Heading size={'md'} mb={2}>
        No Waiver
      </Heading>
      <Text mb={2}>
        24. Any delay or failure by us to exercise or enforce any right or provision of these Terms of Use will not
        constitute a waiver of such right or provision. No waiver by us will have effect unless in writing, nor will any
        waiver constitute a waiver of any subsequent breach or default.
      </Text>
      <Heading size={'md'} mb={2}>
        Whole Agreement
      </Heading>
      <Text mb={2}>
        25. These Terms of Use constitute the whole agreement between You and us with respect to its subject matter. If
        for any reason a court finds any provision of these Terms of Use to be unenforceable, that provision will be
        enforced to the extent permissible so as to effect the intent of the parties and the remainder of these Terms of
        Use will continue in full force and effect.
      </Text>
      <Heading size={'md'} mb={2}>
        Survival
      </Heading>
      <Text mb={2}>
        26. The obligations of the parties contained in these Term of Use, as well as any other provisions limiting
        liability and our rights in and to the Site, Content provided by us, Our Technology and the Services, will
        survive the termination of these Terms of Use.
      </Text>
      <Heading size={'md'} mb={2}>
        Cancellation of Subscription
      </Heading>
      <Text mb={2}>
        27. You may cancel your subscription upon 28 days’ notice by emailing the following email address:{' '}
        <Link href="mailto:support@clipsalcortex.com">support@clipsalcortex.com</Link> and requesting cancellation.
      </Text>
      <Text mb={2}>
        If the cancellation request is provided 28 days prior to the subscription renewal date, then the subscription
        will not rollover and you will not be charged the subscription fee for the next renewal period.
      </Text>
      <Text mb={2}>
        If you cancel a subscription during the subscription period, you will not be entitled to a refund of any
        subscription fees already paid.
      </Text>
    </Box>
  );
}
