import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';

import { IS_NATIVE } from 'clipsal-cortex-utils/src/constants/common-constants';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';

import { BOTTOM_NAV_HEIGHT, IS_NOT_DEVELOPMENT, IS_PRODUCTION } from '../../common/constants';

const FLEET_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.clipsalsolar.onboarding';
const FLEET_APP_STORE_URL = 'itms-apps://apps.apple.com/app/id1583705822';
const FLEET_PACKAGE_NAME = 'com.clipsalsolar.onboarding';

// @NOTE: This is a hacky solution because deep links are not working atm. Once deep links work, remove this.
export const openFleetAppAtRoute = async (route: string) => {
  const fleetSiteUrl = `${IS_PRODUCTION ? 'fleet' : 'fleet-staging'}.clipsalcortex.com/${route}`;
  const fleetDeepLinkUrl = `onboarding://${fleetSiteUrl}`;
  const fleetWebUrl = `https://${fleetSiteUrl}`;
  /* istanbul ignore next -- @preserve */
  const isAndroid = Capacitor.getPlatform() === 'android';

  /* istanbul ignore else -- @preserve */
  if (!Capacitor.isNativePlatform()) {
    window.open(fleetWebUrl, '_blank');
  } else {
    const { value } = await AppLauncher.canOpenUrl({ url: isAndroid ? FLEET_PACKAGE_NAME : fleetDeepLinkUrl });
    await AppLauncher.openUrl({
      url: value ? fleetDeepLinkUrl : isAndroid ? FLEET_PLAY_STORE_URL : FLEET_APP_STORE_URL,
    });
  }
};

export const MOBILE_COLUMN_DISPLAY_TYPES = [
  {
    label: 'System type',
    value: 'system_type',
  },
  {
    label: 'Capacity',
    value: 'solar_capacity_kw',
  },
  {
    label: 'Company',
    value: 'tenant__tenant_name',
  },
];

export const OPERATING_STATUS_CONFIG = {
  fault: { badgeColor: 'customRed.500', label: 'Fault' },
  normal: { badgeColor: 'primaryBranding.500', label: 'Normal' },
  offline: { badgeColor: 'dusk050.500', label: 'Offline' },
};

/**
 * Common hook useful for getting the minimum component height for main container components, taking the bottom nav
 * into consideration.
 *
 * @returns The minimum height property value.
 */
export function useMinComponentHeight() {
  const { isDesktopViewport } = useViewportType();

  if (isDesktopViewport) {
    return `calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))`;
  }

  return `calc(100vh - ${BOTTOM_NAV_HEIGHT} - env(safe-area-inset-top))`;
}

/**
 * Finds the current base URL according to the environment the app is running in e.g. native, web staging/prod or local.
 * Useful for providing links to open the app from elsewhere (e.g. OAuth)
 */
export function getBaseURL() {
  let redirectURI = 'http://localhost:3030';
  if (IS_NATIVE) redirectURI = 'pulse:/';
  else if (IS_NOT_DEVELOPMENT) {
    if (IS_PRODUCTION) redirectURI = 'https://app.clipsalcortex.com';
    else redirectURI = 'https://app-staging.clipsalcortex.com';
  }

  return redirectURI;
}
