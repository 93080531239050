import { Action, combineReducers, configureStore, PayloadAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import evChargerReducer from '../features/ev-chargers/evChargerSlice';
import schneiderChargeSetupReducer from '../features/ev-chargers/schneider-charge/schneiderChargeSetupSlice';
import unmeasuredAppliancesSlice from '../features/live/unmeasuredAppliancesSlice';
import profileReducer from '../features/profile/profileSlice';
import savingsReducer from '../features/savings/savingsSlice';
import schedulerReducer from '../features/scheduler/schedulerSlice';
import siteReducer from '../features/site/siteSlice';
import sitesReducer from '../features/sites/sitesSlice';
import userReducer from '../features/user/userSlice';
import smartSaveReducer from './../features/profile/smart-save/smartSaveSlice';
import { baseApi } from './services/baseApi';

const rootReducer = combineReducers({
  sites: sitesReducer,
  savings: savingsReducer,
  user: userReducer,
  site: siteReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  profile: profileReducer,
  unmeasuredAppliances: unmeasuredAppliancesSlice,
  scheduler: schedulerReducer,
  schneiderChargeSetup: schneiderChargeSetupReducer,
  evCharger: evChargerReducer,
  smartSave: smartSaveReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const resettableRootReducer = (state: RootState | undefined, action: PayloadAction<unknown>) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware);
  },
});

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type ReduxDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
