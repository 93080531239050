import React from 'react';
import { Flex, Heading, Image } from '@chakra-ui/react';

import Card from 'clipsal-cortex-ui/src/components/card/Card';

import errorImage from '../../assets/images/not_found_exclaimation.svg';

type Props = React.PropsWithChildren;

export default function GenericErrorCard({ children }: Props) {
  return (
    <Card>
      <GenericErrorNoCard>{children}</GenericErrorNoCard>
    </Card>
  );
}

export function GenericErrorNoCard({ children }: Props) {
  return (
    <Flex height={'100%'} my={8} justify={'center'} align={'center'} direction={'column'} width={'100%'}>
      <Image src={errorImage} alt={'Red error exclamation mark'} />
      <Heading textAlign={'center'} m={2} size={'md'}>
        {children}
      </Heading>
    </Flex>
  );
}
