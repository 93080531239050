import * as yup from 'yup';

export type WifiDetailsFormData = {
  ssid: string;
  password: string;
};

export const wifiDetailsSchema = yup.object().shape({
  ssid: yup.string().required('This is required'),
  password: yup.string().required('This is required'),
});
