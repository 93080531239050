import React from 'react';
import { Browser } from '@capacitor/browser';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Skeleton,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ENERGY_MASTERS_TENANT_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import amberLogoImg from '../../../assets/images/amber_logo.svg';
import sapnLogoDark from '../../../assets/images/sapn_logo_dark.svg';
import sapnLogo from '../../../assets/images/sapn_logo.svg';
import { selectSite } from '../../site/siteSlice';
import ProfilePageBase from '../ProfilePageBase';
import { SAPN_ENERGY_MASTERS_WEBSITE_URL } from './smart-save-helpers';
import { useSmartSaveStatus } from './use-smartsave-status';

const SmartSaveSettings = () => {
  const { smartSaveStatus, isLoading } = useSmartSaveStatus();
  const borderColor = useColorModeValue('gray.300', 'black');
  const background = useColorModeValue('white', 'dusk100.800');
  const navigate = useNavigate();
  const { tenant_id } = useSelector(selectSite);
  const isEnergyMastersSite = tenant_id === ENERGY_MASTERS_TENANT_ID;

  return (
    <ProfilePageBase title={'SmartSave Settings'}>
      <Box pt={4}>
        <Text px={4}>
          SmartSave helps save on bills by optimising your home energy use and through access to partner programs.
        </Text>

        <Skeleton isLoaded={!isLoading}>
          <Flex
            data-testid="smartsave-selected-option"
            borderBottom="1px solid"
            borderColor={borderColor}
            background={background}
            justify={'space-between'}
            align={'center'}
            py={4}
            my={6}
            px={6}
            onClick={() => navigate('optimisation-settings')}
            cursor={'pointer'}
          >
            <Heading fontSize={18}>Optimisations</Heading>
            <Button
              rightIcon={<ChevronRightIcon w={'26px'} h={'26px'} />}
              variant={'ghost'}
              fontWeight={'normal'}
              data-testid="select-smart-save-option"
            >
              {smartSaveStatus}
            </Button>
          </Flex>
        </Skeleton>

        {isEnergyMastersSite && (
          <Text fontSize={'12px'} mb={2} ml={4}>
            PARTNER PROGRAM(S)
          </Text>
        )}
        {isEnergyMastersSite && <SAPNPartnerProgram />}
        {/* @TODO: Enable when Amber retailer integration is setup */}
      </Box>
    </ProfilePageBase>
  );
};

export default SmartSaveSettings;

export const SAPNPartnerProgram = () => {
  const background = useColorModeValue('white', 'dusk100.800');
  const logoSource = useColorModeValue(sapnLogo, sapnLogoDark);

  return (
    <Box bg={background} p={6}>
      <Center>
        <Center>
          <Box color="red">
            <Image src={logoSource} w={65} h={38} />
          </Box>
          <Text fontWeight={600} ml={2}>
            SAPN
          </Text>
        </Center>
        <Center ml="auto">
          <Box my="auto" w={2} h={2} bg="green.400" borderRadius={'100%'} />
          <Text ml={1}>Active</Text>
        </Center>
      </Center>
      <Text mt={2} fontSize={'14px'}>
        An active connection allows SmartSave to adjust your home's energy via connected devices in response to import
        limit events happening on the grid.
        <Box as="span" ml={1} color={'batteryCharged.500'}>
          <Link
            onClick={async () => {
              /* istanbul ignore next -- @preserve */
              await Browser.open({
                url: SAPN_ENERGY_MASTERS_WEBSITE_URL,
              });
            }}
          >
            Find out more...
          </Link>
        </Box>
      </Text>
      <Text my={4} fontSize={'14px'}>
        By helping the grid, you are rewarded with a subsidy on your energy bill!
      </Text>
      <Text fontSize={'14px'}>To deactivate this, contact Energy Masters.</Text>
    </Box>
  );
};

type AmberPartnerProgramProps = {
  showBorder?: boolean;
};
export const AmberPartnerProgram = ({ showBorder }: AmberPartnerProgramProps) => {
  const theme = useTheme();
  const background = useColorModeValue('white', 'dusk100.800');
  const borderColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[500]);

  return (
    <Box bg={background} p={6} borderTop={showBorder ? `1px solid ${borderColor}` : undefined}>
      <Center>
        <Center>
          <Image src={amberLogoImg} w={65} h={38} />
          <Text fontWeight={600} ml={2}>
            Amber
          </Text>
        </Center>
        <Center ml="auto">
          <Box my="auto" w={2} h={2} bg="green.400" borderRadius={'100%'} />
          <Text ml={1}>Active</Text>
        </Center>
      </Center>
      <Text mt={2} fontSize={'14px'}>
        An active connection allows SmartSave to connect your home battery with Amber's SmartShift™ to make the most of
        wholesale prices.
      </Text>
    </Box>
  );
};
