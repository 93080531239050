import React from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import CustomDatePicker from '../../../../../common/components/date-picker/CustomDatePicker';
import Dialog from '../../../../../common/components/Dialog';
import { TariffFormData } from '../types';
import CustomAddButton from './CustomAddButton';
import { mapValuesToSeasonForm } from './utils';
import { schema } from './validation-schema';

type Props = DialogProps & {
  onSubmit: (values: SeasonFormData) => void;
};

export type SeasonFormData = {
  shouldApplySeasons: boolean;
  seasons: Season[];
};

// Represents the shape of a season in this specific form, noting that it varies outside of this context.
export type Season = {
  name: string;
  fromDate: Date;
  toDate: Date;
};

export default function SeasonConfigurationFormDialog({ isOpen, onClose, onSubmit }: Props) {
  const { getValues: getParentValues } = useFormContext<TariffFormData>();
  const toast = useToast({ duration: 5000 });
  // A new form is necessary to isolate the state of the dialog from the parent form, which improves UX
  // by providing season validation errors in this form, rather than when users submit the whole form.
  const {
    watch,
    control,
    handleSubmit: handleFormSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<SeasonFormData>({
    resolver: yupResolver(schema),
    defaultValues: mapValuesToSeasonForm(getParentValues()), // Populate from parent form values in context
  });
  const {
    fields: seasonFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'seasons',
  });
  const shouldApplySeasons = watch('shouldApplySeasons');

  function handleValidationError(errors: FieldErrors<SeasonFormData>) {
    if (errors?.seasons?.type === 'cover-entire-year') {
      // Just showing a toast for now, not sure which UI elements to highlight
      toast({
        status: 'error',
        title: 'Invalid seasons!',
        description: "The provided seasons' time ranges overlap, or do not cover all 365 days of the year.",
      });
    }
  }

  function handleSubmit(values: SeasonFormData) {
    onSubmit(values);
    onClose();
  }

  return (
    <Dialog
      drawerContentProps={{ height: '80%' }}
      showCloseButton
      onClose={() => {
        reset();
        onClose();
      }}
      isOpen={isOpen}
      title="Season Configuration"
    >
      <Box
        px={2}
        data-testid="season-config-form"
        as="form"
        onSubmit={handleFormSubmit(handleSubmit, handleValidationError)}
      >
        <FormControl px={5} as={Flex} justify="space-between">
          <FormLabel fontWeight="bold">Apply Seasons</FormLabel>
          <Controller
            control={control}
            name="shouldApplySeasons"
            render={({ field: { onChange, value, ref } }) => {
              return (
                <RadioGroup
                  data-testid="should-apply-seasons-radio-group"
                  data-radio-value={value ? 'YES' : 'NO'}
                  onChange={(e) => {
                    onChange(e === 'YES');
                  }}
                  ref={ref}
                  value={value ? 'YES' : 'NO'}
                >
                  <Flex>
                    <Radio data-testid="radio-value-yes" colorScheme="primaryBranding" mr={4} value="YES">
                      Yes
                    </Radio>
                    <Radio data-testid="radio-value-no" colorScheme="primaryBranding" mr={4} value="NO">
                      No
                    </Radio>
                  </Flex>
                </RadioGroup>
              );
            }}
          />
        </FormControl>

        <Divider my={3} />

        {shouldApplySeasons &&
          seasonFields.map((seasonField, seasonIndex) => {
            const errorsForSeason = errors?.seasons?.[seasonIndex];
            const seasonOverlapError = errors?.seasons;

            return (
              <Box data-testid={`season-${seasonIndex}-form`} mt={seasonFields.length > 1 ? 3 : 0} key={seasonField.id}>
                {seasonIndex > 0 && <Divider mx={-2} borderWidth={10} />}

                <FormControl isInvalid={!!errorsForSeason?.name} mt={3}>
                  <FormLabel fontWeight="bold">
                    <Flex align="center">
                      <FormLabel mb={0} mr={0} fontWeight="bold">
                        Season Name
                      </FormLabel>

                      {seasonIndex > 0 && (
                        <IconButton
                          ml={1}
                          size="sm"
                          onClick={() => remove(seasonIndex)}
                          color="customRed.500"
                          variant="ghost"
                          data-testid={`remove-season-${seasonIndex}-button`}
                          icon={<DeleteIcon />}
                          aria-label={`Delete season`}
                        />
                      )}
                    </Flex>
                  </FormLabel>
                  <Input
                    data-testid={`season-${seasonIndex}-name`}
                    {...register(`seasons.${seasonIndex}.name`)}
                    placeholder="e.g. Summer"
                  />
                  <FormErrorMessage data-testid={`season-${seasonIndex}-name-error-message`}>
                    {errorsForSeason?.name?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errorsForSeason?.fromDate || !!seasonOverlapError} mt={3}>
                  <FormLabel fontWeight="bold">Start Date</FormLabel>
                  <Controller
                    control={control}
                    name={`seasons.${seasonIndex}.fromDate`}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        withPortal
                        inputProps={{
                          'data-testid': `season-${seasonIndex}-from-date`,
                        }}
                        dateFormat="MMMM d"
                        showYearPicker={false}
                        onChange={(e) => {
                          // If users move to a leap year, ensure they can't select the 29th of Feb (reverts to 28th)
                          if (e && e.getMonth() === 1 && e.getDate() === 29) {
                            e.setDate(28);
                          }
                          // Ensure the user can't change the year via the period change buttons
                          e?.setFullYear(2021);
                          onChange(e);
                        }}
                        selected={value}
                      />
                    )}
                  />

                  <FormErrorMessage data-testid={`season-${seasonIndex}-from-date-error-message`}>
                    {errorsForSeason?.fromDate?.message || seasonOverlapError?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errorsForSeason?.toDate || !!seasonOverlapError} mt={3}>
                  <FormLabel fontWeight="bold">End Date</FormLabel>
                  <Controller
                    control={control}
                    name={`seasons.${seasonIndex}.toDate`}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        withPortal
                        inputProps={{
                          'data-testid': `season-${seasonIndex}-to-date`,
                        }}
                        dateFormat="MMMM d"
                        showYearPicker={false}
                        onChange={(e) => {
                          // If users move to a leap year, ensure they can't select the 29th of Feb (reverts to 28th)
                          if (e && e.getMonth() === 1 && e.getDate() === 29) {
                            e.setDate(28);
                          }
                          // Ensure the user can't change the year via the period change buttons
                          e?.setFullYear(2021);
                          onChange(e);
                        }}
                        selected={value}
                      />
                    )}
                  />
                  <FormErrorMessage data-testid={`season-${seasonIndex}-to-date-error-message`}>
                    {errorsForSeason?.toDate?.message || seasonOverlapError?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            );
          })}

        {shouldApplySeasons && (
          <CustomAddButton
            mt={5}
            data-testid="add-season-btn"
            onClick={() => {
              const fromDate = new Date('2021-01-01');
              fromDate.setHours(0, 0, 0, 0);
              const toDate = new Date(fromDate);

              append({
                name: 'Custom Season',
                fromDate,
                toDate,
              });
            }}
          >
            Add Another Season
          </CustomAddButton>
        )}

        {!shouldApplySeasons && <Text>By selecting "No", all import rates will be applied all year round.</Text>}

        <Center mt={6}>
          <Button
            data-testid="submit-season-form"
            type="submit"
            w={['75%', '75%', '50%']}
            rounded={20}
            colorScheme="dusk100"
          >
            Save
          </Button>
        </Center>
      </Box>
    </Dialog>
  );
}
