/**
 * Core source of truth for experimental features in the app.
 */
export enum ExperimentalFeature {
  EnergyMastersBranding = 'enableEnergyMastersBranding',
}

/**
 * Retrieves an experimental feature flag value from local storage, returning a boolean value.
 *
 * @param flagName - The flag name in question.
 * @returns Whether the flag is enabled.
 */
export function getFlagValue(flagName: ExperimentalFeature): boolean {
  return localStorage.getItem(flagName) === 'true';
}

/**
 * Sets an experimental feature flag value in local storage. See the `ExperimentalFeatures` component for further
 * details.
 *
 * @param flagName - The string name of the flag to set in local storage.
 * @param value - The boolean value to set in local storage. Saves as a string.
 */
export function setFlagValue(flagName: ExperimentalFeature, value: boolean): void {
  localStorage.setItem(flagName, value.toString());
}
