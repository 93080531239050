import * as yup from 'yup';

import { TARIFF_TYPES } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { REAL_TIME_RETAILER_IDS } from '../../../../common/constants';
import { MAX_DOLLARS_PER_KWH_VALUE } from '../constants';

export const basicDetailsSchema = yup.object().shape({
  retailer: yup.mixed().when({
    is: (value?: object) => value !== undefined,
    then: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    otherwise: yup.mixed().notRequired(),
  }),
  startDate: yup.date().required('This field is required'),
  dailySupplyCharge: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .typeError('This field is required')
    .min(0)
    .when('retailer', {
      is: (value: { label: string; value: number } | undefined) =>
        value && REAL_TIME_RETAILER_IDS.includes(value.value),
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .typeError('This field is required')
        .min(0)
        .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
        .required(),
    }),
  tariffType: yup.string().when('retailer', {
    is: (value: { label: string; value: number } | undefined) => value && REAL_TIME_RETAILER_IDS.includes(value.value),
    then: yup.string().oneOf([...TARIFF_TYPES]),
    otherwise: yup
      .string()
      .oneOf([...TARIFF_TYPES])
      .required(),
  }),
});
