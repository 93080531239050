import { RateTier } from '../types';
import { ChargePeriodSelectOption, TieredRateSeason, TieredTariffFormData } from './types';

export const EMPTY_TIER_TEMPLATE: RateTier = {
  rate: null,
  upperLimitKWh: null,
};

export const EMPTY_SEASON_TEMPLATE: TieredRateSeason = {
  seasonIndex: 0,
  name: 'Full Year',
  fromMonth: 0,
  fromDate: 1,
  toMonth: 11,
  toDate: 31,
  tiers: [EMPTY_TIER_TEMPLATE, EMPTY_TIER_TEMPLATE],
  chargePeriod: { label: 'Daily', value: 'DAILY' },
};

export const TIERED_FORM_DEFAULT_VALUE: TieredTariffFormData = {
  shouldApplySeasons: false,
  ratesAreInclusiveOfGST: true,
  seasons: [EMPTY_SEASON_TEMPLATE],
};

export const CHARGE_PERIOD_SELECT_OPTIONS: ChargePeriodSelectOption[] = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Quarterly', value: 'QUARTERLY' },
  { label: 'Annually', value: 'ANNUALLY' },
];
