import React from 'react';
import { Browser } from '@capacitor/browser';
import { Box, Divider, Flex, Heading, Image, Skeleton, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatNiceDate } from 'se-home/cypress/utils/formatting';

import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import amberLogoImg from '../../../assets/images/amber_logo.svg';
import sensiboLogoImg from '../../../assets/images/sensibo_logo.png';
import teslaLogoImg from '../../../assets/images/tesla_logo.svg';
import MobileTopNav from '../../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT, IS_DEMO_LOGIN, PAGE_SPACING_PER_VIEWPORT } from '../../../common/constants';
import useViewportType from '../../../common/hooks/use-viewport-type';
import { getBaseURL } from '../../../utils/common/common-utils';
import useIntegrateSensibo from '../../live/sensibo-integration/useIntegrateSensibo';
import { selectSite } from '../../site/siteSlice';
import { TESLA_CLIENT_ID, TESLA_SCOPES } from './constants';
import { useGetConnectionStatusQuery } from './tesla/teslaApi';

export default function ThirdPartyIntegrationsMobile() {
  const { isDesktopViewport } = useViewportType();
  const site = useSelector(selectSite);

  return (
    <Box>
      <MobileTopNav title={'Integrations'} backURL={`/site/${site.site_id}/profile`} />
      <Box
        mb={isDesktopViewport ? undefined : BOTTOM_NAV_HEIGHT}
        mt={isDesktopViewport ? undefined : TOP_NAV_SPACING_AFFORDANCE}
      >
        <Card mx={PAGE_SPACING_PER_VIEWPORT}>
          <Heading size={'sm'}>Integrations</Heading>
          <ThirdPartyIntegrations />
        </Card>
      </Box>
    </Box>
  );
}

export function ThirdPartyIntegrations() {
  return (
    <Box data-testid="integration-list">
      <Divider mt={2} />
      <TeslaIntegration />
      <Divider />
      <SensiboIntegration />
      <Divider />
      <AmberIntegration />
    </Box>
  );
}

export function TeslaIntegration() {
  const { site_id: siteId } = useSelector(selectSite);
  const { data: connectionStatus, isLoading, isError } = useGetConnectionStatusQuery(siteId);
  const isConnected = !isError && !isLoading && connectionStatus?.status === 'success';
  const navigate = useNavigate();

  const {
    isOpen: isTeslaConnectionAlertDialogOpen,
    onOpen: onOpenTeslaConnectionAlertDialog,
    onClose: onCloseTeslaConnectionAlertDialog,
  } = useDisclosure();

  async function handleClick() {
    if (isConnected) {
      navigate(`/site/${siteId}/profile/manage-tesla-integration`);
    } else {
      onOpenTeslaConnectionAlertDialog();
    }
  }

  async function handleBeginTeslaAccountConnection() {
    const redirectURI = `${getBaseURL()}/tesla`;

    const url =
      'https://auth.tesla.com/oauth2/v3/authorize' +
      `?client_id=${TESLA_CLIENT_ID}` +
      `&redirect_uri=${redirectURI}` +
      `&response_type=code` +
      `&scope=${TESLA_SCOPES.join(' ')}` +
      `&state=siteID:${siteId}`;

    /* istanbul ignore if -- @preserve */
    await Browser.open({ url, windowName: '_self' });
  }

  function getHeadingCopy() {
    return isConnected ? 'Manage My Tesla Integration' : 'I have a Tesla Powerwall or EV';
  }

  function getSubHeadingCopy() {
    return isConnected
      ? `Last connected ${formatNiceDate(new Date(connectionStatus.last_modified))}`
      : 'Log in to your account to see your battery state of charge';
  }

  return (
    <>
      <Skeleton isLoaded={!isLoading} w="100%">
        <Flex
          data-testid="tesla-integration-button"
          pointerEvents={IS_DEMO_LOGIN ? 'none' : 'initial'}
          opacity={IS_DEMO_LOGIN ? 0.5 : 1}
          onClick={handleClick}
          role="button"
          align={'center'}
          py={3}
          px={4}
          cursor={'pointer'}
        >
          <Image src={teslaLogoImg} />
          <Box ml={4}>
            <Heading size={'sm'}>{getHeadingCopy()}</Heading>
            <Text fontSize="sm" color="rgba(0, 0, 0, 0.7)" _dark={{ color: 'white' }}>
              {getSubHeadingCopy()}
            </Text>
          </Box>
        </Flex>
      </Skeleton>

      <AlertDialogModal
        header="You will be taken to the official Tesla website"
        subHeader={
          'Please make sure you tick all appropriate permission request boxes (e.g. Energy Data for a' +
          ' Powerwall, Vehicle Commands/Vehicle Charging Commands for EVs) when prompted.'
        }
        isOpen={isTeslaConnectionAlertDialogOpen}
        onClose={onCloseTeslaConnectionAlertDialog}
        onConfirm={handleBeginTeslaAccountConnection}
      />
    </>
  );
}

export function SensiboIntegration() {
  const { site_id: siteId, integrations } = useSelector(selectSite);
  const { isAuthorising, handleSensiboLogin } = useIntegrateSensibo(true);
  const navigate = useNavigate();
  const isConnected = !!integrations.sensibo;

  const getHeadingCopy = () => {
    if (isAuthorising) {
      return 'Authorising Sensibo...';
    } else if (isConnected) {
      return 'Manage Sensibo integration';
    } else {
      return 'I have a Sensibo Device';
    }
  };

  const getSubHeadingCopy = () => {
    if (isAuthorising) {
      return 'Please wait...';
    } else if (isConnected) {
      return 'Click to manage integration';
    } else {
      return 'Log in to your account to control and save on your A/C!';
    }
  };

  return (
    <Flex
      align={'center'}
      py={3}
      cursor={'pointer'}
      pointerEvents={IS_DEMO_LOGIN ? 'none' : 'initial'}
      data-testid="sensibo-integration-container"
      textAlign={'start'}
      opacity={isAuthorising || IS_DEMO_LOGIN ? 0.5 : 1}
      onClick={() => {
        if (isAuthorising) return;
        if (isConnected) {
          // when connected, let users manage the integration
          navigate(`/site/${siteId}/live?isSensiboIntegrationDrawerOpen=true`);
        } else {
          handleSensiboLogin();
        }
      }}
    >
      {isAuthorising ? (
        <Flex w={'65px'}>
          <Spinner size="md" mx="auto" />
        </Flex>
      ) : (
        <Image src={sensiboLogoImg} maxW="65px" />
      )}

      <Box ml={2} pr={4} maxW="100%">
        <Heading size={'sm'}>{getHeadingCopy()}</Heading>
        <Text fontSize="sm" color="rgba(0, 0, 0, 0.7)" _dark={{ color: 'white' }}>
          {getSubHeadingCopy()}
        </Text>
      </Box>
    </Flex>
  );
}

export function AmberIntegration() {
  const { site_id: siteId, integrations } = useSelector(selectSite);
  const navigate = useNavigate();
  const isConnected = !!integrations.amber;

  const getHeadingCopy = () => {
    if (isConnected) {
      return 'Manage Amber integration';
    } else {
      return 'I have an Amber Account';
    }
  };

  const getSubHeadingCopy = () => {
    if (isConnected) {
      return 'Click to manage integration';
    } else {
      return 'Input your Amber API key to apply your real-time energy rates in Cortex.';
    }
  };

  return (
    <Flex
      align={'center'}
      py={3}
      cursor={'pointer'}
      data-testid="amber-integration-container"
      textAlign={'start'}
      pointerEvents={IS_DEMO_LOGIN ? 'none' : 'initial'}
      opacity={IS_DEMO_LOGIN ? 0.5 : 1}
      onClick={() => navigate(`/site/${siteId}/bills/amber_integration`)}
    >
      <Image src={amberLogoImg} maxW="65px" />

      <Box ml={2} pr={4} maxW="100%">
        <Heading size={'sm'}>{getHeadingCopy()}</Heading>
        <Text fontSize="sm" color="rgba(0, 0, 0, 0.7)" _dark={{ color: 'white' }}>
          {getSubHeadingCopy()}
        </Text>
      </Box>
    </Flex>
  );
}
