import { useEffect, useState } from 'react';
import { App as CapApp } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

/**
 * Uses Capacitor's `appStateChange` event to return a boolean state value, representing whether the app is currently
 * visible to the user.
 *
 * @param onChangeVisibility - Optional event handler for visibility state changes. Make sure you memoize this.
 * @returns Whether the app is visible, as a state value.
 */
export default function useAppVisibility(onChangeVisibility?: (isActive: boolean) => void) {
  const [isAppVisible, setIsAppVisible] = useState<boolean>(true);

  useEffect(() => {
    let listener: PluginListenerHandle;

    // This mechanism exists
    async function addVisibilityChangeListener() {
      listener = await CapApp.addListener('appStateChange', async (state) => {
        setIsAppVisible(state.isActive);
        onChangeVisibility && onChangeVisibility(state.isActive);
      });
    }

    addVisibilityChangeListener();

    return () => {
      listener?.remove();
    };
  }, [onChangeVisibility]);

  return isAppVisible;
}
