import React from 'react';
import { Center } from '@chakra-ui/layout';
import { Button, Heading, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import ProfilePageBase from '../../ProfilePageBase';

export function VehiclePairingTroubleshooting() {
  const navigate = useNavigate();

  return (
    <ProfilePageBase
      containerProps={{ px: [2, 2, 0], 'data-testid': 'tesla-vehicle-pairing-troubleshooting' }}
      title={'Vehicle Pairing Troubleshooting'}
    >
      <Heading mb={1} size="md">
        If pressing Finish Setup doesn't open the Tesla app
      </Heading>
      <UnorderedList>
        <ListItem>(iOS) Set your phone's default browser to Safari</ListItem>
        <ListItem>(iOS) Scroll up and tap the blue Open button at the top of the webpage</ListItem>
        <ListItem>Android) Use the Firefox browser and select "Open in app" in the menu</ListItem>
        <ListItem>Reinstall the Tesla app</ListItem>
      </UnorderedList>

      <Heading mb={1} mt={3} size="md">
        If the Virtual Key prompt doesn't appear in the Tesla app
      </Heading>
      <Text>Force close the Tesla app and try again.</Text>

      <Heading mb={1} mt={3} size="md">
        If the Tesla app says "Unable to Share Vehicle: Request timed out"
      </Heading>
      <Text>Improve the vehicle's connectivity by changing it to use WiFi, cellular or a mobile hotspot.</Text>

      <Heading mb={1} mt={3} size="md">
        If pressing Finish Setup doesn't open the Tesla app
      </Heading>
      <OrderedList>
        <ListItem>In the Tesla app, tap Security &gt; Phone Key Best Practices &gt; Reset Phone Key</ListItem>
        <ListItem>In the Tesla app, set up Phone Key again</ListItem>
        <ListItem>
          In the Cortex app, tap Profile &gt; Manage Tesla Integration &gt; Pair Tesla Vehicle and try again
        </ListItem>
      </OrderedList>

      <Center>
        <Button
          mt={5}
          w={['75%', '75%', '75%']}
          rounded={20}
          mb={2}
          colorScheme="dusk100"
          onClick={() => navigate(-1)}
          data-testid="inline-back-btn"
          variant="outline"
        >
          Back
        </Button>
      </Center>
    </ProfilePageBase>
  );
}
