import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, IconButton, Image, Text } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react';
import { Controller, useFormContext } from 'react-hook-form';

import { TARIFF_TYPES, TariffType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import InfoPopover from 'clipsal-cortex-ui/src/components/InfoPopover';

import flatTariffImg from '../../../../assets/images/flat_tariff.svg';
import tieredTariffImg from '../../../../assets/images/tiered_tariff.svg';
import touTariffImg from '../../../../assets/images/tou_tariff.svg';
import { BasicDetailsFormData } from './types';

const BUTTON_ICON_SIZE_PX = 36;

type TariffTypeConfig = {
  imageSrc: string;
  type: TariffType;
  title: string;
  description: string;
};

const TARIFF_TYPE_CONFIG: TariffTypeConfig[] = [
  {
    imageSrc: touTariffImg,
    type: 'TOU',
    title: 'Time of Use Rates',
    description: 'You experience peak and off-peak periods with different price points',
  },
  {
    imageSrc: tieredTariffImg,
    type: 'TIERED',
    title: 'Tiered Rates',
    description: 'Your energy rate prices are set by usage tiers',
  },
  {
    imageSrc: flatTariffImg,
    type: 'FLAT',
    title: 'Flat Rates',
    description: 'Your energy rate is the same price at all times',
  },
];

const APPLICABLE_TARIFF_TYPES = TARIFF_TYPES.filter((type) => type !== 'REAL_TIME');

export default function TariffTypeSelect() {
  const { control, setValue, getValues } = useFormContext<BasicDetailsFormData>();
  const [ref, carousel] = useEmblaCarousel({ loop: true });
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0);

  useEffect(() => {
    // Populate value on first render
    const defaultValue = getValues('tariffType');
    if (defaultValue) {
      // Set the form value in the UI
      const indexOfSelectedValue = TARIFF_TYPE_CONFIG.findIndex(({ type }) => type === defaultValue);
      if (indexOfSelectedValue < 0) {
        throw new Error(
          `Invalid index for tariff type value. ${defaultValue} was supplied,
           but the array is length: ${TARIFF_TYPE_CONFIG.length}`
        );
      } else {
        setSelectedCardIndex(indexOfSelectedValue);
      }
    } else {
      setValue('tariffType', TARIFF_TYPE_CONFIG[0].type);
    }

    // Attach handler in case it changes later
    const setIndexOnSelect = () => {
      if (carousel?.slidesInView()) {
        const newIndex = carousel?.slidesInView(true)[0];
        if (newIndex !== undefined) {
          setSelectedCardIndex(newIndex);
          setValue('tariffType', TARIFF_TYPE_CONFIG[newIndex].type);
        }
      }
    };

    carousel?.on('select', setIndexOnSelect);
  }, [carousel, getValues, setValue]);

  // When the index changes somewhere else, update the slides in view
  useEffect(() => {
    const slidesInView = carousel?.slidesInView();

    // Only move to the new slide if it's not currently in the view
    if (slidesInView && !slidesInView.includes(selectedCardIndex)) {
      carousel?.scrollTo(selectedCardIndex);
    }
  }, [carousel, selectedCardIndex]);

  return (
    <Controller
      control={control}
      name="tariffType"
      render={({ field: { onChange } }) => (
        <Box data-testid="tariff-type-select">
          <Text fontWeight="bold">Tariff Type</Text>
          <Text zIndex={999}>
            What is this?<InfoPopover>Tariff type explanation</InfoPopover>
          </Text>
          <Center pos={'relative'}>
            <IconButton
              data-testid="tariff-type-previous-btn"
              rounded={20}
              variant={'ghost'}
              aria-label={'Move left'}
              zIndex={5}
              position={'absolute'}
              left={['5%', '20%', '20%', '30%']}
              bottom={`calc(50% - ${BUTTON_ICON_SIZE_PX / 2}px)`}
              onClick={() => {
                let newIndex = TARIFF_TYPE_CONFIG.length - 1;
                if (selectedCardIndex > 0) {
                  newIndex = selectedCardIndex - 1;
                }
                setSelectedCardIndex(newIndex);

                onChange(TARIFF_TYPE_CONFIG[newIndex].type);
              }}
              icon={<ChevronLeftIcon w={'36px'} h={'36px'} />}
            />
            <Box width={'100%'} overflow={'hidden'} ref={ref}>
              <Flex width={'100%'}>
                {TARIFF_TYPE_CONFIG.map(({ title, imageSrc, description, type }, index) => (
                  <Center
                    flexDirection="column"
                    data-testid={`tariff-type-select-carousel-slide-${type}`}
                    data-carousel-visible={selectedCardIndex === index ? true : undefined}
                    key={type}
                    ml={['10%', '10%', '10%', '25%']}
                    mr={['10%', '10%', '10%', '25%']}
                    minWidth={0}
                    flex={['0 0 80%', '0 0 80%', '0 0 80%', '0 0 50%']}
                  >
                    <Image src={imageSrc} />
                    <Text mt={2} fontWeight="bold" textAlign={'center'}>
                      {title}
                    </Text>
                    <Text textAlign={'center'}>{description}</Text>
                  </Center>
                ))}
              </Flex>
            </Box>
            <IconButton
              data-testid="tariff-type-next-btn"
              rounded={20}
              variant={'ghost'}
              aria-label={'Move right'}
              zIndex={5}
              position={'absolute'}
              right={['5%', '20%', '20%', '30%']}
              bottom={`calc(50% - ${BUTTON_ICON_SIZE_PX / 2}px)`}
              onClick={() => {
                let newIndex = 0;
                if (selectedCardIndex < APPLICABLE_TARIFF_TYPES.length - 1) {
                  newIndex = selectedCardIndex + 1;
                }
                setSelectedCardIndex(newIndex);
                onChange(TARIFF_TYPE_CONFIG[newIndex].type);
              }}
              icon={<ChevronRightIcon w={`${BUTTON_ICON_SIZE_PX}px`} h={`${BUTTON_ICON_SIZE_PX}px`} />}
            />
          </Center>
        </Box>
      )}
    />
  );
}
