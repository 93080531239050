export const formatPhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[0] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export enum DateStringFormatType {
  DayShortMonthYear,
  DayLongMonthYear,
  DayMonthYear,
}

export const dateTypeToFormatOpts: Record<DateStringFormatType, object> = {
  [DateStringFormatType.DayShortMonthYear]: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [DateStringFormatType.DayLongMonthYear]: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  [DateStringFormatType.DayMonthYear]: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
};

/**
 * Formats a date string from the API to a nice format, specified by the `format` parameter.
 *
 * @param dateString - The date string type.
 * @param format - The format enum type.
 * @param locales - One or more locales to format the date string with. Optional.
 */
export function formatNiceDateFromString(
  dateString: string | null,
  format: DateStringFormatType = DateStringFormatType.DayShortMonthYear,
  locales?: string | string[]
): string {
  if (!dateString) return '';

  // First, format the date string so it's uniformly formatted across browsers. Safari sucks.
  const formattedDateString = dateString.replace(/-/g, '/');
  return formatNiceDate(new Date(formattedDateString), format, locales);
}

/**
 * Formats a `Date` object to a nice format, specified by the `format` parameter.
 *
 * @param date - The `Date` object to format.
 * @param format - The format enum type.
 * @param locales - One or more locales to format the date string with. Optional.
 */
export function formatNiceDate(
  date: Date | null,
  format: DateStringFormatType = DateStringFormatType.DayShortMonthYear,
  locales?: string | string[]
): string {
  if (!date) return '';

  return date.toLocaleDateString(locales, dateTypeToFormatOpts[format]);
}

/**
 * Formats a date to 12 hour time.
 *
 * @param date
 */
export function formatTo12HourTime(date: Date) {
  const minutes = date.getMinutes();
  let hours = date.getHours();
  let isPM = false;

  if (hours >= 12) {
    isPM = true;
    hours -= 12;
  }

  return `${hours}:${('0' + minutes).slice(-2)} ${isPM ? 'pm' : 'am'}`;
}
