import { BillForecast } from 'clipsal-cortex-types/src/api/api-bill-forecast';
import { Bill } from 'clipsal-cortex-types/src/api/api-bills';
import { CostsData } from 'clipsal-cortex-types/src/api/api-costs';
import { RPCData } from 'clipsal-cortex-types/src/api/api-rpc';
import { getStringifiedQueryParams } from 'clipsal-cortex-utils/src/formatting/query-params';

import { baseApi } from '../../app/services/baseApi';

type CostsThisPeriod = {
  bought: number;
  sold: number;
  supplyCharge: number;
  controlledLoad: number | null;
};

export enum BillsStatus {
  NoBillReceived = 'NO_BILL_RECEIVED',
  NewBillExpected = 'NEW_BILL_EXPECTED',
  NoActionRequired = 'NO_ACTION_REQUIRED',
}

export const billsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRetailerImageURL: build.query<{ base64_file: string; file_type: string }, number>({
      query: (id) => `/v1/retailers/${id}/logo`,
      providesTags: (result, error, id) => [{ type: 'RetailerLogo', id }],
    }),
    getSiteBillsStatus: build.query<{ status: BillsStatus }, number>({
      query: (id) => `/v1/sites/${id}/bills_status`,
      providesTags: (result, error, id) => [{ type: 'BillsStatus', id }],
    }),
    getSiteRetailPlanCheck: build.query<RPCData | null, number>({
      query: (id) => `/v1/sites/${id}/rpc`,
      providesTags: (result, error, id) => [{ type: 'RetailPlanCheck', id }],
    }),
    getSiteBills: build.query<Bill[], number>({
      query: (id) => `/v1/sites/${id}/bills`,
      transformResponse: (bills: Bill[]) => [...bills].reverse(),
      providesTags: (result, error, id) => [{ type: 'RetailPlanCheck', id }],
    }),
    getSiteBillForecast: build.query<BillForecast, number>({
      query: (id) => `/v1/sites/${id}/bill_forecast`,
      providesTags: (result, error, id) => [{ type: 'BillForecast', id }],
    }),
    getSiteBillPeriodCosts: build.query<
      CostsThisPeriod,
      { siteId: number; startDate: string | null; endDate: string | null }
    >({
      query: ({ siteId, startDate, endDate }) => {
        const queryParams: Record<string, string> = {
          groupby: 'total',
        };
        if (startDate) queryParams['start_date'] = startDate;
        if (endDate) queryParams['end_date'] = endDate;

        return `/v1/sites/${siteId}/costs?${getStringifiedQueryParams(queryParams)}`;
      },
      transformResponse: (response: CostsData[]) => {
        const [{ components }] = response;
        const bought = components?.find((c) => c.type === 'import');
        const controlledLoad = components?.find((c) => c.type === 'controlled_load');
        const sold = components?.find((c) => c.type === 'export');
        const supplyCharge = components?.find((c) => c.type === 'supply_charge');

        return {
          bought: bought?.amount ?? 0,
          controlledLoad: controlledLoad?.amount ?? null,
          sold: sold?.amount ?? 0,
          supplyCharge: supplyCharge?.amount ?? 0,
        };
      },
      providesTags: ['BillPeriodCosts'],
    }),
  }),
});

export const {
  useGetRetailerImageURLQuery,
  useGetSiteBillForecastQuery,
  useGetSiteBillsQuery,
  useGetSiteBillPeriodCostsQuery,
  useGetSiteBillsStatusQuery,
  useGetSiteRetailPlanCheckQuery,
} = billsApi;
