import { DeviceType } from 'clipsal-cortex-types/src/api';
import {
  ChargerStatus,
  EvChargerCurrentData,
  EvChargerDetails,
  EvChargerStatusData,
  SiteEvChargersRequest,
} from 'clipsal-cortex-types/src/api/api-evcharger';

import { baseApi } from '../../app/services/baseApi';

export type EVDeviceType = Extract<DeviceType, 'EV_CAR' | 'EV_CHARGER'>;

export const evDevicesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    activate: build.mutation<EvChargerDetails, { siteId: number; body: SiteEvChargersRequest }>({
      query: ({ siteId, body }) => ({
        url: `/v1/sites/${siteId}/evs`,
        method: 'POST',
        body,
      }),
    }),
    getStatus: build.query<EvChargerStatusData, { siteDeviceId: number }>({
      query: ({ siteDeviceId }) => `/v1/evs/${siteDeviceId}/status`,
      providesTags: (_, __, { siteDeviceId }) => [{ type: 'EVChargers', id: siteDeviceId }],
    }),
    startCharge: build.mutation<void, { siteDeviceId: number }>({
      query: ({ siteDeviceId }) => ({
        url: `/v1/evs/${siteDeviceId}/start_charge`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { siteDeviceId }) => [{ type: 'EVChargers', id: siteDeviceId }],
    }),
    stopCharge: build.mutation<void, { siteDeviceId: number }>({
      query: ({ siteDeviceId }) => ({
        url: `/v1/evs/${siteDeviceId}/stop_charge`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { siteDeviceId }) => [{ type: 'EVChargers', id: siteDeviceId }],
    }),
    pauseCharge: build.mutation<void, { siteDeviceId: number }>({
      query: ({ siteDeviceId }) => ({
        url: `/v1/evs/${siteDeviceId}/pause_charge`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { siteDeviceId }) => [{ type: 'EVChargers', id: siteDeviceId }],
    }),
    resumeCharge: build.mutation<void, { siteDeviceId: number }>({
      query: ({ siteDeviceId }) => ({
        url: `/v1/evs/${siteDeviceId}/resume_charge`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { siteDeviceId }) => [{ type: 'EVChargers', id: siteDeviceId }],
    }),
    getChargeCurrent: build.query<EvChargerCurrentData, { siteDeviceId: number }>({
      query: ({ siteDeviceId }) => `/v1/evs/${siteDeviceId}/charge_current`,
      providesTags: (_, __, { siteDeviceId }) => [{ type: 'EVChargerCurrent', id: siteDeviceId }],
    }),
    setChargeCurrent: build.mutation<void, { siteDeviceId: number; current: number }>({
      query: ({ siteDeviceId, current }) => ({
        url: `/v1/evs/${siteDeviceId}/set_charge_current`,
        method: 'PATCH',
        body: { max_amps: current },
      }),
      invalidatesTags: (_, __, { siteDeviceId }) => [{ type: 'EVChargerCurrent', id: siteDeviceId }],
    }),
  }),
});

export const {
  useActivateMutation,
  useGetStatusQuery,
  useStartChargeMutation,
  useStopChargeMutation,
  usePauseChargeMutation,
  useResumeChargeMutation,
  useGetChargeCurrentQuery,
  useSetChargeCurrentMutation,
} = evDevicesApi;

export const INOPERATIVE_STATUSES = ['Unavailable_Disconnect', 'Unavailable_Restart', 'Unavailable_Charger', 'Faulted'];
const OCCUPIED_STATES = ['Preparing', 'Charging', 'SuspendedEV', 'SuspendedEVSE', 'Finishing'];
const IDLE_STATUSES = ['Available'];

export const getPollingTimeout = (status: ChargerStatus): number => {
  if (INOPERATIVE_STATUSES.includes(status)) {
    return 60_000; // 60 seconds
  } else if (OCCUPIED_STATES.includes(status)) {
    return 3_000; // 3 seconds
  } else if (IDLE_STATUSES.includes(status)) {
    return 5_000; // 5 seconds
  }
  return 5_000; // 5 seconds
};
