import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { SolarSpongeDeviceWrite } from 'clipsal-cortex-types/src/api';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';

import {
  useCreateSiteSolarSpongeConfigMutation,
  useGetSiteSolarSpongeConfigQuery,
  useUpdateSiteSolarSpongeConfigMutation,
} from '../../site/control-profile/siteSolarSpongeConfigApi';
import { selectSite } from '../../site/siteSlice';
import { SmartSaveStatus } from './smart-save-helpers';
import { selectSmartSaveDevices, selectSmartSaveStatus } from './smartSaveSlice';

export const useSaveSmartSaveConfig = () => {
  const smartSaveDevices = useSelector(selectSmartSaveDevices);
  const smartSaveStatus = useSelector(selectSmartSaveStatus);
  const { site_id: siteId } = useSelector(selectSite);
  const { data: siteSmartSaveConfig } = useGetSiteSolarSpongeConfigQuery(siteId);
  const [createSiteSmartSaveConfig, { isLoading: isCreateSiteSmartSaveConfigLoading }] =
    useCreateSiteSolarSpongeConfigMutation();
  const [updateSiteSmartSaveConfig, { isLoading: isUpdatingSiteSmartSaveConfig }] =
    useUpdateSiteSolarSpongeConfigMutation();
  const isUpdating = isCreateSiteSmartSaveConfigLoading || isUpdatingSiteSmartSaveConfig;
  const toast = useToast({
    isClosable: true,
  });

  const saveSmartSaveConfig = useCallback(
    async (showSuccessToast = true) => {
      let formattedTrialEndDate: string | null = null;
      if (smartSaveStatus === SmartSaveStatus.TRIAL) {
        const trialEndDate = new Date();
        trialEndDate.setDate(trialEndDate.getDate() - 1);
        trialEndDate.setMonth(trialEndDate.getMonth() + 1);
        formattedTrialEndDate = formatDate(trialEndDate);
      }

      try {
        if (siteSmartSaveConfig) {
          await updateSiteSmartSaveConfig({
            site_id: siteId,
            export_window_start: siteSmartSaveConfig.export_window_start,
            export_window_end: siteSmartSaveConfig.export_window_end,
            is_tariff_responsive: siteSmartSaveConfig.is_tariff_responsive,
            solar_sponge_devices: smartSaveDevices as SolarSpongeDeviceWrite[],
            trial_end_date: formattedTrialEndDate,
          }).unwrap();
        } else {
          await createSiteSmartSaveConfig({
            site_id: siteId,
            export_window_start: '10:00',
            export_window_end: '17:00',
            is_tariff_responsive: false,
            solar_sponge_devices: smartSaveDevices,
            trial_end_date: formattedTrialEndDate,
          }).unwrap();
        }

        if (showSuccessToast) {
          toast.closeAll();
          toast({
            title: 'Optimisations saved successfully!',
            status: 'success',
          });
        }
      } catch {
        toast.closeAll();
        toast({
          title: 'Failed to save optimisations!',
          description: 'Please try again, or contact support if the issue persists.',
          status: 'error',
        });
      }
    },
    [
      createSiteSmartSaveConfig,
      siteId,
      siteSmartSaveConfig,
      smartSaveDevices,
      smartSaveStatus,
      toast,
      updateSiteSmartSaveConfig,
    ]
  );

  return {
    isUpdating,
    saveSmartSaveConfig,
  };
};
