import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetSiteSolarSpongeConfigQuery } from '../../site/control-profile/siteSolarSpongeConfigApi';
import { selectSite } from '../../site/siteSlice';
import { SmartSaveStatus } from './smart-save-helpers';

export const useSmartSaveStatus = () => {
  const { site_id: siteId } = useSelector(selectSite);
  const { data: siteSmartSaveConfig, isLoading, isFetching } = useGetSiteSolarSpongeConfigQuery(siteId);

  const smartSaveStatus = useMemo(() => {
    if (siteSmartSaveConfig?.trial_end_date) return SmartSaveStatus.TRIAL;
    if (siteSmartSaveConfig?.solar_sponge_devices) {
      const hasActiveDevices = siteSmartSaveConfig.solar_sponge_devices.some((device) => device.active);
      return hasActiveDevices ? SmartSaveStatus.ON : SmartSaveStatus.OFF;
    }

    return SmartSaveStatus.OFF;
  }, [siteSmartSaveConfig]);

  return { smartSaveStatus, isLoading, isFetching };
};
