import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Card from 'clipsal-cortex-ui/src/components/card/Card';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { selectSite } from '../../site/siteSlice';
import { BillsStatus, useGetSiteBillsStatusQuery, useGetSiteRetailPlanCheckQuery } from '../billsApi';
import { useMostRecentTariff } from '../tariffs/tariffApi';
import { EnergyPlanUploadBillSection } from './EnergyPlanBottomSection';
import { EnergyPlanDetails } from './EnergyPlanDetails';
import NewBillExpected from './NewBillExpected';
import RPC from './RPC';

export interface CommonEnergyPlanProps {
  isParentLoaded: boolean;
  isInPlanComparison?: boolean;
  onOpenUploadBillDialog: () => void;
}

export default function EnergyPlan({
  isParentLoaded,
  onOpenUploadBillDialog,
  isInPlanComparison = false,
}: CommonEnergyPlanProps) {
  const site = useSelector(selectSite);
  const { data: rpcData, isLoading: isRPCLoading, isError: isRPCError } = useGetSiteRetailPlanCheckQuery(site.site_id);
  const {
    data: billsStatus,
    isLoading: isBillStatusLoading,
    isError: isBillStatusError,
  } = useGetSiteBillsStatusQuery(site.site_id);
  const { isLoading: isTariffsDataLoading } = useMostRecentTariff();
  const isLoaded = !isRPCLoading && !isBillStatusLoading && !isTariffsDataLoading && isParentLoaded;
  const isError = isRPCError || isBillStatusError;

  const energyPlanDetails =
    !isTariffsDataLoading && !isError ? (
      <EnergyPlanDetails
        onOpenUploadBillDialog={onOpenUploadBillDialog}
        isInPlanComparison={isInPlanComparison}
        isParentLoaded={isParentLoaded}
      />
    ) : null;

  return (
    <>
      <Card>
        <Flex justify={'space-between'} align={'center'}>
          <Heading size={'md'} data-testid={'my-energy-plan'}>
            My Energy Plan
          </Heading>
        </Flex>

        {isLoaded ? energyPlanDetails : <CenteredLoader />}

        <RPC isParentLoaded={isParentLoaded && isLoaded} isInPlanComparison={isInPlanComparison} />

        {/* Display the upload bills section if the user does not have an RPC. */}
        {!isRPCLoading && !isRPCError && !rpcData && (
          <EnergyPlanUploadBillSection
            onOpenUploadBillDialog={onOpenUploadBillDialog}
            isInPlanComparison={isInPlanComparison}
            isParentLoaded={isParentLoaded && isLoaded}
          />
        )}
      </Card>

      {billsStatus?.status === BillsStatus.NewBillExpected && (
        <NewBillExpected onOpenUploadBillDialog={onOpenUploadBillDialog} />
      )}
    </>
  );
}
