import React from 'react';
import { createIcon } from '@chakra-ui/react';

import evChargerImg from './images/ev-charger.png';

// eslint-disable max-len
export const SchneiderChargeIcon = createIcon({
  displayName: 'SchneiderChargeIcon',
  path: (
    <svg
      width="24"
      height="33"
      viewBox="0 0 24 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="33" fill="url(#pattern0_2358_355)" />
      <defs>
        <pattern id="pattern0_2358_355" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2358_355" transform="matrix(0.000361842 0 0 0.000263158 0.0138651 0)" />
        </pattern>
        <image id="image0_2358_355" width="2687" height="3800" href={evChargerImg} />
      </defs>
    </svg>
  ),
  viewBox: '0 0 24 33',
});
export const TeslaIcon = createIcon({
  displayName: 'TeslaIcon',
  path: (
    <svg fill="#E31937" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5.362l2.475-3.026s4.245.09 8.471 2.054c-1.082 1.636-3.231 2.438-3.231 2.438-.146-1.439-1.154-1.79-4.354-1.79L12 24 8.619 5.034c-3.18 0-4.188.354-4.335 1.792 0 0-2.146-.795-3.229-2.43C5.28 2.431 9.525 2.34 9.525 2.34L12 5.362l-.004.002H12v-.002zm0-3.899c3.415-.03 7.326.528 11.328 2.28.535-.968.672-1.395.672-1.395C19.625.612 15.528.015 12 0 8.472.015 4.375.61 0 2.349c0 0 .195.525.672 1.396C4.674 1.989 8.585 1.435 12 1.46v.003z" />
    </svg>
  ),
  viewBox: '0 0 24 24',
});
