import { useEffect, useState } from 'react';

export type ViewportType = 'desktop-lg' | 'desktop' | 'tablet' | 'mobile';

export const DESKTOP_LARGE_WIDTH_BREAKPOINT = 1920;
export const DESKTOP_WIDTH_BREAKPOINT = 1024;
export const TABLET_WIDTH_BREAKPOINT = 768;
export const MOBILE_WIDTH_BREAKPOINT = 480;

/**
 * Reacts to changes in window sizes.
 * Adds listener on mount and removes it on unmount.
 *
 * @returns Object with width and height properties.
 */
export function useGetWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

/**
 * Retrieves the viewport type, according to provided width.
 *
 * @param width Numeric viewport width.
 */
export function getViewportType(width: number): ViewportType {
  if (width >= DESKTOP_LARGE_WIDTH_BREAKPOINT) {
    return 'desktop-lg';
  } else if (width >= DESKTOP_WIDTH_BREAKPOINT) {
    return 'desktop';
  } else if (width < DESKTOP_WIDTH_BREAKPOINT && width >= TABLET_WIDTH_BREAKPOINT) {
    return 'tablet';
  } else {
    return 'mobile';
  }
}
