import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatInTimeZone } from 'date-fns-tz';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import InfoPopover from 'clipsal-cortex-ui/src/components/InfoPopover';

import CustomDatePicker from '../../../../common/components/date-picker/CustomDatePicker';
import PageBase from '../../../../common/components/PageBase';
import RouteChangeBlocker from '../../../../common/components/RouteChangeBlocker';
import TopNav from '../../../../common/components/TopNav';
import { AMBER_RETAILER_ID, REAL_TIME_RETAILER_IDS } from '../../../../common/constants';
import { selectSite } from '../../../site/siteSlice';
import { COMMON_BASE_PAGE_X_PADDING, EMPTY_RETAILER_OPTION } from '../constants';
import FormContainer from '../FormContainer';
import { useTariffFormContext } from '../tariff-form-context';
import { customIsEqual } from '../utils';
import RetailerSelect from './RetailerSelect';
import TariffTypeSelect from './TariffTypeSelect';
import { BasicDetailsFormData } from './types';
import { useInitialFormValues, useTariffEntryAmplitudeEvent } from './utils';
import { basicDetailsSchema } from './validation-schema';

export default function BasicDetailsForm() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const { tariffId } = useParams<{ tariffId: string }>();
  const toast = useToast({ isClosable: true, duration: 5_000 });
  const {
    onUpdateFormData,
    formData: { basicDetails: initialBasicDetailsData },
  } = useTariffFormContext();
  const defaultValues = useInitialFormValues();
  const form = useForm<BasicDetailsFormData>({
    resolver: yupResolver(basicDetailsSchema),
    defaultValues,
    reValidateMode: 'onChange',
  });
  const isRedirectingToRealTimeIntegrationRef = useRef(false);
  const {
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    register,
    control,
    watch,
  } = form;
  const selectedRetailer = watch('retailer');

  const [search] = useSearchParams();
  const backURL = search.get('backURL');

  const realTimeRetailerIdToIntegrationStatus: Record<number, boolean> = {
    [AMBER_RETAILER_ID]: site.integrations.amber,
  };
  const hasIntegrationForRealTimeRetailer =
    selectedRetailer && realTimeRetailerIdToIntegrationStatus[selectedRetailer.value];
  const isRealTimeRetailer = selectedRetailer && REAL_TIME_RETAILER_IDS.includes(selectedRetailer.value);

  const latestTariffStartDate = useMemo(() => {
    const todayInSiteTimezone = new Date(formatInTimeZone(new Date(), site.timezone, 'yyyy-MM-dd'));
    todayInSiteTimezone.setMonth(todayInSiteTimezone.getMonth() + 6);
    return todayInSiteTimezone;
  }, [site.timezone]);

  const earliestTariffStartDate = useMemo(() => {
    return new Date(site?.monitoring_start?.replaceAll('-', '/') ?? '');
  }, [site.monitoring_start]);

  useEffect(() => {
    // Removes persisted form data. See `RealTimeRateAlert`
    localStorage.removeItem(`siteNewTariffCachedFormData__${site.site_id}`);
  }, [site]);

  useTariffEntryAmplitudeEvent();

  async function handleSubmitBasicDetails(basicDetails: BasicDetailsFormData) {
    const basicDetailsToSave = { ...basicDetails };

    let newURL = `/site/${
      site.site_id
    }/bills/tariffs/${tariffId}/${basicDetails.tariffType!.toLowerCase()}-rate-configuration`;

    if (isRealTimeRetailer) {
      newURL = `/site/${site.site_id}/bills/tariffs/${tariffId}/real-time-rate-configuration`;
      basicDetailsToSave.tariffType = 'REAL_TIME';
    }

    if (!basicDetailsToSave.retailer) {
      basicDetailsToSave.retailer = EMPTY_RETAILER_OPTION;
    }

    onUpdateFormData((p) => ({ ...p, basicDetails: basicDetailsToSave }));

    const currentURL = `/site/${site.site_id}/bills/tariffs/${tariffId}/basic-details${
      backURL ? '?backURL=' + backURL : ''
    }`;
    navigate(newURL + (backURL ? '?backURL=' + currentURL : ''));
  }

  function handleSubmissionError() {
    toast({
      title: 'Invalid details',
      description: 'Check that all required fields are filled out',
      status: 'error',
    });
  }

  function checkForUnsavedChanges() {
    if (isSubmitting || isRedirectingToRealTimeIntegrationRef.current) return false;

    const userEnteredValue = getValues();
    if (tariffId === 'new') {
      // New tariff with no existing data saved in the form, no need to inform the user of unsaved changes
      if (!initialBasicDetailsData) {
        return (
          userEnteredValue.tariffType !== 'FLAT' ||
          !!userEnteredValue.retailer ||
          !!userEnteredValue.startDate ||
          !!userEnteredValue.dailySupplyCharge
        );
      } else {
        // The tariff is new _AND_ has saved data in context (but not saved to the server yet), meaning there are
        // at least _some_ unsaved changes.
        return true;
      }
    }

    return !customIsEqual(defaultValues, userEnteredValue);
  }

  return (
    <RouteChangeBlocker onChangeRoute={checkForUnsavedChanges}>
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <TopNav backURL={backURL ?? `/site/${site.site_id}/bills`} title="Basic Details" />

        <Box px={5}>
          <Alert status="info" variant="left-accent">
            <AlertIcon />
            This will take about a minute. Please make sure you have your bill with you for reference.
          </Alert>
        </Box>

        <FormContainer mt={3}>
          <FormProvider {...form}>
            <Box
              data-testid="tariff-basic-details-form"
              as={'form'}
              onSubmit={handleSubmit(handleSubmitBasicDetails, handleSubmissionError)}
            >
              <FormControl isInvalid={!!errors?.retailer}>
                <FormLabel fontWeight="bold">Select energy retailer</FormLabel>
                <Box data-testid="retailer">
                  <RetailerSelect />
                </Box>
              </FormControl>

              <FormControl isInvalid={!!errors?.startDate} mt={3}>
                <FormLabel fontWeight="bold">Start Date</FormLabel>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, value } }) => (
                    <CustomDatePicker
                      minDate={earliestTariffStartDate}
                      maxDate={latestTariffStartDate}
                      withPortal
                      inputProps={{
                        'data-testid': `start-date`,
                      }}
                      dateFormat="dd/MM/yyyy"
                      onChange={onChange}
                      selected={value}
                    />
                  )}
                />
                <FormErrorMessage data-testid="start-date-error-message">{errors?.startDate?.message}</FormErrorMessage>
              </FormControl>

              {isRealTimeRetailer ? (
                <RealTimeRateAlert
                  isRedirectingToRealTimeIntegrationRef={isRedirectingToRealTimeIntegrationRef}
                  selectedRetailer={selectedRetailer!}
                />
              ) : (
                <>
                  <FormControl isInvalid={!!errors?.dailySupplyCharge} mt={3}>
                    <FormLabel fontWeight="bold">
                      Daily supply charge
                      <InfoPopover>
                        Your daily supply charge is a flat daily rate which you pay for electricity, on top of your
                        other rates.
                      </InfoPopover>
                    </FormLabel>
                    <InputGroup>
                      <Input
                        data-testid="daily-supply-charge"
                        {...register('dailySupplyCharge')}
                        type={'number'}
                        min={0}
                        step="0.0000000001"
                      />
                      <InputRightAddon>$ / day</InputRightAddon>
                    </InputGroup>
                    <FormErrorMessage data-testid="daily-supply-charge-error-message">
                      {errors?.dailySupplyCharge?.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* Users cannot edit the tariff type for previously saved tariffs */}
                  <Box mt={3}>{tariffId === 'new' && <TariffTypeSelect />}</Box>
                </>
              )}

              <Center mt={6}>
                <Button
                  data-testid="submit-basic-details-form"
                  type="submit"
                  isDisabled={isRealTimeRetailer && !hasIntegrationForRealTimeRetailer}
                  w={'75%'}
                  rounded={20}
                  colorScheme="dusk100"
                >
                  Next
                </Button>
              </Center>
            </Box>
          </FormProvider>
        </FormContainer>
      </PageBase>
    </RouteChangeBlocker>
  );
}

type RealTimeRateAlertProps = {
  selectedRetailer: { label: string; value: number };
  isRedirectingToRealTimeIntegrationRef: MutableRefObject<boolean>;
};

function RealTimeRateAlert({ selectedRetailer, isRedirectingToRealTimeIntegrationRef }: RealTimeRateAlertProps) {
  const site = useSelector(selectSite);
  const realTimeRetailerIdToIntegrationStatus: Record<number, boolean> = {
    [AMBER_RETAILER_ID]: site.integrations.amber,
  };
  const { getValues } = useFormContext<BasicDetailsFormData>();
  const hasIntegrationForRealTimeRetailer =
    selectedRetailer && realTimeRetailerIdToIntegrationStatus[selectedRetailer.value];
  const retailerIdToIntegrationLink: Record<number, string> = {
    [AMBER_RETAILER_ID]: `/site/${site.site_id}/bills/amber_integration?backURL=/site/${site.site_id}/bills/tariffs/new`,
  };

  return (
    <Alert
      mt={5}
      data-testid={
        hasIntegrationForRealTimeRetailer
          ? 'real-time-retailer-info-alert'
          : 'missing-real-time-retailer-integration-alert'
      }
      status={hasIntegrationForRealTimeRetailer ? 'info' : 'error'}
      variant="left-accent"
    >
      <AlertIcon />
      <Box>
        {hasIntegrationForRealTimeRetailer ? (
          <Text>
            {selectedRetailer.label} only offers real-time rates. Continue to the next step to configure your other
            rates.
          </Text>
        ) : (
          <>
            <Text>You need to set up your {selectedRetailer.label} integration to get real-time rates.</Text>
            <Link
              data-testid="link-to-retailer-integration"
              color={'blue.500'}
              as={RouterLink}
              onClick={() => {
                isRedirectingToRealTimeIntegrationRef.current = true;
                localStorage.setItem(`siteNewTariffCachedFormData__${site.site_id}`, JSON.stringify(getValues()));
              }}
              to={retailerIdToIntegrationLink[selectedRetailer.value]}
              mt={2}
            >
              Set Up {selectedRetailer.label} Integration
            </Link>
          </>
        )}
      </Box>
    </Alert>
  );
}
