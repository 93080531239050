import React, { useEffect, useMemo, useState } from 'react';
import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';

import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { useReduxDispatch } from '../../../app/store';
import { selectSite, updateSiteData } from '../../site/siteSlice';
import {
  useDeleteAmberConnectionMutation,
  useGetAmberApiSitesQuery,
  useGetAmberConnectionQuery,
  usePatchAmberConnectionMutation,
  usePostAmberConnectionMutation,
  usePostAmberSiteMutation,
} from './amberApi';

const AmberConnectionForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { site_id: siteId, nmi, integrations } = useSelector(selectSite);
  const { data: amberConnection, isLoading: isLoadingAmberConnection, isError } = useGetAmberConnectionQuery();
  const hasExistingToken = !isError && !!amberConnection?.user_id;
  const { isLoading: isLoadingAmberApiSites, refetch: fetchAmberSites, data } = useGetAmberApiSitesQuery();
  const amberSites = useMemo(() => data || [], [data]);
  const [saveAmberConnection, { isLoading: isSavingAmberConnection }] = usePostAmberConnectionMutation();
  const [updateAmberConnection, { isLoading: isUpdatingAmberConnection }] = usePatchAmberConnectionMutation();
  const [deleteAmberConnection, { isLoading: isDeletingAmberConnection }] = useDeleteAmberConnectionMutation();
  const [saveAmberSite, { isLoading: isSavingAmberSite }] = usePostAmberSiteMutation();
  const isUpdating = isSavingAmberConnection || isUpdatingAmberConnection || isSavingAmberSite;
  const [{ apiKey, amberSiteId, accordionIndex }, setState] = useState({
    apiKey: '',
    amberSiteId: '',
    accordionIndex: 0,
  });

  useEffect(() => {
    // set the accordion index to 1 if the user has an existing token
    // since we don't need to add the token again
    if (!isLoadingAmberConnection) {
      setState((prevState) => ({ ...prevState, accordionIndex: hasExistingToken ? 1 : 0 }));
    }
  }, [hasExistingToken, isLoadingAmberConnection]);

  const toast = useToast({ duration: 5000, isClosable: true });
  const dispatch = useReduxDispatch();
  const selectOptions = useMemo(() => amberSites?.map((site) => ({ label: site.nmi, value: site.id })), [amberSites]);
  const selectedOption = useMemo(() => {
    // If there are select options and no amberSiteId, set the first option as selected
    if (selectOptions.length && !amberSiteId) {
      setState((prevState) => ({ ...prevState, amberSiteId: selectOptions[0].value }));
      return selectOptions[0];
    }
    return selectOptions.find((option) => option.value === amberSiteId);
  }, [amberSiteId, selectOptions]);

  const handleSaveAmberSite = async (amberSiteId: string) => {
    try {
      await saveAmberSite({ siteId, amberSiteId }).unwrap();
      dispatch(updateSiteData({ integrations: { ...integrations, amber: true } }));
      toast({
        title: 'Amber integration saved successfully!',
        status: 'success',
      });
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error Saving Amber integration!',
        status: 'error',
      });
    }
  };

  const handleSaveAPIKey = async () => {
    try {
      const saveAPIKeyFn = hasExistingToken ? updateAmberConnection : saveAmberConnection;
      await saveAPIKeyFn(apiKey).unwrap();
      setState((prevState) => ({ ...prevState, apiKey: '' }));

      // fetch sites and save the first site
      const sites = await fetchAmberSites().unwrap();
      setState((prevState) => ({ ...prevState, accordionIndex: 1 }));

      // Status 204 returns empty string, so need to account for that
      const amberSiteId = (sites || []).find((site) => site.nmi === nmi)?.id;
      if (amberSiteId) await handleSaveAmberSite(amberSiteId);
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error Saving API Key',
        status: 'error',
      });
    }
  };

  const handleDeleteAmberConnection = async () => {
    try {
      await deleteAmberConnection().unwrap();
      setState((prevState) => ({ ...prevState, accordionIndex: 0, apiKey: '' }));
      toast({
        title: 'Amber Integration Deleted!',
        status: 'success',
      });
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error Deleting Amber Integration',
        status: 'error',
      });
    }
  };

  return (
    <Box data-testid="amber-connection-form">
      {isLoadingAmberApiSites || isLoadingAmberConnection ? (
        <CenteredLoader minH={250} />
      ) : (
        <Accordion
          allowToggle
          index={accordionIndex}
          onChange={(accordionIndex: number) => setState((prevState) => ({ ...prevState, accordionIndex }))}
        >
          <AccordionItem data-testid="amber-api-key-accordion">
            <AccordionButton py={4}>
              <Box as="span" flex="1" textAlign="left" fontWeight={600}>
                1. Add Amber API Key
              </Box>
              <CheckCircleIcon color="green.500" w={6} h={6} display={hasExistingToken ? 'block' : 'none'} mr={2} />
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <Text mb={4}>
                To generate an API key please login to
                <Link color="customBlue.500" mx={1} href="https://app.amber.com.au/developers/">
                  https://app.amber.com.au/developers
                </Link>
                with your account details and create a new token. Then, add it in the input below.
              </Text>
              <Box
                as={'form'}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSaveAPIKey();
                }}
              >
                <FormControl>
                  <InputGroup>
                    <Input
                      data-testid="amber-api-key-input"
                      value={apiKey}
                      placeholder={hasExistingToken ? 'psk_XXXXXXXXXXXXXXXX' : 'Enter API Key'}
                      onChange={(e) => setState((prevState) => ({ ...prevState, apiKey: e.target.value }))}
                    />
                    {hasExistingToken && (
                      <InputRightElement>
                        <IconButton
                          variant={'ghost'}
                          data-testid="amber-delete-api-key-button"
                          aria-label="delete-api-key"
                          icon={<DeleteIcon color="red.500" />}
                          onClick={onOpen}
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  {hasExistingToken && !apiKey && (
                    <FormHelperText>
                      Note: You already have an existing Amber API Key. Please edit this only if you want to update it.
                    </FormHelperText>
                  )}
                </FormControl>
                <Center>
                  <Button
                    data-testid="amber-save-api-key-button"
                    type="submit"
                    w={'75%'}
                    rounded={20}
                    mt={6}
                    colorScheme="dusk100"
                    isDisabled={!apiKey}
                    isLoading={isUpdating}
                  >
                    Save API Key
                  </Button>
                </Center>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem isDisabled={!amberSites?.length} data-testid="amber-sites-selection-accordion">
            <AccordionButton py={4}>
              <Box as="span" flex="1" textAlign="left" fontWeight={600}>
                2. Select NMI of your site
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <Text mb={4}>Please select NMI for your site from the list.</Text>
              <Select
                onChange={(e) => {
                  setState((prevState) => ({ ...prevState, amberSiteId: e?.value ?? '' }));
                }}
                isLoading={isLoadingAmberApiSites}
                isDisabled={!hasExistingToken}
                placeholder={'Select matching site NMI...'}
                // Not specifying portal target can cause the menu to become hidden.
                menuPortalTarget={document.body}
                options={selectOptions}
                value={selectedOption}
                classNamePrefix="amber"
                useBasicStyles
              />
              <Center>
                <Button
                  data-testid="amber-save-site-button"
                  w={'75%'}
                  rounded={20}
                  mt={6}
                  colorScheme="dusk100"
                  isDisabled={!amberSiteId}
                  isLoading={isUpdating}
                  onClick={() => handleSaveAmberSite(amberSiteId)}
                >
                  Save
                </Button>
              </Center>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      <AlertDialogModal
        header="Are you sure you want to delete Amber API Key?"
        subHeader=" "
        isOpen={isOpen}
        onClose={onClose}
        confirmButtonTextColor={'customRed.500'}
        isConfirming={isDeletingAmberConnection}
        onConfirm={handleDeleteAmberConnection}
      />
    </Box>
  );
};

export default AmberConnectionForm;
