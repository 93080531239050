import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

import { TermsAndConditionsText } from '../../../common/components/TermsAndConditions';
import ProfilePageBase from '../ProfilePageBase';

const TermsAndConditions = () => {
  const background = useColorModeValue('white', 'gray.900');

  return (
    <ProfilePageBase title={'Terms & Conditions'}>
      <Box p={4} background={background} pt={6}>
        <TermsAndConditionsText />
      </Box>
    </ProfilePageBase>
  );
};

export default TermsAndConditions;
