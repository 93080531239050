import localforage from 'localforage';

/**
 * Temporary fix for login failing in Safari and iOS with a local storage error: 'Maximum quota exceeded'.
 * This implementation replaces the storage of cognito key value pairs in IndexedDB rather than local storage.
 */
export class LocalForageStorage {
  syncPromise: Promise<void> | null = null;
  memoryStore: Record<string, string> = {};
  asyncStore = localforage.createInstance({
    name: 'amplifyStore',
  });

  setItem(key: string, value: string) {
    this.asyncStore.setItem(key, value);
    this.memoryStore[key] = value;
    return this.memoryStore[key];
  }

  getItem(key: string) {
    return Object.prototype.hasOwnProperty.call(this.memoryStore, key) ? this.memoryStore[key] : undefined;
  }

  removeItem(key: string) {
    this.asyncStore.removeItem(key);
    return delete this.memoryStore[key];
  }

  clear() {
    this.asyncStore.clear();
    return {};
  }

  sync(): Promise<void> {
    if (!this.syncPromise) {
      this.syncPromise = this.asyncStore.iterate((val: string, key) => {
        this.memoryStore[key] = val;
      });
    }
    return this.syncPromise;
  }
}
