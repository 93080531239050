import {
  DAY_OF_WEEK,
  SiteTariff,
  TariffRate,
  TariffSeason,
  TOUPeriod,
  TOURateType,
} from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { BasicDetailsFormData } from './basic-details/types';
import { FlatTariffFormData } from './rates/flat/types';
import { REAL_TIME_FORM_DEFAULT_VALUE } from './rates/real-time/constants';
import { RealTimeTariffFormData } from './rates/real-time/types';
import { CHARGE_PERIOD_SELECT_OPTIONS } from './rates/tiered/constants';
import { ChargePeriodSelectOption, TieredTariffFormData } from './rates/tiered/types';
import { EMPTY_SEASON_TEMPLATE } from './rates/time-of-use/constants';
import {
  TOURateType as FormTOURateType,
  TimeOfUsePeriodSelectOption,
  TOURate,
  TOUTariffFormData,
  TOUTime,
} from './rates/time-of-use/types';
import { ControlledLoadFormData } from './review/additional-rates-and-discounts/controlled-loads/types';
import { DemandChargeFormData } from './review/additional-rates-and-discounts/demand-charges/types';
import {
  DISCOUNT_OPTIONS,
  DISCOUNT_TYPE_TO_UI_DISCOUNT_OPTION,
} from './review/additional-rates-and-discounts/discounts/constants';
import { DiscountsFormData } from './review/additional-rates-and-discounts/discounts/types';
import { SolarFeedInFormData } from './review/additional-rates-and-discounts/solar-feed-in/types';
import { EMPTY_TARIFF_FORM_DATA, TariffFormDataState } from './tariff-form-context';

export function mapAPIValuesToForm(apiTariff: Required<SiteTariff>): TariffFormDataState {
  const dailySupplyChargeRate = apiTariff.tariff.rates.find((rate) => rate.charge_type === 'FIXED_PRICE');
  if (!dailySupplyChargeRate) throw new Error('All tariffs must have a supply charge!');

  const basicDetails: BasicDetailsFormData = {
    retailer: { label: apiTariff.tariff.retailer!.name, value: apiTariff.tariff.retailer!.id },
    startDate: new Date(apiTariff.tariff_effective_date.replaceAll('-', '/')),
    dailySupplyCharge: dailySupplyChargeRate.rate_bands[0].rate,
    tariffType: apiTariff.tariff.tariff_type,
    tariffId: apiTariff.tariff.id,
  };

  return {
    basicDetails,
    touRates: apiTariff.tariff.tariff_type === 'TOU' ? mapAPITOUTariffToForm(apiTariff) : null,
    flatRates: apiTariff.tariff.tariff_type === 'FLAT' ? mapAPIFlatTariffToForm(apiTariff) : null,
    tieredRates: apiTariff.tariff.tariff_type === 'TIERED' ? mapAPITieredTariffToForm(apiTariff) : null,
    realTimeRates: apiTariff.tariff.tariff_type === 'REAL_TIME' ? mapAPIRealTimeTariffToForm(apiTariff) : null,
    additionalRatesAndDiscounts: mapAPIAdditionsToForm(apiTariff),
  };
}

function mapAPITOUTariffToForm(apiTariff: Required<SiteTariff>): TOUTariffFormData {
  const { tariff } = apiTariff;
  // The values are only inclusive of GST when no line item modifier for GST exists. If there is a line item modifier,
  // the values will be _pre-GST_.
  const ratesAreInclusiveOfGST = !tariff.line_item_modifiers?.find((mod) => mod.modifier_type === 'TAX');

  // When no seasons come back from the API, create a blank one
  if (!tariff?.seasons?.length) {
    const getRateSearchPredicateByType = (type: TOURateType) => (rate: TariffRate) =>
      rate.time_of_use?.tou_rate_type === type;
    const peakRateForSeason = tariff.rates.find(getRateSearchPredicateByType('PEAK'));
    const offPeakRateForSeason = tariff.rates.find(getRateSearchPredicateByType('OFF_PEAK'));
    const shoulderRatesForSeason = tariff.rates.filter(getRateSearchPredicateByType('PARTIAL_PEAK'));

    return {
      shouldApplySeasons: false,
      ratesAreInclusiveOfGST,
      seasons: [
        {
          ...EMPTY_SEASON_TEMPLATE,
          peakRate: peakRateForSeason ? mapAPIRateToForm(peakRateForSeason) : null,
          shoulderRates: shoulderRatesForSeason.map((apiShoulderRate) => mapAPIRateToForm(apiShoulderRate)),
          offPeakRate: offPeakRateForSeason ? mapAPIRateToForm(offPeakRateForSeason) : null,
        },
      ],
    };
  } else {
    const shouldApplySeasons = tariff.seasons.length > 1;

    const seasons = tariff.seasons.map((season) => {
      const getRateSearchPredicateByType = (type: TOURateType) => (rate: TariffRate) =>
        rate.season?.season_index === season.season_index && rate.time_of_use?.tou_rate_type === type;
      const peakRateForSeason = tariff.rates.find(getRateSearchPredicateByType('PEAK'));
      const offPeakRateForSeason = tariff.rates.find(getRateSearchPredicateByType('OFF_PEAK'));
      const shoulderRatesForSeason = tariff.rates.filter(getRateSearchPredicateByType('PARTIAL_PEAK'));
      const { toDate, toMonth } = adjustSeasonEndDates(season);

      return {
        seasonIndex: season.season_index,
        name: season.name,
        fromDate: season.from_date,
        fromMonth: season.from_month - 1, // JS months are 0-11 (indexes)
        toDate,
        toMonth,
        peakRate: peakRateForSeason ? mapAPIRateToForm(peakRateForSeason) : null,
        shoulderRates: shoulderRatesForSeason.map((apiShoulderRate) => mapAPIRateToForm(apiShoulderRate)),
        offPeakRate: offPeakRateForSeason ? mapAPIRateToForm(offPeakRateForSeason) : null,
      };
    });

    return {
      ratesAreInclusiveOfGST,
      shouldApplySeasons,
      seasons,
    };
  }
}

function mapAPIRealTimeTariffToForm(apiTariff: Required<SiteTariff>): RealTimeTariffFormData {
  const hasControlledLoad = !!apiTariff.tariff.rates.find(
    (rate) => rate.charge_type === 'REAL_TIME' && rate.charge_class === 'DEDICATED_CIRCUIT_1'
  );
  const hasSolarFeedIn = !!apiTariff.tariff.rates.find(
    (rate) => rate.charge_type === 'REAL_TIME' && rate.transaction_type == 'SELL_EXPORT'
  );
  const supplyCharge = apiTariff.tariff.rates.find(
    (rate) => rate.charge_type === 'FIXED_PRICE' && rate.charge_class == 'DISTRIBUTION'
  );
  const monthlyFee = apiTariff.tariff.rates.find(
    (rate) => rate.charge_type === 'FIXED_PRICE' && rate.charge_class == 'FEES'
  );

  return {
    ...REAL_TIME_FORM_DEFAULT_VALUE,
    shouldApplySeasons: false,
    ratesAreInclusiveOfGST: false,
    seasons: [
      {
        ...REAL_TIME_FORM_DEFAULT_VALUE.seasons[0],
        hasControlledLoad,
        hasSolarFeedIn,
        dailySupplyCharge: supplyCharge?.rate_bands?.[0]?.rate ?? 0,
        monthlyFee: monthlyFee?.rate_bands?.[0]?.rate ?? 0,
      },
    ],
  };
}

const API_RATE_TYPE_TO_FORM_RATE_TYPE: Record<Exclude<TOURateType, 'SINGLE'>, FormTOURateType> = {
  PEAK: 'PEAK',
  OFF_PEAK: 'OFF-PEAK',
  PARTIAL_PEAK: 'SHOULDER',
};

function mapAPIRateToForm(apiRate: TariffRate): TOURate {
  const apiRateType = apiRate.time_of_use?.tou_rate_type as Exclude<TOURateType, 'SINGLE'>;
  const firstApiTier = apiRate.rate_bands[0];

  return {
    rateId: apiRate.id,
    // It's difficult to discern this from API values, so we set to false everywhere. This field's main purpose is
    // for when users are adding a new tariff, which (when true) will auto-generate the times of use for them when
    // they finally save the tariff on the server-side.
    appliesAtAllOtherTimes: false,
    type: API_RATE_TYPE_TO_FORM_RATE_TYPE[apiRateType],
    tiers: [
      {
        tierId: firstApiTier.id,
        rate: firstApiTier.rate,
        upperLimitKWh: null,
      },
    ],
    timesOfUse: apiRate.time_of_use!.periods.map((period) => ({
      fromTime: mapAPIPeriodToTOUTime(period, 'from'),
      toTime: mapAPIPeriodToTOUTime(period, 'to'),
      applicablePeriods: mapPeriodDaysToSelectOptions(period),
    })),
  };
}

function mapAPIPeriodToTOUTime(period: TOUPeriod, type: 'from' | 'to'): TOUTime {
  let hourValue = period[`${type}_hour`];
  // The API can sometimes treat midnight as 24 hours, so convert this to 0
  if (hourValue === 24) hourValue = 0;
  const amOrPm = hourValue < 12 ? 'AM' : 'PM';
  if (hourValue >= 12) hourValue -= 12;

  return {
    hours: hourValue,
    minutes: period[`${type}_minute`],
    amOrPm,
  };
}

function mapPeriodDaysToSelectOptions(period: TOUPeriod): TimeOfUsePeriodSelectOption[] {
  const periods: TimeOfUsePeriodSelectOption[] = [];
  // If there are 5 days and all weekdays are included in the day array, assume this is weekedays
  if (period.days.length === 5 && period.days.every((d) => ['MON', 'TUE', 'WED', 'THU', 'FRI'].includes(d))) {
    periods.push({
      label: 'Weekdays',
      value: 'WEEKDAYS',
    });
  }

  if (period.days.length === 7 && period.days.every((d) => DAY_OF_WEEK.includes(d))) {
    periods.push({
      label: 'Everyday',
      value: 'EVERYDAY',
    });
  }

  if (period.days.length === 2 && period.days.every((d) => ['SAT', 'SUN'].includes(d))) {
    periods.push({
      label: 'Weekends',
      value: 'WEEKENDS',
    });
  }

  if (period.public_holiday) {
    periods.push({
      label: 'Public Holidays',
      value: 'PUBLIC_HOLIDAYS',
    });
  }

  return periods;
}

function mapAPITieredTariffToForm(apiTariff: Required<SiteTariff>): TieredTariffFormData | null {
  const { tariff } = apiTariff;
  // The values are only inclusive of GST when no line item modifier for GST exists. If there is a line item modifier,
  // the values will be _pre-GST_.
  const ratesAreInclusiveOfGST = !tariff.line_item_modifiers?.find((mod) => mod.modifier_type === 'TAX');

  // When no seasons come back from the API, create a blank one
  if (!tariff?.seasons?.length) {
    const tieredRate = tariff.rates.find(
      (rate) =>
        rate.charge_class === 'SUPPLY' &&
        rate.charge_type === 'CONSUMPTION_BASED' &&
        rate.transaction_type === 'BUY_IMPORT'
    );

    if (!tieredRate) {
      throw new Error(`Tiered tariff with ID ${tariff.id} was missing tiered rate in the rates array!`);
    }

    const chargePeriod = getChargePeriodForRate(tieredRate);

    return {
      shouldApplySeasons: false,
      ratesAreInclusiveOfGST,
      seasons: [
        {
          ...EMPTY_SEASON_TEMPLATE,
          chargePeriod,
          tiers: tieredRate.rate_bands.map((rateBand) => {
            return {
              rate: rateBand.rate,
              tierId: rateBand.id,
              upperLimitKWh: rateBand.consumption_upper_limit ?? null,
            };
          }),
        },
      ],
    };
  } else {
    const shouldApplySeasons = tariff.seasons.length > 1;

    const seasons = tariff.seasons.map((season) => {
      const tieredRateForSeason = tariff.rates.find(
        (rate) =>
          rate.charge_class === 'SUPPLY' &&
          rate.charge_type === 'CONSUMPTION_BASED' &&
          rate.transaction_type === 'BUY_IMPORT' &&
          rate.season?.season_index === season.season_index
      );
      if (!tieredRateForSeason) {
        throw new Error(
          `Tiered tariff with ID ${tariff.id} was missing a flat rate in the rates array ` +
            `for season index ${season.season_index}`
        );
      }
      const { toDate, toMonth } = adjustSeasonEndDates(season);
      const chargePeriod = getChargePeriodForRate(tieredRateForSeason);

      return {
        seasonIndex: season.season_index,
        name: season.name,
        fromDate: season.from_date,
        fromMonth: season.from_month - 1, // JS months are 0-11 (indexes)
        toDate,
        toMonth,
        tiers: tieredRateForSeason.rate_bands.map((rateBand) => ({
          rate: rateBand.rate,
          tierId: rateBand.id,
          upperLimitKWh: rateBand.consumption_upper_limit ?? null,
        })),
        chargePeriod,
      };
    });

    return {
      shouldApplySeasons,
      ratesAreInclusiveOfGST,
      seasons,
    };
  }
}

function mapAPIFlatTariffToForm(apiTariff: Required<SiteTariff>): FlatTariffFormData {
  const { tariff } = apiTariff;
  // The values are only inclusive of GST when no line item modifier for GST exists. If there is a line item modifier,
  // the values will be _pre-GST_.
  const ratesAreInclusiveOfGST = !tariff.line_item_modifiers?.find((mod) => mod.modifier_type === 'TAX');

  // When no seasons come back from the API, create a blank one
  if (!tariff?.seasons?.length) {
    const flatRate = tariff.rates.find(
      (rate) =>
        rate.charge_class === 'SUPPLY' &&
        rate.charge_type === 'CONSUMPTION_BASED' &&
        rate.transaction_type === 'BUY_IMPORT'
    );

    if (!flatRate) {
      throw new Error(`Flat tariff with ID ${tariff.id} was missing a flat rate in the rates array!`);
    }

    return {
      shouldApplySeasons: false,
      ratesAreInclusiveOfGST,
      seasons: [
        {
          ...EMPTY_SEASON_TEMPLATE,
          rate: flatRate.rate_bands[0].rate,
        },
      ],
    };
  } else {
    const shouldApplySeasons = tariff.seasons.length > 1;

    const seasons = tariff.seasons.map((season) => {
      const flatRateForSeason = tariff.rates.find(
        (rate) =>
          rate.charge_class === 'SUPPLY' &&
          rate.charge_type === 'CONSUMPTION_BASED' &&
          rate.transaction_type === 'BUY_IMPORT' &&
          rate.season?.season_index === season.season_index
      );
      const { toDate, toMonth } = adjustSeasonEndDates(season);

      if (!flatRateForSeason) {
        throw new Error(
          `Flat tariff with ID ${tariff.id} was missing a flat rate in the rates array ` +
            `for season index ${season.season_index}`
        );
      }

      return {
        seasonIndex: season.season_index,
        name: season.name,
        fromDate: season.from_date,
        fromMonth: season.from_month - 1, // JS months are 0-11 (indexes)
        toDate,
        toMonth, // JS months are 0-11 (indexes)
        rate: flatRateForSeason.rate_bands[0].rate,
      };
    });

    return {
      ratesAreInclusiveOfGST,
      shouldApplySeasons,
      seasons,
    };
  }
}

function mapAPIAdditionsToForm(apiTariff: Required<SiteTariff>): {
  controlledLoads: ControlledLoadFormData | null;
  solarFeedIn: SolarFeedInFormData | null;
  discounts: DiscountsFormData | null;
  demandCharges: DemandChargeFormData | null;
} {
  const { tariff } = apiTariff;

  // Real-time tariffs don't use additional rates and discounts, just return the default value.
  if (tariff.tariff_type === 'REAL_TIME') {
    return EMPTY_TARIFF_FORM_DATA.additionalRatesAndDiscounts;
  }

  const solarFeedInRate = tariff.rates.find((rate) => rate.transaction_type === 'SELL_EXPORT');

  return {
    controlledLoads: mapControlledLoadsToForm(apiTariff),
    solarFeedIn: solarFeedInRate
      ? {
          rate: solarFeedInRate.rate_bands[0].rate,
        }
      : null,
    discounts: mapDiscountsToForm(apiTariff),
    demandCharges: null, // @TODO: to come later
  };
}

function mapDiscountsToForm(apiTariff: Required<SiteTariff>): DiscountsFormData | null {
  const discounts = apiTariff.tariff?.line_item_modifiers?.filter((mod) => mod.modifier_type === 'DISCOUNT');

  return discounts?.length
    ? {
        discounts: discounts.map((discount) => {
          if (!discount.discount_type) console.error('No discount type specified.');
          const discountType = DISCOUNT_TYPE_TO_UI_DISCOUNT_OPTION[discount.discount_type ?? 'USAGE'];
          const discountTypeOption = DISCOUNT_OPTIONS.find((option) => option.value === discountType);

          return {
            id: discount.id,
            type: discountTypeOption ?? DISCOUNT_OPTIONS[0],
            value: discount.amount,
          };
        }),
      }
    : null;
}

function mapControlledLoadsToForm(apiTariff: Required<SiteTariff>): ControlledLoadFormData | null {
  const { tariff } = apiTariff;
  const controlledLoadRate = tariff.rates.find(
    (rate) => rate.charge_class === 'DEDICATED_CIRCUIT_1' && rate.charge_type === 'CONSUMPTION_BASED'
  );
  const controlledLoadSupplyCharge = tariff.rates.find(
    (rate) => rate.charge_class === 'DEDICATED_CIRCUIT_1' && rate.charge_type === 'FIXED_PRICE'
  );
  const controlledLoad2Rate = tariff.rates.find(
    (rate) => rate.charge_class === 'DEDICATED_CIRCUIT_2' && rate.charge_type === 'CONSUMPTION_BASED'
  );
  const controlledLoad2SupplyCharge = tariff.rates.find(
    (rate) => rate.charge_class === 'DEDICATED_CIRCUIT_2' && rate.charge_type === 'FIXED_PRICE'
  );

  // No controlled load at all
  if (!controlledLoadRate && !controlledLoad2Rate) return null;

  return {
    hasControlledLoad2: !!controlledLoad2Rate,
    controlledLoad: {
      rateId: controlledLoadRate?.id ?? null,
      rate: controlledLoadRate?.rate_bands[0].rate ?? null,
      dailySupplyChargeId: controlledLoadSupplyCharge?.id ?? null,
      dailySupplyCharge: controlledLoadSupplyCharge?.rate_bands[0].rate ?? null,
      hasDailySupplyCharge: !!controlledLoadSupplyCharge,
    },
    controlledLoad2: {
      rateId: controlledLoad2Rate?.id ?? null,
      rate: controlledLoad2Rate?.rate_bands[0].rate ?? null,
      dailySupplyChargeId: controlledLoad2SupplyCharge?.id ?? null,
      dailySupplyCharge: controlledLoad2SupplyCharge?.rate_bands[0].rate ?? null,
      hasDailySupplyCharge: !!controlledLoad2SupplyCharge,
    },
  };
}

/**
 * Seasons in the API always have exclusive end dates, whereas they are inclusive in the form (for a better UX).
 * This function subtracts a single day from the end date to ensure it's inclusive.
 *
 * @param season - The season to adjust the end date for
 * @returns An object containing keys for the to date and to month, adjusted for inclusivity.
 */
function adjustSeasonEndDates(season: TariffSeason) {
  const endDateFromAPI = new Date(2021, season.to_month - 1, season.to_date);
  endDateFromAPI.setDate(endDateFromAPI.getDate() - 1);

  return {
    toDate: endDateFromAPI.getDate(),
    toMonth: endDateFromAPI.getMonth(),
  };
}

function getChargePeriodForRate(rate: TariffRate): ChargePeriodSelectOption {
  let chargePeriod = CHARGE_PERIOD_SELECT_OPTIONS.find((chargePeriod) => chargePeriod.value === rate.charge_period);
  if (!chargePeriod) {
    console.warn(`Reverting charge period to default option, as there was none specified for rate ID: ${rate.id}`);
    chargePeriod = CHARGE_PERIOD_SELECT_OPTIONS[0];
  }

  return chargePeriod;
}
