/* eslint-disable max-len */
import { Step } from 'react-joyride';

import { COMMON_TOUR_STEP_PROPS } from '../../../common/constants';

export const BILLS_TOUR_STEPS: Step[] = [
  {
    target: '.tour-bills-step-one',
    // eslint-disable-next-line max-len
    content:
      'When you upload a current bill you’ll be able to see your Retail Plan Check, which compares if there are any better rates available out there for you!\n\nNote: The energy plans compared on this site are not representative of all plans available in the market. We do not compare all brands in the market, or all products offered by all brands.',
    title: 'Check if you are on the best plan!',
    ...COMMON_TOUR_STEP_PROPS,
  },
  {
    target: '.tour-bills-step-two',
    content:
      // eslint-disable-next-line max-len
      'An estimate will be provided for your current billing period along with a forecasted estimate.\n\nNote: The current bill period will start from the monitoring start date. Once you upload your latest bill, we will track your billing period going forward.',
    title: 'Track your current billing period',
    placement: 'top',
    ...COMMON_TOUR_STEP_PROPS,
  },
];

export const CONSUMPTION_ONLY_BILLS_TOUR_STEPS: Step[] = [
  {
    target: '.tour-bills-step-one',
    // eslint-disable-next-line max-len
    content:
      'When you upload a current bill you’ll be able to see your Retail Plan Check, which compares if there are any better rates available out there for you!\n\nNote: The energy plans compared on this site are not representative of all plans available in the market. We do not compare all brands in the market, or all products offered by all brands.',
    title: 'Check if you are on the best plan!',
    ...COMMON_TOUR_STEP_PROPS,
  },
  {
    target: '.tour-bills-consumption-only-step',
    content:
      // eslint-disable-next-line max-len
      'Feel free to also upload the last 12 months of electricity bills. We’ve found users like to compare last year’s bill with your new bills with solar to see the savings comparison over time.',
    title: 'Before and After Solar',
    placement: 'top',
    ...COMMON_TOUR_STEP_PROPS,
  },
  {
    target: '.tour-bills-step-two',
    content:
      // eslint-disable-next-line max-len
      'An estimate will be provided for your current billing period along with a forecasted estimate.\n\nNote: The current bill period will start from the monitoring start date. Once you upload your latest bill, we will track your billing period going forward.',
    title: 'Track your current billing period',
    placement: 'top',
    ...COMMON_TOUR_STEP_PROPS,
  },
];

export const localStorageFlag = 'isBillsTourDismissed';
