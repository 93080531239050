import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Text,
} from '@chakra-ui/react';

type InfoBottomDrawerProps = {
  onClose: () => void;
  isOpen: boolean;
  header: string;
  isClosingDisabled?: boolean;
  headerBgColor?: string;
  drawerBgColor?: string;
  children?: React.ReactNode;
} & DrawerProps;

function InfoBottomBody({ children }: { children: React.ReactNode }) {
  return <Box>{children}</Box>;
}

function InfoBottomConfirmButton({
  text = 'Ok I understand',
  onClick,
  children,
  ...buttonProps
}: React.PropsWithChildren<
  {
    text?: string;
    onClick?: () => void;
    children?: React.ReactNode;
  } & ButtonProps
>) {
  if (!children)
    return (
      <Button colorScheme="primaryBranding" variant="outline" size="lg" onClick={onClick} {...buttonProps}>
        {text}
      </Button>
    );
  return <>{children}</>;
}

export default function InfoBottomDrawer({
  onClose,
  isOpen,
  children,
  header,
  isClosingDisabled,
  headerBgColor,
  drawerBgColor,
  ...drawerProps
}: InfoBottomDrawerProps) {
  return (
    <Drawer
      placement={'bottom'}
      onClose={onClose}
      isOpen={isOpen}
      closeOnEsc={!isClosingDisabled}
      closeOnOverlayClick={!isClosingDisabled}
      {...drawerProps}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px" background={headerBgColor} py={3}>
          <Flex fontSize={'17px'} alignItems="center" position={'relative'}>
            <Button
              variant={'link'}
              color="primaryBrandingStatic.500"
              position={'absolute'}
              top="50%"
              left="0%"
              transform="translate(-50%, -50%)"
              fontWeight={'normal'}
              ml="20px"
              onClick={onClose}
              isDisabled={isClosingDisabled}
              data-testid={'info-bottom-drawer-close'}
            >
              Close
            </Button>
            <Text mx="auto">{header}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody background={drawerBgColor}>
          <Flex direction={'column'} justifyContent="space-between" minHeight="50vh">
            {children}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

InfoBottomDrawer.Content = InfoBottomBody;
InfoBottomDrawer.ConfirmButton = InfoBottomConfirmButton;
