import Highcharts from 'highcharts';

// Extend Highcharts types to include custom methods
declare module 'highcharts' {
  interface Point {
    triggerHighlight(event: Highcharts.PointerEventObject): void;
  }
  interface Static {
    syncExtremes(e: Highcharts.AxisSetExtremesEventObject): void;
  }
}

(function (H) {
  H.Pointer.prototype.reset = function () {
    return undefined;
  };

  /**
   * Highlight a point by showing tooltip, setting hover state and draw crosshair
   */
  H.Point.prototype.triggerHighlight = function (event) {
    event = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  H.syncExtremes = function (e: Highcharts.AxisSetExtremesEventObject) {
    const thisChart = this.chart;

    if (e.trigger !== 'syncExtremes') {
      // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function (chart: Highcharts.Chart) {
        if (chart && chart !== thisChart) {
          // It is null while updating
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
            trigger: 'syncExtremes',
          });
        }
      });
    }
  };
})(Highcharts);
