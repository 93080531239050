import { useLocation } from 'react-router-dom';

/*
 * This hook is used to replace the last segment of the current path with the provided pathLastSegments in bulk.
 * This does NOT replace multiple segments. It replaces the same segment multiple times.
 * You can use this to create multiple paths with the same last segment replaced.
 *
 * @param pathLastSegments - The last segment of the path to replace.
 * @returns An array of paths with the last segment replaced.
 * */
export const useBulkPathSegmentReplace = (pathLastSegments: string[]) => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/');

  return pathLastSegments.map((pathLastSegment) => {
    pathSegments[pathSegments.length - 1] = pathLastSegment;
    return pathSegments.join('/');
  });
};
