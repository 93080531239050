export type DiscountsFormData = {
  discounts: DiscountFormItem[];
};

export type DiscountFormItem = {
  id?: number;
  type: DiscountSelectOption | null;
  value: number | null;
};

export enum Discount {
  TOTAL_BILL = 'TOTAL_BILL',
  SUPPLY_CHARGE_DISCOUNT = 'SUPPLY_CHARGE_DISCOUNT',
  USAGE_CHARGE_DISCOUNT = 'USAGE_CHARGE_DISCOUNT',
}

export type DiscountSelectOption = {
  label: string;
  value: Discount;
};
