import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HOME_PAGE_ROUTE } from '../../common/constants';
import { selectUser } from '../user/userSlice';

export default function SiteRedirect() {
  const navigate = useNavigate();
  const { isLoggedIn, siteIDs } = useSelector(selectUser);

  useEffect(() => {
    if (isLoggedIn && siteIDs.length === 1) {
      navigate(`/site/${siteIDs[0]}/${HOME_PAGE_ROUTE}`);
    } else if (isLoggedIn && siteIDs.length > 1) {
      navigate('/sites');
    } else {
      navigate('/login');
    }
  }, [isLoggedIn, siteIDs, navigate]);

  return null;
}
