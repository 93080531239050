import React from 'react';
import { Box, Skeleton, Text, VStack } from '@chakra-ui/react';

import { COMPONENT_MIN_HEIGHT_DESKTOP, COMPONENT_MIN_HEIGHT_EXCLUDING_NAV } from '../../../common/constants';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import ProfilePageBase from '../ProfilePageBase';
import SaveOptimisationsButton from './SaveOptimisationsButton';
import SmartSaveDevices from './SmartSaveDevices';
import { SmartSaveOptionsSelect } from './SmartSaveOptionsSelect';
import { useSyncSmartSaveSettingsToRedux } from './use-sync-smartsave-settings-to-redux';

export const OptimisationSettings = () => {
  const { isLoaded, isFetchingSiteSmartSaveConfig } = useSyncSmartSaveSettingsToRedux();
  const { isDesktopViewport } = useViewportType();
  const componentMinHeight = isDesktopViewport ? COMPONENT_MIN_HEIGHT_DESKTOP : COMPONENT_MIN_HEIGHT_EXCLUDING_NAV;

  return (
    <ProfilePageBase
      title={'Optimisations'}
      containerProps={{
        height: componentMinHeight,
      }}
    >
      <VStack minH={componentMinHeight} pb={8}>
        <Box>
          <SmartSaveOptionsSelect />
          <Text p={4}>
            When turned on, SmartSave will automatically adjust your energy use to optimise solar power & periods when
            energy prices are low.
          </Text>

          <Skeleton isLoaded={isLoaded || !isFetchingSiteSmartSaveConfig}>
            <SmartSaveDevices />
          </Skeleton>
        </Box>
        <SaveOptimisationsButton />
      </VStack>
    </ProfilePageBase>
  );
};
