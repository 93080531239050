import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import GenericErrorCard from '../../../common/components/GenericErrorCard';
import PageBase from '../../../common/components/PageBase';
import TopNav from '../../../common/components/TopNav';
import { selectSite } from '../../site/siteSlice';
import { COMMON_BASE_PAGE_X_PADDING } from './constants';
import TariffFormContext, { EMPTY_TARIFF_FORM_DATA, TariffFormDataState } from './tariff-form-context';
import { useGetTariffForFormQuery } from './tariffApi';

export default function TariffRoutes() {
  const { site_id } = useSelector(selectSite);
  const { localFormData, onUpdateFormData, queryResult, isLoaded, isError } = useFormData();

  if (isError)
    return (
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <TopNav backURL={`/site/${site_id}/bills`} title="Tariffs" />
        <GenericErrorCard>An error occurred loading your tariff. Please contact support.</GenericErrorCard>
      </PageBase>
    );

  if (queryResult.isLoading || !isLoaded) return <CenteredLoader />;

  return (
    <TariffFormContext.Provider value={{ formData: localFormData, onUpdateFormData }}>
      <Outlet />
    </TariffFormContext.Provider>
  );
}

/**
 * Queries a tariff by the ID provided in the params (unless this is set to 'new', in which case there is nothing to
 * fetch), then stores this value in local state.
 * This allows the multistep form to exist in an unsaved state by having each step save itself into the ref instead
 * of updating it in the API each time (which is not possible, as the tariff must be saved as one whole object).
 *
 * @returns An object containing keys:
 *            - localFormData: The local state value of the form data. Usually, saved after each step is complete.
 *            - onUpdateFormData: A state setter function for the `localFormData` state object.
 *            - queryResult: Self-explanatory -- the result of the tariff query. Set to `null` when there is no result.
 *            - isLoaded: Whether form data has been initially loaded. Mainly useful because we want to inject the
 *                        state value as a default form value on component mount (as a parameter to `useForm`), but
 *                        displaying the form before this exists requires usage of an effect + the `reset` helper.
 *                        Just provides a better UX to block the UI for a fraction of a second between data being
 *                        loaded and data being populated into state.
 *            - isError: Error state for API loading (including population to form structure).
 */
function useFormData(): {
  localFormData: TariffFormDataState;
  onUpdateFormData: Dispatch<SetStateAction<TariffFormDataState>>;
  queryResult: ReturnType<typeof useGetTariffForFormQuery>;
  isLoaded: boolean;
  isError: boolean;
} {
  const { tariffId } = useParams<{ tariffId: string }>();
  const queryResult = useGetTariffForFormQuery(Number(tariffId), { skip: !tariffId || tariffId === 'new' });
  const { data: tariffFormData, isLoading, isError } = queryResult;

  // Facilitates persisting unsaved form data on first save attempt, where a server-side save is only possible after
  // all information has been entered. Before this, we store everything in this state object.
  const [localFormData, setLocalFormData] = useState<TariffFormDataState>(EMPTY_TARIFF_FORM_DATA);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading && !isError) {
      if (tariffFormData) {
        setLocalFormData(tariffFormData);
      }

      setIsLoaded(true);
    }
  }, [tariffFormData, isLoading, isError]);

  return {
    localFormData,
    onUpdateFormData: setLocalFormData,
    queryResult,
    isLoaded,
    isError,
  };
}
