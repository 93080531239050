import React, { useMemo } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Highcharts, { Options, PlotSeriesOptions } from 'highcharts';
import Chart from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import { CHART_HEIGHT_PX, MS_IN_ONE_HOUR } from './time-of-use/constants';

highchartsAccessibility(Highcharts);

interface PlotSeriesOptionsCustom extends PlotSeriesOptions {
  groupPadding: number;
  pointPadding: number;
}

type Props = {
  series: Highcharts.SeriesOptionsType[];
  height?: number;
};

// Positions a tick at every displayed 6 hour marker on the x-axis
const SIX_HOURLY_TICK_POSITIONS = [
  new Date(2021, 0, 1, 0).getTime(),
  new Date(2021, 0, 1, 6).getTime(),
  new Date(2021, 0, 1, 12).getTime(),
  new Date(2021, 0, 1, 18).getTime(),
  new Date(2021, 0, 2, 0).getTime(),
];

export default function TimeSpanChart({ series, height = CHART_HEIGHT_PX }: Props) {
  const textColor = useColorModeValue('black', 'white');

  const options = useMemo((): Options => {
    return {
      title: {
        text: undefined,
      },
      chart: {
        backgroundColor: 'transparent',
        type: 'column',
        height,
        style: {
          fontFamily: 'inherit',
        },
      },
      yAxis: {
        reversedStacks: false,
        title: {
          text: undefined,
        },
        height: '100%',
        offset: 0,
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
      },
      xAxis: {
        tickPositions: SIX_HOURLY_TICK_POSITIONS,
        minorTicks: true,
        gridLineWidth: 0,
        minorTickWidth: 2,
        minorTickLength: 5,
        minorGridLineWidth: 0,
        minorTickInterval: MS_IN_ONE_HOUR,
        tickLength: 8,
        tickWidth: 4,
        tickColor: textColor,
        minorTickColor: textColor,
        min: new Date(2021, 0, 1, 0).getTime(),
        max: new Date(2021, 0, 2, 0).getTime(),
        labels: {
          formatter: function () {
            const valueDate = new Date(this.value);
            const hours = valueDate.getHours();
            let displayHours = hours === 0 ? 12 : hours;
            if (displayHours > 12) {
              displayHours -= 12;
            }
            const amOrPm = hours < 12 ? 'AM' : 'PM';

            return `<span style="font-size: 12px; font-weight: bold; color: ${textColor}">${displayHours} ${amOrPm}</span>`;
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
        // @NOTE: There is a discrepancy between the types in the type package, and the types which work with the form.
        //        For this reason, we're coercing this to `PlotSeriesOptionsCustom`. Maybe a later update to the types inside the highcharts
        //        package will resolve this.
        series: {
          enableMouseTracking: false,
          animation: false,
          borderColor: undefined,
          groupPadding: 0,
          pointPadding: 0,
          turboThreshold: 1500, // 1440 minutes in a day
        } as PlotSeriesOptionsCustom,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series,
    };
  }, [series, height, textColor]);

  // Charts overflowing the parent container seems to be a common issue in Highcharts.
  // see: https://stackoverflow.com/a/34334676
  return (
    <Box maxW="100vw" position={'relative'} height={`${height}px`}>
      <Box position={'absolute'} width="100%">
        <Chart highcharts={Highcharts} options={options} />
      </Box>
    </Box>
  );
}
