import { isEqualWith, isNumber, isString } from 'lodash';

/**
 * Customizes the internal equality check used by lodash's `isEqual` function.
 * The main purpose of this customizer is to ensure string-number comparisons with equal values are considered
 * equal, since form elements (even of type `number`) will always hold number values until submit time (when they're
 * validated by isValid).
 *
 * @param a - First value for comparison
 * @param b - Second value for comparison
 * @returns A boolean, if applicable, otherwise `undefined` (in which case lodash will do the comparison itself).
 */
export function equalityCheckCustomizer(a?: object, b?: object): boolean | undefined {
  if (isString(a) && !isNaN(Number(a)) && isNumber(b)) {
    return parseFloat(a) === b;
  }
  if (isNumber(a) && isString(b) && !isNaN(Number(b))) {
    return a === parseFloat(b);
  }
  // Return undefined to let lodash handle the comparison for other cases
  return undefined;
}

/**
 * Generic wrapper for `isEqualWith` to reduce repetition.
 *
 * @param a - First value for comparison
 * @param b - Second value for comparison
 * @returns A boolean which indicates equality between the passed arguments
 */
export function customIsEqual(a?: object, b?: object): boolean {
  return isEqualWith(a, b, equalityCheckCustomizer);
}
