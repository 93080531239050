import React from 'react';
import { BoxProps } from '@chakra-ui/react';

import { MobileTopNavWithHeading } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import { TOP_BANNER_SPACING_AFFORDANCE } from '../constants';
import useTopBanner from '../hooks/use-top-banner';

type TopNavProps = (
  | {
      backURL: string;
      onClickBack?: never;
    }
  | { onClickBack: () => void; backURL?: never }
) &
  CommonProps;

type CommonProps = {
  background?: string;
  darkModeBackground?: string;
  title: string;
} & BoxProps;

// Common Cortex top nav wrapper used for cases where the top nav may be obfuscated (e.g. demo login, system fault)
export default function MobileTopNav(props: TopNavProps) {
  const hasTopBanner = useTopBanner();

  return (
    <MobileTopNavWithHeading
      {...props}
      top={hasTopBanner ? TOP_BANNER_SPACING_AFFORDANCE : 'env(safe-area-inset-top)'}
    />
  );
}
