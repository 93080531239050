import React, { useRef, useState } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import printJS from 'print-js';
import ReactDOM from 'react-dom';
import { RiDownload2Fill } from 'react-icons/ri';
import { Document, Page, pdfjs } from 'react-pdf';

import { PDFIcon, PrintIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';

import { IS_DEMO_LOGIN } from '../constants';

//fix for vite and react-pdf issues
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
interface Props {
  isOpen: boolean;
  onClose: () => void;
  contents: string;
  fileName: string;
}

export default function PDFViewerModal({ isOpen, onClose, contents, fileName }: Props) {
  if (isOpen) {
    document.body.style.overflow = 'hidden';
  }

  return isOpen
    ? ReactDOM.createPortal(<PDFViewer fileName={fileName} contents={contents} onClose={onClose} />, document.body)
    : null;
}

function PDFViewer({ onClose, contents, fileName }: Omit<Props, 'isOpen'>) {
  const { viewportType, isDesktopViewport } = useViewportType();
  const viewportTypeToPageScale = {
    mobile: 0.5,
    tablet: 0.75,
    desktop: 1,
    'desktop-lg': 1,
  };
  const base64PDF = 'data:application/pdf;base64,' + contents;
  const [numPages, setNumPages] = useState<number | null>(null);
  const ref = useRef(null);
  const initialScale = viewportTypeToPageScale[viewportType];
  const [scale, setScale] = useState(initialScale);

  function downloadPDF() {
    // Create a new `a` DOM element, click it (to download), and remove it.
    const downloadLink = document.createElement('a');
    downloadLink.href = base64PDF;
    downloadLink.download = fileName;
    downloadLink.click();

    // Remove the element we just created
    downloadLink.remove();
  }

  function printPDF() {
    printJS({
      type: 'pdf',
      printable: contents,
      base64: true,
    });
  }

  function closeDialog() {
    // Allow scroll again on the document's body.
    document.body.style.overflow = 'scroll';
    onClose();
  }

  return (
    <Box
      overflow={'scroll'}
      height={'100%'}
      width={'100%'}
      background={'rgba(0, 0, 0, 0.75)'}
      top={0}
      left={0}
      zIndex={100}
      position={'fixed'}
      color={'white'}
      data-testid="pdf-viewer-bill-info"
    >
      <SimpleGrid
        columns={3}
        top={`calc(${IS_DEMO_LOGIN ? 28 : 0}px + env(safe-area-inset-top))`}
        left={0}
        width={'100%'}
        position={'fixed'}
        py={2}
        px={isDesktopViewport ? 6 : 1}
        zIndex={9999}
        bg={'rgba(0, 0, 0, 0.41)'}
      >
        {/* Left side icons */}
        <Flex align={'center'}>
          <Box mx={2} onClick={closeDialog} as={'button'} data-testid="pdf-viewer-back-btn">
            <ChevronLeftIcon w={'28px'} h={'28px'} />
          </Box>
          <Flex align={'center'}>
            <PDFIcon mx={2} w={'26px'} h={'26px'} />
            {isDesktopViewport && <Heading size={'sm'}>{fileName}</Heading>}
          </Flex>
        </Flex>

        <Flex width={'100%'} px={6} align={'center'} justify={'center'}>
          <Box
            onClick={() => {
              setScale(Math.min(scale + 0.5, 10));
            }}
            px={2}
            as={'button'}
            fontWeight={'bolder'}
            fontSize={'30px'}
          >
            +
          </Box>
          <Text fontWeight={'bolder'}>{Math.round((scale / initialScale) * 100)}%</Text>
          <Box
            onClick={() => {
              setScale(Math.max(scale - 0.5, 0));
            }}
            px={2}
            as={'button'}
            fontWeight={'bolder'}
            fontSize={'30px'}
          >
            -
          </Box>
        </Flex>

        {/* Right side icons */}
        <Flex justifyContent={'flex-end'} mr={6}>
          <Box mx={2} onClick={printPDF} as={'button'}>
            <PrintIcon w={'26px'} h={'26px'} />
          </Box>

          <Box mx={2} onClick={downloadPDF} as={'button'}>
            <Box size={'22px'} as={RiDownload2Fill} />
          </Box>
        </Flex>
      </SimpleGrid>

      <Flex
        mt={`calc(${IS_DEMO_LOGIN ? 78 : 50}px + env(safe-area-inset-top))`}
        mb={4}
        justify={'center'}
        align={'center'}
      >
        <Document ref={ref} onLoadSuccess={({ numPages }) => setNumPages(numPages)} file={base64PDF}>
          {[...Array(numPages)]
            .map((x, i) => i + 1)
            .map((page, i) => (
              <Box my={2} key={`pdf-page-${i}`}>
                <Page
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  loading={''}
                  scale={scale}
                  pageNumber={page}
                />
              </Box>
            ))}
        </Document>
      </Flex>
    </Box>
  );
}
