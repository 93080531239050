import * as yup from 'yup';

import { MAX_DOLLARS_PER_KWH_VALUE } from '../../constants';

export const realTimeFormSchema = yup.object().shape({
  seasons: yup.array().of(
    yup.object().shape({
      dailySupplyCharge: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .moreThan(0, 'Must be greater than 0')
        .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
        .typeError('This field is required'),
      hasSolarFeedIn: yup.boolean(),
      monthlyFee: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .moreThan(0, 'Must be greater than 0')
        .typeError('This field is required'),
      hasControlledLoad: yup.boolean(),
    })
  ),
});
