import { TariffType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { EMPTY_RETAILER } from '../../../common/constants';
import { TariffFormDataState } from './tariff-form-context';

export const LIST_ITEM_HEIGHT_PX = 82;

export const COMMON_BASE_PAGE_X_PADDING = [0, 0, 20, 20, '20vw'];

export const GST_VALUE_DIVISOR = 1.1;

// We assume that any value greater than this is a user error, mistaking $/kWh for c/kWh, as it appears like this on
// some (but not most) customer bills.
export const MAX_DOLLARS_PER_KWH_VALUE = 3;

export const ITEMS_PER_PAGE = 5;

export const TARIFF_TYPE_TO_FORM_DATA_PROPERTY: Record<
  TariffType,
  Exclude<keyof TariffFormDataState, 'additionalRatesAndDiscounts' | 'basicDetails'>
> = {
  TOU: 'touRates',
  TIERED: 'tieredRates',
  FLAT: 'flatRates',
  REAL_TIME: 'realTimeRates',
};

export const EMPTY_RETAILER_OPTION = { label: EMPTY_RETAILER.name, value: EMPTY_RETAILER.id };
