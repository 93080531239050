import React, { useMemo, useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TariffType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { RTKQError } from '../../../../common/api/api-helpers';
import PageBase from '../../../../common/components/PageBase';
import RouteChangeBlocker from '../../../../common/components/RouteChangeBlocker';
import TopNav from '../../../../common/components/TopNav';
import { selectSite } from '../../../site/siteSlice';
import { selectUser } from '../../../user/userSlice';
import { mapFormValuesToAPI } from '../api-mapping-utils';
import { COMMON_BASE_PAGE_X_PADDING, TARIFF_TYPE_TO_FORM_DATA_PROPERTY } from '../constants';
import FormContainer from '../FormContainer';
import { useTariffFormContext } from '../tariff-form-context';
import {
  useCreateTariffMutation,
  useDeleteTariffMutation,
  useGetTariffForFormQuery,
  useUpdateTariffMutation,
} from '../tariffApi';
import { customIsEqual } from '../utils';
import AdditionalRatesAndDiscounts from './additional-rates-and-discounts/AdditionalRatesAndDiscounts';
import { AdditionalRatesAndDiscountsType } from './additional-rates-and-discounts/types';
import FlatRateSummary from './flat/FlatRateSummary';
import RealTimeRateSummary from './real-time/RealTimeRateSummary';
import TieredRateSummary from './tiered/TieredRateSummary';
import TOURateSummary from './time-of-use/TOURateSummary';
import { useTariffEntryAmplitudeEvent } from './utils';

const TARIFF_TYPE_TO_DISPLAY_COMPONENT: Record<TariffType, React.ReactElement> = {
  TOU: <TOURateSummary />,
  TIERED: <TieredRateSummary />,
  FLAT: <FlatRateSummary />,
  REAL_TIME: <RealTimeRateSummary />,
};

export default function Review() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const { tariffId } = useParams<{ tariffId: string }>();
  const { formData } = useTariffFormContext();
  const {
    basicDetails,
    additionalRatesAndDiscounts: { solarFeedIn, controlledLoads },
  } = formData;
  const [invalidRateTypes, setInvalidRateTypes] = useState<AdditionalRatesAndDiscountsType[]>([]);
  const { data: lastSavedTariff } = useGetTariffForFormQuery(Number(tariffId), {
    skip: !tariffId || tariffId === 'new',
  });
  const user = useSelector(selectUser);
  const toast = useToast({ isClosable: true, duration: 5_000 });
  const [createTariff, { isLoading: isCreateTariffLoading }] = useCreateTariffMutation();
  const [updateTariff, { isLoading: isUpdateTariffLoading }] = useUpdateTariffMutation();
  const [deleteTariff, { isLoading: isDeleteTariffLoading }] = useDeleteTariffMutation();
  const {
    isOpen: isDeleteAlertDialogOpen,
    onClose: onCloseDeleteAlertDialog,
    onOpen: onOpenDeleteAlertDialog,
  } = useDisclosure();
  const {
    isOpen: isControlledLoadAlertDialogOpen,
    onClose: onCloseControlledLoadAlertDialog,
    onOpen: onOpenControlledLoadAlertDialog,
  } = useDisclosure();
  useTariffEntryAmplitudeEvent();
  const [search] = useSearchParams();
  const backURL = search.get('backURL');
  const currentURL = `/site/${site.site_id}/bills/tariffs/${tariffId}/review${backURL ? '?backURL=' + backURL : ''}`;
  // There are circumstances where navigating to a new route should not trigger the unsaved changes dialog (e.g. for
  // a new tariff, if a user clicks "back" to go back to the rate configuration page, or if users directly click
  // the links to the other tariff entry pages.
  const shouldIgnoreUnsavedChangesRef = useRef(false);

  const relevantRates = useMemo(() => {
    return basicDetails ? TARIFF_TYPE_TO_FORM_DATA_PROPERTY[basicDetails?.tariffType] : null;
  }, [basicDetails]);

  if (!basicDetails) {
    return (
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <TopNav backURL={`/site/${site.site_id}/bills`} title="Review & Save" />

        <Alert status="error">
          <AlertIcon />
          Error loading energy rate data: basic details do not exist!
        </Alert>
      </PageBase>
    );
  }

  function validateRatesAgainstSiteConfiguration() {
    // No validation necessary for real-time tariffs
    if (basicDetails?.tariffType === 'REAL_TIME') return true;

    if (!site.is_consumption_site && !solarFeedIn?.rate) {
      setInvalidRateTypes(['SOLAR_FEED_IN']);
      toast({
        title: 'Solar feed-in is required',
        description: 'We detected that you have a solar system. Please add your solar feed-in rate to proceed.',
        status: 'error',
      });
      return false;
    }

    if (site.has_controlled_load && !controlledLoads?.controlledLoad && !controlledLoads?.hasControlledLoad2) {
      onOpenControlledLoadAlertDialog();
      return false;
    }

    return true;
  }

  async function handleValidateAndSaveTariff() {
    const validationResult = validateRatesAgainstSiteConfiguration();
    if (!validationResult) return;

    await handleSaveTariff();
  }

  async function handleSaveTariff() {
    try {
      shouldIgnoreUnsavedChangesRef.current = true;
      const isNewTariff = tariffId === 'new';
      const savePromise = isNewTariff
        ? createTariff({ siteId: site.site_id, body: mapFormValuesToAPI(formData) })
        : updateTariff(mapFormValuesToAPI(formData, Number(tariffId)));

      await savePromise.unwrap();
      toast({
        title: `Successfully ${isNewTariff ? 'created' : 'updated'} your energy rate.`,
        description: `Over the next 24 hours, your usage and savings data for the applicable tariff period will be updated.`,
        status: 'success',
      });
      sendAmplitudeEvent();
      navigate(`/site/${site.site_id}/bills`);
    } catch (e) {
      shouldIgnoreUnsavedChangesRef.current = false;
      console.error(e);
      const error = e as RTKQError;

      let tariffAPIData = '';
      try {
        tariffAPIData = JSON.stringify(mapFormValuesToAPI(formData));
      } catch (e) {
        console.error(e);
        Sentry.captureException(new Error(`Unable to parse form data for tariff`), {
          extra: {
            // NOTE: this must be a string, nested structures don't show in Sentry
            tariffFormData: JSON.stringify(formData),
          },
        });
      }

      Sentry.captureException(error.originalError, {
        extra: {
          // NOTE: this must be a string, nested structures don't show in Sentry
          tariffFormData: JSON.stringify(formData),
          tariffAPIData,
        },
      });

      toast({
        title: 'Unable to save energy rate',
        description: 'Please try again. If this persists, contact support.',
        status: 'error',
      });
    }
  }

  async function handleDeleteTariff() {
    try {
      await deleteTariff(Number(tariffId)).unwrap();
      toast({
        title: `Successfully deleted energy rate.`,
        status: 'success',
      });
      navigate(`/site/${site.site_id}/bills`);
    } catch (e) {
      Sentry.captureException(e);
      toast({
        title: 'Unable to delete energy rate',
        description: 'Please try again. If this persists, contact support.',
        status: 'error',
      });
    }
  }

  function sendAmplitudeEvent() {
    const journeyIdentifier = localStorage.getItem('tariffFormJourneyIdentifier');
    const eventProperties: Record<string, string> = {
      'Site ID': site.site_id.toString(),
      'User ID': user.userID.toString(),
      'Tariff Form Journey Identifier': journeyIdentifier as string,
    };
    amplitude.logEvent('Tariff Form Completed', eventProperties);
  }

  function checkForUnsavedChanges() {
    if (shouldIgnoreUnsavedChangesRef.current) return false;
    return !customIsEqual(formData, lastSavedTariff);
  }

  return (
    <RouteChangeBlocker onChangeRoute={checkForUnsavedChanges}>
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <TopNav
          onClickBack={() => {
            // When the tariff is not yet saved, moving 'back' takes the user to the rates input page, where the data
            // they entered is still locally persisted.
            // If this is an existing tariff through, it's expected that the normal unsaved changes check will occur,
            // since the back button will usually take the user away from this form entirely.
            if (tariffId === 'new') shouldIgnoreUnsavedChangesRef.current = true;
            navigate(
              backURL ??
                `/site/${site.site_id}/bills/tariffs/${tariffId}/${basicDetails!.tariffType
                  .toLowerCase()
                  .replace('_', '-')}-rate-configuration`
            );
          }}
          title="Review"
        />

        <Flex
          borderBottom="1px solid"
          borderColor="backgroundGrey.500"
          w="100%"
          as="button"
          type="button"
          mb={2}
          mt={1}
          px={[3, 3, 3, 5]}
          py={5}
          justify="space-between"
          bg="white"
          _dark={{ bg: 'gray.900' }}
          onClick={() => {
            shouldIgnoreUnsavedChangesRef.current = true;
            navigate(`/site/${site.site_id}/bills/tariffs/${tariffId}/basic-details?backURL=${currentURL}`);
          }}
          data-testid="edit-basic-details-btn"
        >
          <Text fontWeight="bold">Basic Details</Text>
          <Flex align="center">
            <Text color="primaryBranding.500">
              {basicDetails.retailer.label}, {basicDetails.tariffType.replace('_', ' ')}
            </Text>
            <ChevronRightIcon w={6} h={6} />
          </Flex>
        </Flex>

        {/* Override base x-padding to allow some elements to expand the entire card width */}
        <FormContainer px={0} data-testid="review-tariff">
          <Box px={[3, 3, 3, 5]}>
            <Flex
              as="button"
              width="100%"
              onClick={() => {
                shouldIgnoreUnsavedChangesRef.current = true;
                navigate(
                  `/site/${site.site_id}/bills/tariffs/${tariffId}/${basicDetails.tariffType
                    .toLowerCase()
                    .replace('_', '-')}-rate-configuration?backURL=${currentURL}`
                );
              }}
              justify="space-between"
              align="center"
              data-testid="edit-rates-btn"
            >
              <Heading size="md">Import Rates</Heading>
              <ChevronRightIcon w={6} h={6} />
            </Flex>
          </Box>
          <Divider mt={5} mb={4} />
          <Box px={[3, 3, 3, 5]}>
            {relevantRates === null ? (
              <Box>No seasons configured. Something went wrong with your tariff's configuration.</Box>
            ) : (
              TARIFF_TYPE_TO_DISPLAY_COMPONENT[basicDetails.tariffType]
            )}
          </Box>
          {basicDetails.tariffType !== 'REAL_TIME' && (
            <AdditionalRatesAndDiscounts
              invalidRateTypes={invalidRateTypes}
              onUpdateInvalidRateTypes={(newInvalidRateTypes) => {
                setInvalidRateTypes(newInvalidRateTypes);
              }}
            />
          )}
        </FormContainer>

        <Center flexDirection="column" mt={4}>
          <Button
            isLoading={isCreateTariffLoading || isUpdateTariffLoading}
            data-testid="submit-review-tariff"
            onClick={handleValidateAndSaveTariff}
            w={['75%', '75%', '50%']}
            rounded={20}
            mb={2}
            colorScheme="dusk100"
          >
            Save
          </Button>

          {tariffId !== 'new' && (
            <Button
              mt={2}
              isLoading={isDeleteTariffLoading}
              data-testid="delete-tariff"
              onClick={onOpenDeleteAlertDialog}
              w={['75%', '75%', '50%']}
              mb={2}
              variant={'ghost'}
              colorScheme="red"
            >
              Delete
            </Button>
          )}
        </Center>
      </PageBase>

      <AlertDialogModal
        header="Are you sure you want to delete this tariff?"
        subHeader="This will cause all related costs and savings to be re-calculated over the next 24 hours"
        isOpen={isDeleteAlertDialogOpen}
        onClose={onCloseDeleteAlertDialog}
        confirmButtonTextColor={'customRed.500'}
        onConfirm={handleDeleteTariff}
        confirmButtonName="Delete"
      />

      <AlertDialogModal
        header="We detected a controlled load on your site, but you did not provide a controlled load rate"
        subHeader={
          'If your site configuration has changed since your install, you can proceed as normal. ' +
          'Otherwise, please add your controlled load rates for more accurate costs & savings data.'
        }
        isOpen={isControlledLoadAlertDialogOpen}
        onClose={onCloseControlledLoadAlertDialog}
        onConfirm={handleSaveTariff}
        confirmButtonName="Continue"
        dialogContentTestId="controlled-load-alert-dialog-modal"
      />
    </RouteChangeBlocker>
  );
}
