import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SchedulingType } from 'clipsal-cortex-types/src/api/api-ww-switch';

import { RootState } from '../../app/store';
import { EditScheduleRoute, EditSensiboScheduleRoute, Schedule, SchedulerRoute, SolarSpongeDeviceStore } from './types';
import { ValidationResult } from './utils';

type SchedulerState = {
  /** Manual flag for unsaved changes which do not affect the schedules in state, e.g. time select */
  hasUnsavedChanges: boolean;
  /**
   * The selected schedule type.
   * A deprecated concept and no longer used for Site Device schedules.
   * The selected type is now derived from the existence of an active smartsave and/or active schedules.
   */
  selectedScheduleType: SchedulingType;
  previouslySelectedScheduleType: SchedulingType;
  route: SchedulerRoute;
  editRoute: EditScheduleRoute | EditSensiboScheduleRoute;
  selectedScheduleIndex: number | null;
  schedules: Schedule[];
  timeEditType: 'start' | 'end' | null; // Controls whether we're editing the start or end time
  isLoaded: boolean; // Separate to `isLoading` from the API state due to mapping structures
  validationResult: ValidationResult | null; // Only gets populated in the event of an error.
  previouslySavedSchedules: Schedule[];
  /**
   * The SmartSave control object associated with the site device.
   * Only used for Site Devices not Site Switches.
   */
  solarSpongeDevice: SolarSpongeDeviceStore | null;
  previouslySavedSolarSpongeDevice: SolarSpongeDeviceStore | null;
};

const initialState: SchedulerState = {
  hasUnsavedChanges: false,
  validationResult: null,
  isLoaded: false,
  selectedScheduleType: 'SUPPRESSED' as const,
  previouslySelectedScheduleType: 'SUPPRESSED' as const,
  route: SchedulerRoute.View,
  editRoute: EditScheduleRoute.EditAllSchedules,
  selectedScheduleIndex: null,
  timeEditType: null,
  schedules: [],
  previouslySavedSchedules: [],
  solarSpongeDevice: null,
  previouslySavedSolarSpongeDevice: null,
};

// We use the scheduler API to fetch data, but we keep state in this slice until it's ready to be saved back to the API.
// Due to the interplay between various parts of the scheduling system, it's best to batch validate and save schedule
// data to avoid additional complexity and provide a simpler user experience.
export const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    clearSchedulerData: () => initialState,
    setSchedulerState: (state, action: PayloadAction<Partial<SchedulerState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { clearSchedulerData, setSchedulerState } = schedulerSlice.actions;

export const selectSchedules = (state: RootState) => {
  return state.scheduler;
};

export default schedulerSlice.reducer;
