import React from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import { Center, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { TOP_BANNER_HEIGHT } from '../../../common/constants';
import useViewportType from '../../../common/hooks/use-viewport-type';
import { selectSite } from '../siteSlice';
import DeviceAlertInfoDialog from './DeviceAlertInfoDialog';

type BannerConfig = {
  text: string;
  isClickable: boolean;
  color: string;
};

export default function DeviceAlertBanner() {
  const { isDesktopViewport } = useViewportType();
  const { isOpen: isInfoDialogOpen, onOpen: onOpenInfoDialog, onClose: onCloseInfoDialog } = useDisclosure();
  const site = useSelector(selectSite);

  function getBannerConfig(): BannerConfig {
    // Regardless of any other alerts, if the site is pending we just show that.
    if (site.commissioning_status === 'PENDING') {
      return {
        text: "We're still setting up your site. Check again tomorrow.",
        isClickable: false,
        color: 'yellow.500',
      };
    }

    // Check if the meter's subscription has expired
    const hasMeterSubscriptionExpired = site.devices.find(
      (d) => d.device_type === 'METER' && d.operating_state === 'SUBSCRIPTION_EXPIRED'
    );
    if (hasMeterSubscriptionExpired) {
      return {
        text: `Your subscription has expired. ${isDesktopViewport ? 'Click' : 'Touch'} to see more.`,
        isClickable: true,
        color: 'yellow.500',
      };
    }

    // The banner is orange if the meter is online, but the inverter is offline, as that won't affect
    // usage/savings/costs data, just the live page.
    const hasFailingInverter = site.devices.find(
      (d) => d.device_type === 'INVERTER' && d.is_hybrid_inverter && !['NORMAL', null].includes(d.operating_state)
    );
    const hasWorkingMeter = site.devices.find(
      (d) => d.device_type === 'METER' && ['NORMAL', null].includes(d.operating_state)
    );
    if (hasWorkingMeter && hasFailingInverter) {
      return {
        text: `There is an issue with your system. ${isDesktopViewport ? 'Click' : 'Touch'} to see more.`,
        isClickable: true,
        color: 'orange.500',
      };
    }

    return {
      text: `There is an issue with your system. ${isDesktopViewport ? 'Click' : 'Touch'} to see more.`,
      isClickable: true,
      color: 'red.500',
    };
  }

  const { text, isClickable, color } = getBannerConfig();

  return (
    <>
      <Center
        py="4px"
        bg={color}
        color="white"
        h={TOP_BANNER_HEIGHT}
        position="fixed"
        w="100vw"
        top={'env(safe-area-inset-top)'}
        zIndex={999}
        cursor={'pointer'}
        onClick={() => {
          if (isClickable) onOpenInfoDialog();
        }}
        data-testid="device-alert-banner"
      >
        <Flex align="center">
          <WarningIcon mr={1} w={4} h={4} />
          <Text fontSize={['xs', 'sm', 'sm', 'md']} fontWeight="bold">
            {text}
          </Text>
        </Flex>
      </Center>

      <DeviceAlertInfoDialog isOpen={isInfoDialogOpen} onClose={onCloseInfoDialog} />
    </>
  );
}
