import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RPCPlan } from 'clipsal-cortex-types/src/api/api-rpc';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { selectSite } from '../../../site/siteSlice';
import { useGetSiteRetailPlanCheckQuery } from '../../billsApi';
import { RetailerImage } from '../../RetailerImage';
import { getDifferenceBetweenCosts, rpcDisplayLabelTypeToIcon } from './helpers';

export interface Props {
  plans: RPCPlan[];
  currentBillCost: number;
  totalNumPlans: number;
  isExpanded: boolean;
}

export function MobilePlansList({ plans, totalNumPlans, currentBillCost, isExpanded }: Props) {
  const navigate = useNavigate();
  const site = useSelector(selectSite);

  return (
    <>
      <Box data-testid="plan-list">
        {plans.map((plan, i) => {
          return (
            <ListRow
              key={`plan-data-${i}`}
              currentBillCost={currentBillCost}
              planIndex={i}
              plan={plan}
              totalNumPlans={totalNumPlans}
            />
          );
        })}
      </Box>

      {!isExpanded && (
        <Flex
          borderBottom="1px solid"
          borderColor={'dusk005.500'}
          _dark={{
            borderColor: 'textGrey.500',
          }}
          justify={'center'}
        >
          <Box py={2} as={'button'} rounded={20} width={'100%'}>
            <Flex
              data-testid="view-all-plans-btn"
              onClick={() => {
                navigate(`/site/${site.site_id}/bills/compare_plans/view_all`);
              }}
              justify={'space-between'}
            >
              <Heading alignSelf={'center'} size={'sm'}>
                View all plans
              </Heading>{' '}
              <ChevronRightIcon alignSelf={'center'} w={'26px'} h={'26px'} color={'textGrey.500'} />
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
}

function ListRow({
  plan,
  planIndex,
  totalNumPlans,
  currentBillCost,
}: {
  plan: RPCPlan;
  planIndex: number;
  totalNumPlans: number;
  currentBillCost: number;
}) {
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const { data: rpcData } = useGetSiteRetailPlanCheckQuery(site.site_id);

  return (
    <Box
      data-testid={`visit-plan-${planIndex}`}
      onClick={() => {
        navigate(`/site/${site.site_id}/bills/compare_plans/view_plan/${planIndex}`);
      }}
      borderTop={planIndex === 0 ? `1px solid` : undefined}
      borderBottom="1px solid"
      borderColor={'dusk005.500'}
      _dark={{
        borderColor: 'textGrey.500',
      }}
      py={5}
    >
      <Flex fontSize={'xs'} color={'textGrey.500'} justify={'space-between'}>
        <Flex>
          <Text fontWeight={'bolder'}>{planIndex + 1}&nbsp;</Text> <Text>/ {totalNumPlans} plans</Text>
        </Flex>
        <Text>Estimated bill ({rpcData?.current_bill_data.num_days} days)</Text>
      </Flex>

      <Grid my={2} templateColumns={'50px 0.6fr 0.4fr 15px'}>
        <RetailerImage id={plan.tariff.retailer.id} size={'sm'} name={plan.tariff.retailer.name} />
        <Box>
          <Heading size={'xs'}>{plan.tariff.retailer.name}</Heading>{' '}
          <Text mb={2} fontSize={'sm'}>
            {plan.tariff.name}
          </Text>
          {plan.tariff.display_labels.map((displayLabel, i) => (
            <Flex fontSize={'xs'} align={'center'} color={'textGrey.500'} key={`rpc-display-label-${i}`}>
              {rpcDisplayLabelTypeToIcon[displayLabel.type]} {displayLabel.value}
            </Flex>
          ))}
        </Box>

        <Flex textAlign={'center'} direction={'column'} align={'center'} justify={'center'}>
          <Heading size={'sm'}>{formatDollarsCents(plan.cost)}</Heading>
          {getDifferenceBetweenCosts(currentBillCost, plan.cost)}
        </Flex>

        <ChevronRightIcon alignSelf={'center'} justifySelf={'center'} w={'26px'} h={'26px'} color={'textGrey.500'} />
      </Grid>
    </Box>
  );
}
