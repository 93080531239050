/**
 * Generates an stringified query param from query param object
 * @param params = { key: value }
 */
export function getStringifiedQueryParams<T extends string = string>(params: Record<T, string | number | boolean>) {
  return Object.entries(params).reduce((acc, [key, value], index, arr) => {
    const isLastIndex = index === arr.length - 1;
    acc += `${String(key)}=${encodeURIComponent(value as string)}${isLastIndex ? '' : '&'}`;
    return acc;
  }, '');
}
