import { DeviceType } from 'clipsal-cortex-types/src/api/api-site';
import { SCHNEIDER_ELECTRIC_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { SCHNEIDER_CHARGE_PATH } from './constants';

// TODO: replace deviceManufacturer string with manufacturer id when that is available from the API
export const getConfigurableDeviceRoute = (manufacturerId: number, deviceType: DeviceType): string | null => {
  if (manufacturerId === SCHNEIDER_ELECTRIC_MANUFACTURER_ID && deviceType === 'EV_CHARGER') {
    return SCHNEIDER_CHARGE_PATH;
  }
  return null;
};
