import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ProfileIcon = createIcon({
  displayName: 'ProfileIcon',
  path: (
    <>
      <path
        d="M9 13C12.3137 13 15 10.3137 15 7C15 3.68629 12.3137 1 9 1C5.68629 1 3 3.68629 3 7C3 10.3137 5.68629 13 9 13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 19C14.7916 17.0669 11.9465 16 9 16C6.05346 16 3.20844 17.0669 1 19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  viewBox: '0 0 18 20',
});

export const ProfileActiveIcon = createIcon({
  displayName: 'ProfileActiveIcon',
  path: (
    <>
      <path
        d="M10 14.25C13.5899 14.25 16.5 11.3399 16.5 7.75C16.5 4.16015 13.5899 1.25 10 1.25C6.41015 1.25 3.5 4.16015 3.5 7.75C3.5 11.3399 6.41015 14.25 10 14.25Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6666 20.75C16.2742 18.6558 13.1921 17.5 9.99998 17.5C6.8079 17.5 3.72579 18.6558 1.33331 20.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  viewBox: '0 0 20 22',
});

export const IntegrationIcon = createIcon({
  displayName: 'IntegrationIcon',
  path: (
    <path
      d="M5.40242 3.37424L15.0711 8.8685C15.2566 8.98249 15.3494 9.25606 15.2566 9.55243C15.1639 9.8488 14.8625 9.826 14.5842 9.73481L4.91551 4.21776C4.15036 5.17526 2.80555 5.35765 1.64623 4.7877C0.486914 4.03538 0 2.50793 0.76515 1.27685C1.5303 0.045772 3.08379 -0.34179 4.33585 0.319345C5.40242 1.00328 5.79659 2.23436 5.40242 3.37424ZM16.4159 12.7669L6.74723 18.284C6.56174 18.3752 6.28351 18.3752 6.07483 18.1016C5.98208 17.8964 5.98208 17.6228 6.28351 17.4177L15.929 11.9234C15.5581 10.7835 16.0218 9.55243 17.0884 8.8685C18.3404 8.20737 19.8939 8.59493 20.6591 9.826C21.3546 11.0571 20.9605 12.5845 19.7084 13.3369C18.6418 13.9068 17.297 13.6332 16.4159 12.7669ZM2.10996 16.9617V6.49753C2.10996 6.22396 2.31864 6.01878 2.59687 6.01878C2.87511 6.01878 3.08379 6.22396 3.08379 6.49753V16.9617C4.24311 17.1441 5.21693 18.1928 5.21693 19.4239C5.21693 20.8601 4.05761 22 2.59687 22C1.13613 22 0 20.8601 0 19.4239C0 18.1928 0.857896 17.1441 2.10996 16.9617ZM0.950641 19.4239C0.950641 20.2902 1.64623 21.0425 2.59687 21.0425C3.54751 21.0425 4.24311 20.3814 4.24311 19.4239C4.24311 18.4664 3.5707 17.8052 2.59687 17.8052C1.62305 17.8052 0.950641 18.5575 0.950641 19.4239ZM19.2215 12.4933C19.9867 12.0146 20.2881 11.0571 19.8012 10.3048C19.3142 9.55243 18.3404 9.25606 17.5753 9.73481C16.8101 10.2136 16.5087 11.1483 16.9956 11.9234C17.4825 12.6757 18.4564 12.9721 19.2215 12.4933ZM2.22589 3.94419C2.99104 4.42294 3.96487 4.12657 4.4286 3.37424C4.89232 2.62192 4.63727 1.66441 3.84894 1.18566C3.0606 0.706907 2.01721 1.00328 1.5303 1.7556C1.04339 2.50793 1.43755 3.46543 2.22589 3.94419Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 22',
});

export const SystemIcon = createIcon({
  displayName: 'SystemIcon',
  path: (
    <path
      d="M12 20.25V23.25M16.5 20.25V23.25M4.9 11.251C4.47765 10.782 4.15869 10.2294 3.96382 9.6291C3.76895 9.0288 3.70254 8.39422 3.76889 7.76658C3.83525 7.13894 4.03289 6.53227 4.34898 5.98599C4.66506 5.43971 5.09253 4.96602 5.60361 4.59571C6.11469 4.22539 6.69795 3.96672 7.31551 3.83651C7.93307 3.70629 8.57112 3.70744 9.18821 3.83987C9.80529 3.97231 10.3876 4.23307 10.8974 4.60522C11.4071 4.97737 11.8329 5.45259 12.147 6M8.25 1.5V0.75M13.5 3.75L14.25 3M3 3.75L2.25 3M1.5 8.25H0.75M3 12.75L2.25 13.5M14.25 9.75V20.25M7.393 14.25H21.07M22.5 20.25H6.314C6.19403 20.25 6.0758 20.2213 5.96927 20.1661C5.86273 20.1109 5.77101 20.031 5.70182 19.933C5.63262 19.835 5.58799 19.7218 5.57166 19.6029C5.55533 19.4841 5.5678 19.363 5.608 19.25L8.822 10.25C8.87351 10.1039 8.96907 9.97731 9.09551 9.88776C9.22196 9.79821 9.37306 9.75008 9.528 9.75H18.642C18.7888 9.74998 18.9324 9.79309 19.0549 9.87399C19.1774 9.95488 19.2734 10.07 19.331 10.205L23.189 19.205C23.2378 19.319 23.2575 19.4434 23.2465 19.5669C23.2354 19.6904 23.1939 19.8093 23.1256 19.9128C23.0573 20.0164 22.9644 20.1014 22.8553 20.1602C22.7461 20.2191 22.624 20.2499 22.5 20.25Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  viewBox: '0 0 24 24',
});

export const HeadsetIcon = createIcon({
  displayName: 'HeadsetIcon',
  path: (
    <path
      d="M3.75 9C2.95435 9 2.19129 9.31607 1.62868 9.87868C1.06607 10.4413 0.75 11.2044 0.75 12V15C0.75 15.7956 1.06607 16.5587 1.62868 17.1213C2.19129 17.6839 2.95435 18 3.75 18H4.5C4.69891 18 4.88968 17.921 5.03033 17.7803C5.17098 17.6397 5.25 17.4489 5.25 17.25V9.75C5.25 9.55109 5.17098 9.36032 5.03033 9.21967C4.88968 9.07902 4.69891 9 4.5 9H3.75ZM3.75 9C3.75 6.81196 4.61919 4.71354 6.16637 3.16637C7.71354 1.61919 9.81196 0.75 12 0.75C14.188 0.75 16.2865 1.61919 17.8336 3.16637C19.3808 4.71354 20.25 6.81196 20.25 9M20.25 18H19.5C19.3011 18 19.1103 17.921 18.9697 17.7803C18.829 17.6397 18.75 17.4489 18.75 17.25V9.75C18.75 9.55109 18.829 9.36032 18.9697 9.21967C19.1103 9.07902 19.3011 9 19.5 9H20.25M20.25 18C21.0456 18 21.8087 17.6839 22.3713 17.1213C22.9339 16.5587 23.25 15.7956 23.25 15V12C23.25 11.2044 22.9339 10.4413 22.3713 9.87868C21.8087 9.31607 21.0456 9 20.25 9M20.25 18V18.75C20.25 19.5456 19.9339 20.3087 19.3713 20.8713C18.8087 21.4339 18.0456 21.75 17.25 21.75H15M15 21.75C15 21.3522 14.842 20.9706 14.5607 20.6893C14.2794 20.408 13.8978 20.25 13.5 20.25H12C11.6022 20.25 11.2206 20.408 10.9393 20.6893C10.658 20.9706 10.5 21.3522 10.5 21.75C10.5 22.1478 10.658 22.5294 10.9393 22.8107C11.2206 23.092 11.6022 23.25 12 23.25H13.5C13.8978 23.25 14.2794 23.092 14.5607 22.8107C14.842 22.5294 15 22.1478 15 21.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  viewBox: '0 0 24 24',
});

export const EmailIcon = createIcon({
  displayName: 'EmailIcon',
  path: (
    <path
      d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM10 9L2 4V14H18V4L10 9ZM10 7L18 2H2L10 7ZM2 4V2V14V4Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
});

export const AddressPinOutlinedIcon = createIcon({
  displayName: 'AddressPinOutlinedIcon',
  path: (
    <>
      <path
        d="M25 13C25 19.6274 13 33 13 33C13 33 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <circle cx="13" cy="12.9998" r="4.64286" stroke="white" />
    </>
  ),
  viewBox: '0 0 26 34',
});

export const SmartSaveIcon = createIcon({
  displayName: 'SmartSaveIcon',
  path: (
    <>
      <path
        d="M11 1C5.486 1 1 5.486 1 11C1 16.515 5.486 21 11 21C16.514 21 21 16.515 21 11C21 5.486 16.514 1 11 1"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M14 12.7308C14 9.71041 9.44 10.9887 9.44 9.06561C9.44 8.39819 10.0229 7.96833 10.9829 7.96833C11.9657 7.96833 12.6057 8.5 12.6857 9.31448H13.84C13.7486 8.0362 12.8571 7.14253 11.52 6.96154V6H10.5257V6.95023C9.16571 7.09729 8.25143 7.92308 8.25143 9.06561C8.25143 11.9842 12.7886 10.8416 12.7886 12.7421C12.7886 13.6018 12.0571 13.9864 11.0514 13.9864C9.92 13.9864 9.24571 13.3303 9.15429 12.3122H8C8.08 13.7828 9.02857 14.8009 10.5257 14.9932V15.9887L11.52 16V15.0045C12.9829 14.8575 14 14.043 14 12.7308Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 22 22',
});

export const DragIcon = createIcon({
  displayName: 'DragIcon',
  path: (
    <>
      <ellipse cx="2" cy="8" rx="2" ry="2" transform="rotate(90 2 8)" fill="currentColor" />
      <ellipse cx="2" cy="14" rx="2" ry="2" transform="rotate(90 2 14)" fill="currentColor" />
      <ellipse cx="2" cy="2" rx="2" ry="2" transform="rotate(90 2 2)" fill="currentColor" />
      <ellipse cx="8" cy="8" rx="2" ry="2" transform="rotate(90 8 8)" fill="currentColor" />
      <ellipse cx="8" cy="14" rx="2" ry="2" transform="rotate(90 8 14)" fill="currentColor" />
      <ellipse cx="8" cy="2" rx="2" ry="2" transform="rotate(90 8 2)" fill="currentColor" />
    </>
  ),
  viewBox: '0 0 10 16',
});

export const CloseIcon = createIcon({
  displayName: 'CloseIcon',
  path: (
    <>
      <circle cx="10" cy="10" r="10" fill="#2B2E2D" />
      <path
        d="M7.80076 11.7282L8.82213 10.7068L9.29354 11.1782L8.27216 12.1996C8.14199 12.3297 7.93093 12.3297 7.80076 12.1996C7.67058 12.0694 7.67058 11.8583 7.80076 11.7282ZM8.82213 9.29256L7.80076 8.27118C7.67058 8.14101 7.67058 7.92995 7.80076 7.79978C7.93093 7.6696 8.14199 7.6696 8.27216 7.79978L9.29354 8.82116L8.82213 9.29256ZM10.0006 10.4711L9.52924 9.99967L10.0006 9.52826L10.472 9.99967L10.0006 10.4711ZM11.1792 9.29256L10.7078 8.82116L11.7291 7.79978C11.8593 7.6696 12.0704 7.6696 12.2005 7.79978C12.3307 7.92995 12.3307 8.14101 12.2005 8.27118L11.1792 9.29256ZM10.7078 11.1782L11.1792 10.7068L12.2005 11.7282C12.3307 11.8583 12.3307 12.0694 12.2005 12.1996C12.0704 12.3297 11.8593 12.3297 11.7291 12.1996L10.7078 11.1782Z"
        stroke="white"
      />
    </>
  ),
  viewBox: '0 0 20 20',
});
