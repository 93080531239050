import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Filesystem } from '@capacitor/filesystem';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  CircularProgress,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { decode } from 'base64-arraybuffer';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { ImInfo } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { NoPhotosIcon, NoScansIcon, PDFOnlyIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { BillUploadException, BillUploadResult, UploadURLResult } from 'clipsal-cortex-types/src/api/api-bills';
import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import billUploadGenericErrorImg from '../../../assets/images/bill_upload_generic_error.svg';
import billUploadImg from '../../../assets/images/bill_upload_icon.svg';
import billUploadRequiresEAImg from '../../../assets/images/bill_upload_requires_ea.svg';
import billUploadSuccessImg from '../../../assets/images/bill_upload_success.svg';
import billUploadUnsupportedErrorImg from '../../../assets/images/bill_upload_unsupported_error.svg';
import bubbleBgImg from '../../../assets/images/bubble_bg.svg';
import { NetworkError, post } from '../../../common/api/api-helpers';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { selectSite } from '../../site/siteSlice';
import { useGetSiteBillsQuery } from '../billsApi';

type UploadBillsProps = { onResetBills: () => void; billUrl?: string | null; onClose: () => void };

type UploadBillDialogProps = DialogProps & UploadBillsProps;

export function UploadBillModal({ isOpen, onClose, onResetBills }: UploadBillDialogProps) {
  return (
    <Modal scrollBehavior={'inside'} size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="upload-bills-modal-body">
        <ModalHeader as={Center}>Upload bill</ModalHeader>
        <ModalCloseButton data-testid="upload-bills-close-btn" />
        <ModalBody>
          <UploadBillContents data-testid="upload-bills-modal-contents" onResetBills={onResetBills} onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function UploadBillBottomDrawer({ isOpen, onClose, onResetBills, billUrl }: UploadBillDialogProps) {
  const drawerHeaderBorderColor = useColorModeValue('dusk005.500', 'borderGrey.500');
  return (
    <Drawer onClose={onClose} placement={'bottom'} isOpen={isOpen} id={'uploadBillDrawer'}>
      <DrawerOverlay />
      <DrawerContent data-testid="upload-bills-drawer-content">
        <DrawerHeader borderBottomWidth={10} borderColor={drawerHeaderBorderColor}>
          <Center position="relative">
            <Button variant="ghost" onClick={onClose} left={0} position="absolute" size="sm">
              <CloseIcon w={[3, 4]} h={[3, 4]} />
            </Button>
            <Text>Upload bill</Text>
          </Center>
        </DrawerHeader>
        <DrawerBody px={1}>
          <UploadBillContents onResetBills={onResetBills} onClose={onClose} billUrl={billUrl} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

enum BillUploadStatus {
  InProgressPreSigning,
  InProgressUploading,
  InProgressProcessing,
  Success,
  SuccessHistorical,
  TentativeFail,
  FailUnsupported,
  FailGeneric,
}

type BillToUpload = {
  fileName: string;
  status: BillUploadStatus;
};

type UploadDialogConfig = {
  icon: string;
  buttonText: string;
  messageText: {
    heading: string;
    body: string;
  };
};

const BILL_STATUS_TO_CONFIG: Record<BillUploadStatus, UploadDialogConfig> = {
  [BillUploadStatus.InProgressPreSigning]: {
    icon: '',
    buttonText: '',
    messageText: {
      heading: '',
      body: 'Preparing your upload...',
    },
  },
  [BillUploadStatus.InProgressUploading]: {
    icon: '',
    buttonText: '',
    messageText: {
      heading: '',
      body: 'Uploading your bill to our servers...',
    },
  },
  [BillUploadStatus.InProgressProcessing]: {
    icon: '',
    buttonText: '',
    messageText: {
      heading: '',
      body: 'Processing your retail plan check...',
    },
  },
  [BillUploadStatus.Success]: {
    icon: billUploadSuccessImg,
    buttonText: 'View Plans',
    messageText: {
      heading: 'Your results are ready',
      body: 'Tap below to find the right energy plans and get saving more!',
    },
  },
  [BillUploadStatus.SuccessHistorical]: {
    icon: billUploadSuccessImg,
    buttonText: 'Back to bills',
    messageText: {
      heading: 'Your bill has been uploaded',
      body: "It looks like that was an old bill, so you won't see an updated retail plan check.",
    },
  },
  [BillUploadStatus.TentativeFail]: {
    icon: billUploadRequiresEAImg,
    buttonText: 'Done',
    messageText: {
      heading: 'Your bill needs to be reviewed by our team',
      //eslint-disable-next-line max-len
      body: 'Your bill has been received and requires a manual review by our team. Please expect it to appear in your bills page within the next 7 days. There is no need to upload it again.',
    },
  },
  [BillUploadStatus.FailUnsupported]: {
    icon: billUploadUnsupportedErrorImg,
    buttonText: 'Upload Bill',
    messageText: {
      heading: "This bill isn't supported",
      //eslint-disable-next-line max-len
      body: "It looks like you've uploaded an older bill or an unsupported bill type. Please try again with a different electricity bill.",
    },
  },
  [BillUploadStatus.FailGeneric]: {
    icon: billUploadGenericErrorImg,
    buttonText: 'Upload Bill',
    messageText: {
      heading: 'Oops! something went wrong',
      body: 'We had trouble uploading your bill. Please try again.',
    },
  },
};

const ERROR_TOAST_TIMEOUT_MS = 10_000;
const MAX_FILE_SIZE_BYTES = 50_000_000;

function UploadBillContents({ onResetBills, onClose, billUrl }: UploadBillsProps) {
  // The upload states will contain parallel indices with uploaded bills
  const [uploadedBill, setUploadedBill] = useState<BillToUpload>({
    fileName: '',
    status: BillUploadStatus.InProgressPreSigning,
  });
  const toast = useToast();
  const site = useSelector(selectSite);
  const { isDesktopViewport } = useViewportType();
  const [isUploading, setIsUploading] = useState(false);
  const { data: bills } = useGetSiteBillsQuery(site.site_id);
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const uploadBill = useCallback(
    async (name: string, pdfArrayBuffer: ArrayBuffer) => {
      const bill = {
        fileName: name,
        status: BillUploadStatus.InProgressPreSigning,
      };
      setUploadedBill(bill);

      let s3Path: string;
      try {
        const {
          signed_url: signedUrl,
          form_fields: formFields,
          plain_path: plainPath,
        } = await post<UploadURLResult>(`/v1/upload_url`, {
          file_extension: 'pdf',
          max_file_size: MAX_FILE_SIZE_BYTES,
          base_path: `bills/${site.site_id}`,
        });
        s3Path = plainPath;
        setUploadedBill({ ...bill, status: BillUploadStatus.InProgressUploading });

        const formData = new FormData();
        for (const key in formFields) {
          formData.append(key, formFields[key]);
        }
        formData.append('file', new Blob([pdfArrayBuffer], { type: formFields['Content-Type'] }));
        // Save to each presigned url
        await fetch(signedUrl, {
          method: 'POST',
          body: formData,
        });
        setUploadedBill({ ...bill, status: BillUploadStatus.InProgressProcessing });
      } catch {
        setUploadedBill({ ...bill, status: BillUploadStatus.FailGeneric });
        return;
      }
      try {
        const result = await post<BillUploadResult>(`/v2/sites/${site.site_id}/upload_bill`, {
          bill_s3_path: s3Path,
        });
        const mostRecentBill = bills![0];
        let status = BillUploadStatus.Success;
        if (result?.bill_start_date && mostRecentBill?.start_date > result.bill_start_date) {
          status = BillUploadStatus.SuccessHistorical;
        }
        onResetBills();
        setUploadedBill({ ...bill, status });
      } catch (e) {
        let billUploadStatus;
        const errorData = (e as NetworkError)?.response?.data as BillUploadException;
        if (errorData.code === 'UNSUPPORTED') billUploadStatus = BillUploadStatus.FailUnsupported;
        else if (errorData.code === 'GENERIC') billUploadStatus = BillUploadStatus.FailGeneric;
        else billUploadStatus = BillUploadStatus.TentativeFail;
        setUploadedBill({ ...bill, status: billUploadStatus });
      }
    },
    [bills, onResetBills, site.site_id]
  );

  const readFileFromUrl = useCallback(
    async (title: string) => {
      if (!billUrl) return;
      const { data: pdfBase64 } = await Filesystem.readFile({
        path: billUrl,
      });
      // remove search params from url as reloading site can start upload bill process again
      if (search.has('bill-url')) {
        search.delete('bill-url');
        if (search.has('bill-title')) search.delete('bill-title');
        navigate(
          {
            search: search.toString(),
          },
          { replace: true }
        );
      }
      setIsUploading(true);
      await uploadBill(title, decode(pdfBase64));
    },
    [billUrl, navigate, search, uploadBill]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!isEmpty(acceptedFiles)) {
        setIsUploading(true);
      }

      acceptedFiles.forEach((file) => {
        const bufferReader = new FileReader();

        bufferReader.onabort = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            duration: ERROR_TOAST_TIMEOUT_MS,
            isClosable: true,
          });
          setIsUploading(false);
          console.log('file reading was aborted');
        };

        bufferReader.onerror = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            duration: ERROR_TOAST_TIMEOUT_MS,
            isClosable: true,
          });
          setIsUploading(false);
          console.log('file reading has failed');
        };

        bufferReader.onload = async () => {
          if (file.size > MAX_FILE_SIZE_BYTES) {
            toast({
              title: 'Error reading file.',
              description: 'File is over 50MB, too large.',
              status: 'error',
              duration: ERROR_TOAST_TIMEOUT_MS,
              isClosable: true,
            });
            setIsUploading(false);
            return;
          }
          await uploadBill(file.name, bufferReader.result as ArrayBuffer);
        };
        bufferReader.readAsArrayBuffer(file);
      });
    },
    [toast, uploadBill]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'application/pdf', multiple: false });

  useEffect(() => {
    if (billUrl) {
      const title = search.get('bill-title') || 'New Bill';
      readFileFromUrl(title);
    }
  }, [billUrl, search, readFileFromUrl]);

  const modalContentConfig = BILL_STATUS_TO_CONFIG[uploadedBill.status];

  const handleGetActionFromStatus = () => {
    const failureStatuses = [BillUploadStatus.FailUnsupported, BillUploadStatus.FailGeneric];
    const billUploadFailed = failureStatuses.includes(uploadedBill.status);

    if (billUploadFailed) setIsUploading(false);
    else onClose();
  };

  const {
    icon,
    buttonText,
    messageText: { heading, body },
  } = modalContentConfig;

  return (
    <Box>
      <Center mb={6}>
        {isUploading ? (
          <VStack>
            <Center>
              <Image src={bubbleBgImg} />
              <Image position="absolute" src={icon} />

              {[
                BillUploadStatus.InProgressPreSigning,
                BillUploadStatus.InProgressUploading,
                BillUploadStatus.InProgressProcessing,
              ].includes(uploadedBill.status) && (
                <CircularProgress position="absolute" size="25px" isIndeterminate color="primaryBranding.500" />
              )}
            </Center>
            <Heading textAlign="center">{heading}</Heading>
            <Text align="center">{body}</Text>
            {buttonText && (
              <Button
                data-testid="finished-upload-btn"
                rounded={20}
                w="40%"
                variant="solid"
                colorScheme="dusk100"
                onClick={handleGetActionFromStatus}
              >
                {buttonText}
              </Button>
            )}
          </VStack>
        ) : (
          <Center
            data-testid="bill-upload-dropzone"
            flexDirection="column"
            border="2px"
            borderStyle="dashed"
            rounded={5}
            borderColor="day.500"
            bg="rgba(134, 181, 209, 0.2)"
            h="300px"
            w="80%"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Image mb={2} src={billUploadImg} alt="Upload icon" w="20%" />
            <Flex mb={2} align="center">
              <Heading mr={2} size="md">
                Electricity Bill
              </Heading>
              <UploadBillInfoPopover />
            </Flex>
            <Text mb={3} fontSize="sm">
              (original PDF only, no scans or photos)
            </Text>
            {isDesktopViewport && (
              <Text mb={5} fontSize="sm">
                Upload or drag and drop your file.
              </Text>
            )}
            <Button colorScheme="white" rounded={20} w="40%" variant="outline" mr={3} type="button">
              Upload
            </Button>
          </Center>
        )}
      </Center>
    </Box>
  );
}

function UploadBillInfoPopover() {
  const textColor = useColorModeValue('black', 'white');
  const ref = useRef<HTMLElement | null>(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  return (
    <Popover isOpen={isOpen} trigger={'hover'}>
      <>
        <PopoverTrigger>
          <Button
            data-testid="upload-bill-info-popover-trigger"
            variant={'unstyled'}
            onClick={(e) => {
              // Prevent default functionality and bubbling where clicking anywhere inside dropzone triggers
              // file upload window
              e.preventDefault();
              e.stopPropagation();
              onOpen();
            }}
          >
            <Box as={ImInfo} color={'customBlue.500'} size="18px" />
          </Button>
        </PopoverTrigger>

        <PopoverContent
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          ref={ref}
          data-testid="upload-bill-info-popover"
          p={2}
          rounded={20}
          w="inherit"
          zIndex={4}
        >
          <PopoverArrow />
          <CloseButton
            color={textColor}
            data-testid="about-bill-upload-popover-close-btn"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}
            position={'absolute'}
            right={3}
          />

          <PopoverBody>
            <Text maxW={'280px'} mb={2} color={textColor}>
              You can provide multiple bills, containing up to 24 months of recent energy usage. Please make sure you
              upload the original electricity bill in e-PDF format.
            </Text>
            <Grid gridTemplateColumns={'repeat(3, 1fr)'}>
              <Center flexDirection={'column'} p={1}>
                <PDFOnlyIcon color={textColor} />
                <Text color={textColor} fontSize="xs">
                  PDF only
                </Text>
              </Center>
              <Center flexDirection={'column'} p={1}>
                <NoPhotosIcon color={textColor} />
                <Text color={textColor} fontSize="xs">
                  No photos
                </Text>
              </Center>
              <Center flexDirection={'column'} p={1}>
                <NoScansIcon color={textColor} />
                <Text color={textColor} fontSize="xs">
                  No scans
                </Text>
              </Center>
            </Grid>
          </PopoverBody>
        </PopoverContent>
      </>
    </Popover>
  );
}
