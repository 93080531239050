import React, { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, FlexProps, IconButton, useColorModeValue } from '@chakra-ui/react';

type Props = {
  totalNumPages: number;
  currentPageNumber: number;
  onChangeToPage: (newPageNumber: number) => void;
  containerProps?: FlexProps & { 'data-testid'?: string };
  isLoaded: boolean;
};

export default function StandalonePagination({
  totalNumPages,
  currentPageNumber,
  onChangeToPage,
  containerProps,
  isLoaded,
}: Props) {
  const buttonHoverColor = useColorModeValue('rgba(0, 0, 0, 0.04)', 'black');
  const buttonBackgroundColors = useColorModeValue(
    {
      selected: 'black',
      unselected: 'white',
    },
    {
      selected: 'white',
      unselected: 'gray.900',
    }
  );
  const buttonTextColors = useColorModeValue(
    {
      selected: 'white',
      unselected: 'black',
    },
    {
      selected: 'black',
      unselected: 'white',
    }
  );
  const visiblePages: number[] = useMemo(() => {
    let firstVisiblePage = currentPageNumber - 2;
    let lastVisiblePage = currentPageNumber + 2;

    if (firstVisiblePage < 1) {
      firstVisiblePage = 1;
    }

    if (lastVisiblePage > totalNumPages) {
      lastVisiblePage = totalNumPages;
    }

    const range = lastVisiblePage - firstVisiblePage + 1;
    return [...Array(range).keys()].map((i) => i + firstVisiblePage);
  }, [currentPageNumber, totalNumPages]);

  return (
    <Flex align={'flex-end'} justify={'center'} {...containerProps}>
      {totalNumPages > 1 && (
        <IconButton
          onClick={() => {
            onChangeToPage(currentPageNumber - 1);
          }}
          isDisabled={currentPageNumber === 1 || !isLoaded}
          mr={0.5}
          size={'sm'}
          aria-label={'Move left'}
          variant={'ghost'}
          icon={<ChevronLeftIcon />}
          data-testid="pagination-previous-page"
        />
      )}

      {visiblePages.map((pageNum, i) => {
        const isSelected = pageNum === currentPageNumber;
        return (
          <Box
            mx={0.5}
            rounded={10}
            px={2}
            py={1}
            data-testid={`pagination-select-page-${pageNum}`}
            // Don't include this property at all if not selected
            data-is-selected={isSelected || undefined}
            as={'button'}
            opacity={isLoaded ? 1 : 0.5}
            onClick={() => {
              if (!isLoaded) return;
              onChangeToPage(pageNum);
            }}
            _hover={{ background: isSelected ? 'grey' : buttonHoverColor }}
            key={`page-num-btn-${i}`}
            color={isSelected ? buttonTextColors.selected : buttonTextColors.unselected}
            background={isSelected ? buttonBackgroundColors.selected : buttonBackgroundColors.unselected}
          >
            {pageNum}
          </Box>
        );
      })}

      {totalNumPages > 1 && (
        <IconButton
          onClick={() => {
            onChangeToPage(currentPageNumber + 1);
          }}
          isDisabled={currentPageNumber === totalNumPages || !isLoaded}
          ml={0.5}
          size={'sm'}
          aria-label={'Move right'}
          variant={'ghost'}
          icon={<ChevronRightIcon />}
          data-testid="pagination-next-page"
        />
      )}
    </Flex>
  );
}
