import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SmartSaveStatus } from './smart-save-helpers';
import { selectSmartSaveDevices, updateSmartSaveDevices, updateSmartSaveStatus } from './smartSaveSlice';

export const useSelectSmartSaveOption = () => {
  const smartSaveDevices = useSelector(selectSmartSaveDevices);
  const dispatch = useDispatch();

  const handleRemoveAllFromSmartSave = useCallback(() => {
    const updatedSmartSaveDevices = smartSaveDevices.map((device) => ({
      ...device,
      active: false,
    }));
    dispatch(updateSmartSaveDevices(updatedSmartSaveDevices));
    dispatch(updateSmartSaveStatus(SmartSaveStatus.OFF));
  }, [dispatch, smartSaveDevices]);

  const handleOneMonthTrialForSmartSave = useCallback(async () => {
    const hasActiveDevices = smartSaveDevices.some((device) => device.active);

    if (!hasActiveDevices) {
      const updatedSmartSaveDevices = smartSaveDevices.map((device) => ({
        ...device,
        active: true,
      }));
      dispatch(updateSmartSaveDevices(updatedSmartSaveDevices));
    }
    dispatch(updateSmartSaveStatus(SmartSaveStatus.TRIAL));
  }, [dispatch, smartSaveDevices]);

  const handleTurnOnSmartSave = useCallback(async () => {
    const hasActiveDevices = smartSaveDevices.some((device) => device.active);
    if (!hasActiveDevices) {
      const updatedSmartSaveDevices = smartSaveDevices.map((device) => ({
        ...device,
        active: true,
      }));
      dispatch(updateSmartSaveDevices(updatedSmartSaveDevices));
    }
    dispatch(updateSmartSaveStatus(SmartSaveStatus.ON));
  }, [dispatch, smartSaveDevices]);

  return {
    handleRemoveAllFromSmartSave,
    handleOneMonthTrialForSmartSave,
    handleTurnOnSmartSave,
  };
};
