import { DeviceOperatingState, DeviceType } from 'clipsal-cortex-types/src/api/api-site';

import airConDeviceImgDark from '../../../assets/images/aircon_device_dark.svg';
import airConDeviceImg from '../../../assets/images/aircon_device.svg';
import batteryDeviceImgDark from '../../../assets/images/battery_device_dark.svg';
import batteryDeviceImg from '../../../assets/images/battery_device.svg';
import cortexDeviceImgDark from '../../../assets/images/cortex_device_dark.svg';
import cortexDeviceImg from '../../../assets/images/cortex_device.svg';
import evChargerDeviceImgDark from '../../../assets/images/ev_charger_device_dark.svg';
import evChargerDeviceImg from '../../../assets/images/ev_charger_device.svg';
import inverterDeviceImgDark from '../../../assets/images/inverter_device_dark.svg';
import inverterDeviceImg from '../../../assets/images/inverter_device.svg';
import solarDeviceImgDark from '../../../assets/images/solar_device_dark.svg';
import solarDeviceImg from '../../../assets/images/solar_device.svg';
import teslaLogoImg from '../../../assets/images/tesla_logo.svg';

type DeviceStatusData = {
  title: string;
  iconLight: string;
  iconDark: string;
};

export const DEVICE_TYPE_TO_DISPLAY_DATA: Record<DeviceType, DeviceStatusData> = {
  INVERTER: {
    title: 'Solar PV Inverter',
    iconLight: inverterDeviceImg,
    iconDark: inverterDeviceImgDark,
  },
  SOLAR_MODULE: {
    title: 'Solar Module',
    iconLight: solarDeviceImg,
    iconDark: solarDeviceImgDark,
  },
  BATTERY_PACK: {
    title: 'Battery',
    iconLight: batteryDeviceImg,
    iconDark: batteryDeviceImgDark,
  },
  METER: {
    title: 'Cortex Meter',
    iconLight: cortexDeviceImg,
    iconDark: cortexDeviceImgDark,
  },
  AIR_CONDITIONER: {
    title: 'Cortex Air Conditioner',
    iconLight: airConDeviceImg,
    iconDark: airConDeviceImgDark,
  },
  EV_CHARGER: {
    title: 'Cortex EV Charger',
    iconLight: evChargerDeviceImg,
    iconDark: evChargerDeviceImgDark,
  },
  EV_CAR: {
    title: 'Tesla EV',
    iconLight: teslaLogoImg,
    iconDark: teslaLogoImg,
  },
};

export const OPERATING_STATE_TO_UI_CONFIG: Record<DeviceOperatingState, { color: string; text: string | null }> = {
  NORMAL: {
    color: 'green',
    text: null,
  },
  OFFLINE: {
    color: 'yellow.500',
    text: 'Device offline',
  },
  SUBSCRIPTION_EXPIRED: {
    color: 'yellow.500',
    text: 'Subscription expired',
  },
  NOT_CONNECTED: {
    color: 'yellow.500',
    text: 'Connection error',
  },
  UNREACHABLE: {
    color: 'yellow.500',
    text: 'Connection error',
  },
  FAULT: {
    color: 'red',
    text: 'System Fault',
  },
};

// Resolves to null for unknown operating state
export const getOperatingStateUIConfig = (operatingState: DeviceOperatingState) => {
  return operatingState in OPERATING_STATE_TO_UI_CONFIG ? OPERATING_STATE_TO_UI_CONFIG[operatingState] : null;
};

export const SCHNEIDER_CHARGE_PATH = 'schneider_charge';
