import React from 'react';
import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Card from 'clipsal-cortex-ui/src/components/card/Card';

import newBillExpectedImg from '../../../assets/images/new_bill_expected.svg';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { selectSite } from '../../site/siteSlice';

interface Props {
  onOpenUploadBillDialog: () => void;
}

export default function NewBillExpected({ onOpenUploadBillDialog }: Props) {
  const { isDesktopViewport } = useViewportType();
  const site = useSelector(selectSite);

  function getNewBillCopy() {
    const copy = 'Upload your latest bill to track your bill';
    if (['NT', 'WA'].includes(site.state)) return copy;
    else return `${copy} and receive an updated RPC.`;
  }

  return (
    <Card mt={5}>
      <Flex direction={isDesktopViewport ? 'row' : 'column'} justify={'space-between'} align={'center'} width={'100%'}>
        <Flex>
          <Image mr={6} width={'minmax(100px, 15%)'} src={newBillExpectedImg} />
          <Flex direction={'column'} justify={'center'}>
            <Heading size={'md'}>New bill expected</Heading>
            <Text>{getNewBillCopy()}</Text>
          </Flex>
        </Flex>

        <Button
          data-testid="open-upload-bill-modal-btn"
          onClick={onOpenUploadBillDialog}
          rounded={20}
          colorScheme="dusk100"
          justifySelf={'flex-end'}
          mt={isDesktopViewport ? undefined : 3}
          alignSelf={isDesktopViewport ? undefined : 'stretch'}
          isDisabled={IS_DEMO_LOGIN}
        >
          Upload bill
        </Button>
      </Flex>
    </Card>
  );
}
