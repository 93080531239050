import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';

import openLinkInCortexApp from '../../../utils/native/browser';
import ProfilePageBase from '../ProfilePageBase';

type RouteConfig = {
  title: string;
  route: string;
  isExternalLink?: boolean;
};

const ABOUT_AND_SUPPORT_PAGES: RouteConfig[] = [
  // Commenting now as we do not have energy masters tenant now
  // {
  //   title: 'Energy Masters Program',
  //   route: 'https://www.saenergymasters.com.au/',
  //   isExternalLink: true,
  // },
  {
    title: 'Terms & Conditions',
    route: 'terms-and-conditions',
  },
];

export const AboutOutlet = () => {
  return <Outlet />;
};

const About = () => {
  const navigate = useNavigate();
  const background = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.300', 'black');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'black');
  return (
    <ProfilePageBase title={'About'}>
      {ABOUT_AND_SUPPORT_PAGES.map((page) => (
        <Box bg={background} _hover={{ bg: hoverColor }} key={page.title}>
          <Flex
            data-testid={page.route}
            borderBottom="1px solid"
            borderColor={borderColor}
            justify={'space-between'}
            align={'center'}
            py={8}
            px={6}
            cursor={'pointer'}
            onClick={() => (page.isExternalLink ? openLinkInCortexApp(page.route) : navigate(page.route))}
          >
            <Heading fontSize={18}>{page.title}</Heading>
            <ChevronRightIcon w={'26px'} h={'26px'} />
          </Flex>
        </Box>
      ))}
    </ProfilePageBase>
  );
};

export default About;
