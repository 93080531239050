export type ShorthandEnvironmentType = 'STAGING' | 'PROD';
export let ENV_TYPE_SHORTHAND: ShorthandEnvironmentType = import.meta.env?.MODE === 'production' ? 'PROD' : 'STAGING';

if (window['Cypress' as any]) {
  ENV_TYPE_SHORTHAND = 'STAGING';
}

// The clipsal deploy account will always default to DEV, the schneider account will default to prod.
// This is related to the way we handle push notification certs in the back-end system.
if (import.meta.env?.VITE_DEPLOY_ACCOUNT === 'clipsal') {
  ENV_TYPE_SHORTHAND = 'STAGING';
}

// Check local storage for a different env setting (users can override this)
let fromStorage = localStorage.getItem('seHomeEnvType');
// Short-term fix to re-assign DEV to STAGING
if (fromStorage && fromStorage === 'DEV') fromStorage = 'STAGING';

if (fromStorage) {
  ENV_TYPE_SHORTHAND = fromStorage as ShorthandEnvironmentType;
}
