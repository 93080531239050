import { ChakraTheme } from '@chakra-ui/theme';
import { DeepPartial } from '@chakra-ui/theme-utils/dist/extend-theme';

import { ENERGY_MASTERS_TENANT_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import defaultBranding from './../../assets/branding/default.json';
import energyMastersBranding from './../../assets/branding/energy_masters.json';
import clipsalLogo from './../../assets/images/clipsal_logo_icon.svg';
import emLogo from './../../assets/images/em_logo_icon.svg';

export const TENANT_ID_TO_BRANDING_MAP: Record<number, Record<string, DeepPartial<ChakraTheme>>> = {
  [ENERGY_MASTERS_TENANT_ID]: energyMastersBranding as Record<string, DeepPartial<ChakraTheme>>,
};

export const getThemeForTenant = (tenantID: number): Record<string, DeepPartial<ChakraTheme>> => {
  // @TODO: Remove this when we have sites that are from Energy Masters tenant
  if (localStorage.getItem('enableEnergyMastersBranding') === 'true')
    return energyMastersBranding as Record<string, DeepPartial<ChakraTheme>>;

  const tenantHasCustomBranding = tenantID in TENANT_ID_TO_BRANDING_MAP;
  return tenantHasCustomBranding
    ? TENANT_ID_TO_BRANDING_MAP[tenantID]
    : (defaultBranding as Record<string, DeepPartial<ChakraTheme>>);
};

export const TENANT_ID_TO_LOGO_MAP: Record<number, string> = {
  [ENERGY_MASTERS_TENANT_ID]: emLogo,
};

export const getLogoIcon = (tenantID: number): string => {
  // @TODO: Remove this when we have sites that are from Energy Masters tenant
  if (localStorage.getItem('enableEnergyMastersBranding') === 'true') return emLogo;

  const tenantHasCustomLogo = tenantID in TENANT_ID_TO_LOGO_MAP;
  return tenantHasCustomLogo ? TENANT_ID_TO_LOGO_MAP[tenantID] : clipsalLogo;
};
