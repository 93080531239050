import { FlatRateSeason, FlatTariffFormData } from './types';

export const EMPTY_SEASON_TEMPLATE: FlatRateSeason = {
  seasonIndex: 0,
  name: 'Full Year',
  fromMonth: 0,
  fromDate: 1,
  toMonth: 11,
  toDate: 31,
  rate: null,
};

export const FLAT_FORM_DEFAULT_VALUE: FlatTariffFormData = {
  shouldApplySeasons: false,
  ratesAreInclusiveOfGST: true,
  seasons: [EMPTY_SEASON_TEMPLATE],
};
