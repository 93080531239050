export type ShorthandRegion = 'AU' | 'US';

export const DEFAULT_REGION = import.meta.env?.VITE_DEFAULT_REGION;

export let AWS_REGION = DEFAULT_REGION;

const fromStorage = localStorage.getItem('seHomeRegionType');

if (fromStorage) AWS_REGION = fromStorage;

const AWS_REGION_TO_SHORTHAND: Record<string, ShorthandRegion> = {
  'ap-southeast-2': 'AU',
  'us-east-1': 'US',
};

export const REGION_SHORTHAND: ShorthandRegion = AWS_REGION_TO_SHORTHAND[AWS_REGION];
