import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useReduxDispatch } from '../../../app/store';
import { SmartSaveStatus } from './smart-save-helpers';
import {
  selectSmartSaveDevices,
  selectSmartSaveStatus,
  updateSmartSaveDevices,
  updateSmartSaveStatus,
} from './smartSaveSlice';

export const useUpdateSmartSaveDevice = () => {
  const smartSaveDevices = useSelector(selectSmartSaveDevices);
  const smartSaveStatus = useSelector(selectSmartSaveStatus);

  const dispatch = useReduxDispatch();

  return useCallback(
    (siteDeviceId: number, isActive?: boolean) => {
      const updatedDevices = smartSaveDevices.map((d) => {
        if (d.site_device_id === siteDeviceId) {
          return { ...d, active: isActive ?? !d.active };
        }
        return d;
      });

      const hasActiveDevices = updatedDevices.some((d) => d.active);

      // If there are no active devices, turn off smart save
      if (!hasActiveDevices && smartSaveStatus !== SmartSaveStatus.OFF) {
        dispatch(updateSmartSaveStatus(SmartSaveStatus.OFF));
      }

      // If there are active devices, turn on smart save
      if (hasActiveDevices && smartSaveStatus === SmartSaveStatus.OFF) {
        dispatch(updateSmartSaveStatus(SmartSaveStatus.ON));
      }

      dispatch(updateSmartSaveDevices(updatedDevices));
    },
    [dispatch, smartSaveDevices, smartSaveStatus]
  );
};
