import { cloneDeep } from 'lodash';

import { CostsData, SavingsAPIData, StorageData, UsageAPIData, UsageSummaryData } from 'clipsal-cortex-types/src/api';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';

import costsGroupByTotal from './stubs/costs_groupby_total.json';
import costs from './stubs/costs.json';
import savingsCumulative from './stubs/savings_cumulative.json';
import savings from './stubs/savings_recent.json';
import last30DaysStorage from './stubs/storage-map.json';
import last30DaysUsage from './stubs/usage-map.json';
import last30DaysUsageSummary from './stubs/usage-summary-map.json';

export const DATA_MAP: Record<string, Record<string, unknown>> = {
  liveData: {},
  costs: {},
  costsGroupByTotal: {},
  savings: {},
  usage: {},
  usageSummary: {},
  storage: {},
};

export const CURRENT_DAY_IN_TIMEZONE = new Date();
export const TOTAL_NUM_DAYS = 30;

export function populateDailyDataMap(date = CURRENT_DAY_IN_TIMEZONE) {
  const numDaysToMoveBack = TOTAL_NUM_DAYS - 1;
  const dateCursor = new Date(date);

  try {
    // Traverse back 30 days and populate data into a map
    for (let i = 0; i <= numDaysToMoveBack; i++) {
      const dateFormatted = formatDate(dateCursor);
      const isDateInFuture = dateCursor.getTime() > CURRENT_DAY_IN_TIMEZONE.getTime();
      populateCostData(i, costs as CostsData[], dateFormatted, isDateInFuture);
      populateSavingsData(i, savings as SavingsAPIData[], dateFormatted, isDateInFuture);
      populateUsageData(i, last30DaysUsage as UsageAPIData[][], dateFormatted, isDateInFuture);
      populateUsageSummaryData(i, last30DaysUsageSummary as UsageSummaryData[][], dateFormatted, isDateInFuture);
      populateStorageData(i, last30DaysStorage as StorageData[][], dateFormatted, isDateInFuture);
      dateCursor.setDate(dateCursor.getDate() - 1);
    }
    // Iterate from the beginning of the year to December
    const monthDateCursor = new Date();
    monthDateCursor.setMonth(0);
    monthDateCursor.setDate(1);
    let monthIndex = 0;
    while (monthIndex < 12) {
      populateCostsGroupByTotalData(monthDateCursor);
      monthIndex++;
    }
  } catch (e) {
    console.error(e);
  }
}

function populateCostData(i: number, costs: CostsData[], dateFormatted: string, isDateInFuture: boolean) {
  const costForDate = cloneDeep((costs as CostsData[])[i]);
  costForDate.date = dateFormatted;
  if (!(dateFormatted in DATA_MAP.costs))
    DATA_MAP.costs[dateFormatted] = isDateInFuture
      ? {
          ...costForDate,
          components: [],
          assignments: [],
          total_cost: 0,
        }
      : costForDate;
}

function populateSavingsData(i: number, savings: SavingsAPIData[], dateFormatted: string, isDateInFuture: boolean) {
  const savingsForDate = cloneDeep(savings[i]);
  savingsForDate.date = dateFormatted;
  if (!(dateFormatted in DATA_MAP.savings)) DATA_MAP.savings[dateFormatted] = isDateInFuture ? null : savingsForDate;
}

function populateUsageData(i: number, usage: UsageAPIData[][], dateFormatted: string, isDateInFuture: boolean) {
  const usageForDate = cloneDeep(usage[i]);
  if (!(dateFormatted in DATA_MAP.usage))
    DATA_MAP.usage[dateFormatted] = isDateInFuture
      ? []
      : usageForDate.map((u) => {
          u.datetime = dateFormatted + ' ' + u.datetime.split(' ')[1];
          return u;
        });
}

function populateUsageSummaryData(
  i: number,
  usageSummary: UsageSummaryData[][],
  dateFormatted: string,
  isDateInFuture: boolean
) {
  const usageSummaryForDate = isDateInFuture ? [] : cloneDeep(usageSummary[i]);
  if (!(dateFormatted in DATA_MAP.usageSummary))
    DATA_MAP.usageSummary[dateFormatted] = usageSummaryForDate.map((u) => {
      u.date = dateFormatted;
      return u;
    });
}

function populateCostsGroupByTotalData(monthDateCursor: Date) {
  const firstDayOfMonthFormatted = formatDate(monthDateCursor);
  const costsValueForMonth = cloneDeep(costsGroupByTotal[0]);
  costsValueForMonth.date = firstDayOfMonthFormatted;
  DATA_MAP.costsGroupByTotal[firstDayOfMonthFormatted] = costsValueForMonth;
  monthDateCursor.setMonth(monthDateCursor.getMonth() + 1);
}

function populateStorageData(i: number, storage: StorageData[][], dateFormatted: string, isDateInFuture: boolean) {
  const storageForDate = isDateInFuture ? [] : cloneDeep(storage[i]);
  if (!(dateFormatted in DATA_MAP.storage))
    DATA_MAP.storage[dateFormatted] = storageForDate.map((u) => {
      u.datetime = dateFormatted + ' ' + u.datetime.split(' ')[1];
      return u;
    });
}

// Builds the data map once with appropriate dates from the last 30 days
populateDailyDataMap();

/**
 *  Get the start and end dates from the query params
 * @param queryParams - The query params object.
 * @returns The start and end dates.
 */
const getStartAndEndDates = (queryParams: URLSearchParams) => {
  let startDate = queryParams.get('start_date') as string;
  let endDate = queryParams.get('end_date') as string;
  const fromDateTime = queryParams.get('from_datetime') as string;
  const toDateTime = queryParams.get('to_datetime') as string;
  if (fromDateTime) startDate = fromDateTime.split(' ')[0];
  if (toDateTime) endDate = toDateTime.split(' ')[0];
  return { startDate, endDate, fromDateTime, toDateTime };
};

/**
 * Fetches data based on the query params and type. This is a universal function
 * but can be broken down into more if required.
 *
 * @param queryParams - The query params object.
 * @param type - The type of data to fetch.
 * @param returnType - The return type of the data.
 */
function fetchDataBasedOnParamsAndType(queryParams: URLSearchParams, type: string, returnType: 'array' | null = null) {
  const { startDate, endDate, fromDateTime } = getStartAndEndDates(queryParams);
  const isReturnTypeArray = returnType === 'array';
  if (startDate === endDate || fromDateTime) {
    const selectedDate = startDate.split(' ')[0];
    const data = DATA_MAP[type][formatDate(new Date(selectedDate))];
    if (data) return isReturnTypeArray ? [data] : data;

    // Some cases (e.g. week view moving back > 4 weeks) can cause fetches to savings out of the last 30 day range.
    // In that case, just get the savings for the current day.
    const defaultData = DATA_MAP[type][formatDate(CURRENT_DAY_IN_TIMEZONE)];
    return isReturnTypeArray ? [defaultData] : defaultData;
  } else {
    const cursor = new Date(startDate);
    const endCursor = new Date(endDate);
    const costsData = [];

    while (cursor.getTime() <= endCursor.getTime()) {
      const dataForDay = DATA_MAP[type][formatDate(cursor)];
      dataForDay && costsData.push(dataForDay);
      cursor.setDate(cursor.getDate() + 1);
    }
    return costsData;
  }
}

/**
 * Populates the daily data map if the start and end date are provided in the query params.
 *
 * @param queryParams - The query params object.
 * @param type - The type of data to populate.
 */
function populateDailyDataMapIfRequired(queryParams: URLSearchParams, type: string) {
  const { startDate, endDate } = getStartAndEndDates(queryParams);

  // populate data for the range if it doesn't exist
  if (startDate && endDate) {
    const hasDataForRange = startDate in DATA_MAP[type] && endDate in DATA_MAP[type];
    if (!hasDataForRange) populateDailyDataMap(new Date(endDate));
  }
}

export function getCostsData(queryParams: URLSearchParams) {
  const groupBy = queryParams.get('groupby');
  populateDailyDataMapIfRequired(queryParams, 'costs');
  if (!groupBy || groupBy === 'day') return fetchDataBasedOnParamsAndType(queryParams, 'costs') as CostsData;
  if (groupBy === 'total') return Object.values(DATA_MAP.costsGroupByTotal) as CostsData[];
  return [];
}

export function getSavingsData(queryParams: URLSearchParams) {
  const cumulative = queryParams.get('cumulative') as string;
  populateDailyDataMapIfRequired(queryParams, 'savings');
  if (!cumulative) return fetchDataBasedOnParamsAndType(queryParams, 'savings', 'array') as SavingsAPIData[];
  return savingsCumulative as SavingsAPIData[];
}

export function getUsageData(queryParams: URLSearchParams) {
  populateDailyDataMapIfRequired(queryParams, 'usage');
  return fetchDataBasedOnParamsAndType(queryParams, 'usage') as UsageAPIData;
}

export function getUsageSummaryData(queryParams: URLSearchParams) {
  populateDailyDataMapIfRequired(queryParams, 'usageSummary');
  return fetchDataBasedOnParamsAndType(queryParams, 'usageSummary') as UsageSummaryData;
}

export function getStorageData(queryParams: URLSearchParams) {
  populateDailyDataMapIfRequired(queryParams, 'storage');
  return fetchDataBasedOnParamsAndType(queryParams, 'storage') as StorageData;
}
