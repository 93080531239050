import React, { useEffect, useState } from 'react';
import { ChatIcon, ChevronLeftIcon, ChevronRightIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Ticket, TicketStatus } from 'clipsal-cortex-types/src/api/api-zendesk';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { get } from '../../common/api/api-helpers';
import { IS_DEMO_LOGIN } from '../../common/constants';
import openLinkInCortexApp from '../../utils/native/browser';
import { selectUser } from '../user/userSlice';
import { SupportRoute } from './SupportModal';

type HomeProps = {
  onChangeRoute: (newRoute: SupportRoute) => void;
  onChangeCurrentlyViewingTicket: (ticket: Ticket) => void;
};

export function Home({ onChangeRoute, onChangeCurrentlyViewingTicket }: HomeProps) {
  const user = useSelector(selectUser);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [isLoaded, setLoaded] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const pages = buildPages(tickets);
  const buttonHoverColor = useColorModeValue('rgba(0, 0, 0, 0.04)', 'black');
  const buttonBackgroundColors = useColorModeValue(
    {
      selected: 'black',
      unselected: 'white',
    },
    {
      selected: 'white',
      unselected: 'gray.900',
    }
  );
  const buttonTextColors = useColorModeValue(
    {
      selected: 'white',
      unselected: 'black',
    },
    {
      selected: 'black',
      unselected: 'white',
    }
  );

  useEffect(() => {
    async function fetchTickets() {
      const tickets = await get<Ticket[]>(`/v1/tickets`);

      setTickets(tickets);
      setLoaded(true);
    }

    fetchTickets();
  }, [user]);

  function openHelpDesk() {
    openLinkInCortexApp(
      'https://clipsalsolar.zendesk.com/hc/en-us/categories/4406043428889-Clipsal-Pulse-app-for-Consumers'
    );
  }

  if (!isLoaded) return <CenteredLoader />;

  return (
    <Box>
      <Alert mb={2} variant="left-accent" status="info">
        <AlertIcon />
        <Box>
          Before you submit a ticket, check out our{' '}
          <Link textDecoration="underline" onClick={openHelpDesk}>
            help desk
          </Link>{' '}
          to see if we already have an answer for you.
        </Box>
      </Alert>

      {tickets.length ? (
        <TableContainer>
          <Table data-testid="zendesk-ticket-table" variant="simple">
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Status</Th>
                <Th>View</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getVisibleTickets(tickets, pages, currentPageNumber).map((t) => (
                <Tr data-testid="zendesk-ticket-table-row" key={t.id}>
                  <Td>{t.subject}</Td>
                  <Td>
                    <TicketStatusBadge status={t.status} />
                  </Td>
                  <Td>
                    <IconButton
                      size="sm"
                      variant={'ghost'}
                      onClick={() => {
                        onChangeCurrentlyViewingTicket(t);
                        onChangeRoute('viewTicket');
                      }}
                      aria-label="View ticket"
                      icon={<ViewIcon />}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Bottom pagination panel */}
          <Flex mt={2} justifySelf={'flex-end'} align={'flex-end'} justify={'center'}>
            {pages.length > 1 && (
              <IconButton
                onClick={() => {
                  setCurrentPageNumber(currentPageNumber - 1);
                }}
                isDisabled={currentPageNumber === 1}
                mr={0.5}
                size={'sm'}
                aria-label={'Move left'}
                variant={'ghost'}
                icon={<ChevronLeftIcon />}
              />
            )}

            {pages.map((page, i) => {
              return (
                <Box
                  mx={0.5}
                  rounded={10}
                  px={2}
                  py={1}
                  as={'button'}
                  onClick={() => {
                    setCurrentPageNumber(page.pageNum);
                  }}
                  _hover={{ background: page.pageNum === currentPageNumber ? 'grey' : buttonHoverColor }}
                  key={`page-num-btn-${i}`}
                  color={page.pageNum === currentPageNumber ? buttonTextColors.selected : buttonTextColors.unselected}
                  background={
                    page.pageNum === currentPageNumber
                      ? buttonBackgroundColors.selected
                      : buttonBackgroundColors.unselected
                  }
                >
                  {page.pageNum}
                </Box>
              );
            })}

            {pages.length > 1 && (
              <IconButton
                onClick={() => {
                  setCurrentPageNumber(currentPageNumber + 1);
                }}
                isDisabled={currentPageNumber === pages.length}
                ml={0.5}
                size={'sm'}
                aria-label={'Move right'}
                variant={'ghost'}
                icon={<ChevronRightIcon />}
              />
            )}
          </Flex>
        </TableContainer>
      ) : (
        <Center>
          <Heading mt={3} size={'sm'}>
            You have no tickets yet. Note that new tickets sometimes take a while to appear here, so check back in a few
            minutes if you just submitted one.
          </Heading>
        </Center>
      )}

      <Button
        width={'100%'}
        alignSelf={'center'}
        rounded={20}
        colorScheme="dusk100"
        onClick={() => onChangeRoute('submitTicket')}
        mt={5}
        data-testid="submit-new-ticket"
        isDisabled={IS_DEMO_LOGIN}
      >
        <ChatIcon mr={2} />
        Submit new ticket
      </Button>
    </Box>
  );
}

type Page = {
  pageNum: number;
  indices: [number, number];
};

/**
 * Constructs an array `Page`-shaped objects, based on the number of provided  objects.
 *
 * @param tickets - The tickets to paginate.
 * @param itemsPerPage - Number of items per page.
 */
function buildPages(tickets: Ticket[], itemsPerPage = 5) {
  return tickets.reduce<Page[]>((pages, ticket, i) => {
    if (i === 0) {
      pages.push({
        pageNum: 1,
        indices: [0, 0],
      });
    } else if (i % itemsPerPage === 0) {
      pages.push({
        pageNum: i / itemsPerPage + 1,
        indices: [i, i + 3],
      });
    } else {
      pages[pages.length - 1].indices[1] = i;
    }

    return pages;
  }, []);
}

/**
 * Discerns which `Ticket` objets are currently visible, according to the current page number.
 *
 * @param tickets - The full `Ticket`s array.
 * @param pages - All pages of bills.
 * @param currentPageNumber - The current page number.
 */
function getVisibleTickets(tickets: Ticket[], pages: Page[], currentPageNumber: number) {
  let visibleTickets: Ticket[] = [];

  if (tickets.length) {
    const currentPage = pages.find((p) => p.pageNum === currentPageNumber);

    if (currentPage) {
      const [startIndex, endIndex] = currentPage.indices;

      visibleTickets = tickets.slice(startIndex, endIndex + 1);
    }
  }

  return visibleTickets;
}

const STATUS_TO_COLOR_SCHEME: Record<TicketStatus, string> = {
  new: 'blue',
  open: 'purple',
  pending: 'yellow',
  hold: 'grey',
  solved: 'green',
  closed: 'red',
};

type TicketStatusBadgeProps = { status: TicketStatus };

function TicketStatusBadge({ status }: TicketStatusBadgeProps) {
  return <Badge colorScheme={STATUS_TO_COLOR_SCHEME[status]}>{status}</Badge>;
}
