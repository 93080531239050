import React, { createRef } from 'react';
import { RouteObject } from 'react-router-dom';

import { Route } from '../../../routes';
import { RouteAuthCheck } from './RouteAuthCheck';

export const createRoutes = (routes: Route[]): RouteObject[] => {
  return routes.map(({ isPublic, element, children, ...rest }) => {
    const routeElement = isPublic ? element : <RouteAuthCheck>{element}</RouteAuthCheck>;

    return {
      ...rest,
      element: routeElement,
      children: children ? createRoutes(children) : undefined,
      nodeRef: createRef(),
    };
  });
};
