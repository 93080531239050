export type ErrorType = 'Default' | 'LimitExceededException' | 'ExpiredCodeException' | 'CodeMismatchException';

type ToastConfig = {
  title: string;
  description: string;
};
export const OTP_ERROR_TYPE_TO_TOAST_CONFIG: Record<ErrorType, ToastConfig> = {
  Default: {
    title: 'Invalid OTP code!',
    description: 'The code you entered was not valid, please double-check your email.',
  },
  LimitExceededException: {
    title: 'Maximum attempt limit reached!',
    description: 'Please try again after some time.',
  },
  ExpiredCodeException: {
    title: 'Invalid OTP code!',
    description: 'Please try resending OTP and try again!',
  },
  CodeMismatchException: {
    title: 'Invalid OTP code!',
    description: 'Please try resending OTP and try again!',
  },
};
