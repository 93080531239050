export enum SmartSaveStatus {
  ON = 'Always On',
  TRIAL = 'Try for 1 Month',
  OFF = 'Off',
}

export const SMART_SAVE_STATUS_TO_TITLE = {
  [SmartSaveStatus.ON]: 'Always allow all the time',
  [SmartSaveStatus.TRIAL]: 'Try for a month',
  [SmartSaveStatus.OFF]: 'Do not allow at all',
};

export const SAPN_ENERGY_MASTERS_WEBSITE_URL = 'https://www.saenergymasters.com.au/';
