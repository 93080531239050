import { TOTAL_MS_IN_DAY } from './constants';
import { TimeOfUsePeriodSelectOption, TOUSeason, TOUTime } from './types';

type WeekdayCoverage = {
  periods: { fromTime: Date; toTime: Date }[];
  totalTime: number; // The total amount of time covered for this weekday
};

// @NOTE: Public holidays technically aren't a day of the week, but they (alongside each weekday) need to be checked
// for coverage in the same way.
type WeekdayName = 'MON' | 'TUE' | 'WED' | 'THUR' | 'FRI' | 'SAT' | 'SUN' | 'PUBLIC_HOLIDAYS';

export function validateTimesOfUse(season: TOUSeason) {
  let includesManagedPublicHolidayCoverage = false;
  const hasRateWhichAppliesAtAllOtherTimes = checkIfHasRateWhichAppliesAtAllOtherTimes(season);
  const coveragePerDayOfWeek = createWeekdayCoverage();

  // All times of use for a season need to cover 24 hours, 7 days a week, without overlap.
  const timesOfUse = getAllTimesOfUseForSeason(season);

  // First, convert into a more effective format for measuring overlap and coverage
  for (const timeOfUse of timesOfUse) {
    for (const { value: weekdayType } of timeOfUse.applicablePeriods) {
      if (weekdayType === 'PUBLIC_HOLIDAYS') includesManagedPublicHolidayCoverage = true;

      const fromTime = convertTimeToDate(timeOfUse.fromTime);
      const toTime = convertTimeToDate(timeOfUse.toTime);

      // When toTime is earlier or the same as from fromTime, increment the date for toTime to normalize it,
      // to account for day overlap (e.g. 10pm -> 6am, 12am -> 12am)
      if (toTime.getTime() <= fromTime.getTime()) {
        toTime.setDate(toTime.getDate() + 1);
      }

      addTo(weekdayType, fromTime, toTime, coveragePerDayOfWeek);
    }
  }

  // If one time of use has been marked as a public holiday rate, validate coverage for public holidays as well
  let daysToCheckCoverage = EVERY_WEEK_DAY;
  if (includesManagedPublicHolidayCoverage) daysToCheckCoverage = [...daysToCheckCoverage, 'PUBLIC_HOLIDAYS'];

  // Sort all weekday periods by fromTime, then account for overlap
  for (const dayOfWeek of daysToCheckCoverage) {
    const { periods, totalTime } = coveragePerDayOfWeek[dayOfWeek];
    periods.sort((a, b) => a.fromTime.getTime() - b.fromTime.getTime());

    let index = 0;
    for (const period of periods) {
      if (index > 0) {
        const previousPeriod = periods[index - 1];
        // After being sorted, if this period's beginning is within the bounds of the previous (earlier) period's
        // start and end, there is an overlap.
        if (period.fromTime > previousPeriod.fromTime && period.fromTime < previousPeriod.toTime) {
          console.warn('Found overlap between periods');
          return false;
        }
      }

      if (totalTime > TOTAL_MS_IN_DAY) {
        console.warn(`Weekday ${dayOfWeek} has coverage greater than 24 hours`);
        return false;
      }

      // No need to validate coverage if another rate applies at all other times.
      if (!hasRateWhichAppliesAtAllOtherTimes && totalTime < TOTAL_MS_IN_DAY) {
        console.warn(`Missing time coverage for weekday ${dayOfWeek}`);
        return false;
      }

      index++;
    }
  }

  return true;
}

/**
 * Simple helper to convert a `TOUTime` structured object to a JS `Date`.
 * Note that the date will always be January 1st, 2021.
 *
 * @param time - The `TOUTime` object to convert
 * @returns A `Date` object, set to January 1st 2021 (to avoid leap years).
 */
export function convertTimeToDate(time: TOUTime) {
  const { minutes, amOrPm } = time;
  let { hours } = time;

  if (amOrPm === 'PM') {
    if (hours !== 12) hours += 12; // No change needed if hours is already 12 for PM
  } else if (hours === 12) {
    hours = 0; // Change to 0 for 12 AM
  }

  return new Date(2021, 0, 1, hours, minutes, 0, 0);
}

/**
 * Returns a flattened array of all times of use for a season's rates, excluding any rates which apply at all other
 * times.
 *
 * @param season - The season to collate and flatten all rates for.
 */
function getAllTimesOfUseForSeason(season: TOUSeason) {
  const allTimesOfUse = [];

  if (season.peakRate?.timesOfUse && !season.peakRate.appliesAtAllOtherTimes) {
    allTimesOfUse.push(...season.peakRate?.timesOfUse);
  }

  if (season.offPeakRate?.timesOfUse && !season.offPeakRate.appliesAtAllOtherTimes) {
    allTimesOfUse.push(...season.offPeakRate?.timesOfUse);
  }

  if (season.shoulderRates.length) {
    const shoulderTimesOfUse = season.shoulderRates
      .filter((shoulderRate) => !shoulderRate.appliesAtAllOtherTimes)
      .flatMap((shoulderRate) => shoulderRate.timesOfUse);
    allTimesOfUse.push(...shoulderTimesOfUse);
  }

  return allTimesOfUse;
}

const WEEKDAYS: WeekdayName[] = ['MON', 'TUE', 'WED', 'THUR', 'FRI'];
const WEEKENDS: WeekdayName[] = ['SAT', 'SUN'];
const EVERY_WEEK_DAY: WeekdayName[] = [...WEEKDAYS, ...WEEKENDS];
const PUBLIC_HOLIDAYS: WeekdayName[] = ['PUBLIC_HOLIDAYS'];

const TYPE_TO_PROPERTY_NAME_ARRAY: Record<TimeOfUsePeriodSelectOption['value'], WeekdayName[]> = {
  WEEKDAYS: WEEKDAYS,
  WEEKENDS: WEEKENDS,
  EVERYDAY: EVERY_WEEK_DAY,
  PUBLIC_HOLIDAYS: PUBLIC_HOLIDAYS,
};

/**
 * Helper method responsible for:
 * 1. Adding a period to a weekday's pre-defined period ranges
 * 2. Incrementing the total time coverage accordingly for that weekday
 *
 * @param type - The type of period to add the metadata to.
 * @param fromTime - The 'from time' of the period.
 * @param toTime - The 'to time' of the period
 * @param coveragePerDayOfWeek - The coverage management object which contains the original coverage metadata for each
 *                               day of the week.
 */
function addTo(
  type: TimeOfUsePeriodSelectOption['value'],
  fromTime: Date,
  toTime: Date,
  coveragePerDayOfWeek: Record<WeekdayName, WeekdayCoverage>
) {
  TYPE_TO_PROPERTY_NAME_ARRAY[type].forEach((w) => {
    const weekdayCoverage = coveragePerDayOfWeek[w];
    weekdayCoverage.periods = [
      ...weekdayCoverage.periods,
      {
        fromTime,
        toTime,
      },
    ];
    weekdayCoverage.totalTime += toTime.getTime() - fromTime.getTime();
  });
}

function createWeekdayCoverage(): Record<WeekdayName, WeekdayCoverage> {
  return {
    MON: {
      periods: [],
      totalTime: 0,
    },
    TUE: {
      periods: [],
      totalTime: 0,
    },
    WED: {
      periods: [],
      totalTime: 0,
    },
    THUR: {
      periods: [],
      totalTime: 0,
    },
    FRI: {
      periods: [],
      totalTime: 0,
    },
    SAT: {
      periods: [],
      totalTime: 0,
    },
    SUN: {
      periods: [],
      totalTime: 0,
    },
    PUBLIC_HOLIDAYS: {
      periods: [],
      totalTime: 0,
    },
  };
}

function checkIfHasRateWhichAppliesAtAllOtherTimes(season: TOUSeason) {
  return (
    season?.peakRate?.appliesAtAllOtherTimes ||
    season?.offPeakRate?.appliesAtAllOtherTimes ||
    season.shoulderRates.find((shoulderRate) => shoulderRate.appliesAtAllOtherTimes)
  );
}

export function validateAtLeastOneRateProvided(season: TOUSeason) {
  return !!season?.peakRate || !!season?.offPeakRate || !!season.shoulderRates.length;
}
