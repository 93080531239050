import { RealTimeTariffFormData } from './types';

export const REAL_TIME_FORM_DEFAULT_VALUE: RealTimeTariffFormData = {
  shouldApplySeasons: false,
  ratesAreInclusiveOfGST: false,
  seasons: [
    {
      seasonIndex: 0,
      name: 'Full Year',
      fromMonth: 0,
      fromDate: 1,
      toMonth: 11,
      toDate: 31,
      dailySupplyCharge: 0,
      hasControlledLoad: false,
      hasSolarFeedIn: false,
      monthlyFee: 0,
    },
  ],
};
