import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { Investments } from 'clipsal-cortex-types/src/api/api-investments';
import { Milestone } from 'clipsal-cortex-types/src/api/api-milestone';
import { SavingsAPIData } from 'clipsal-cortex-types/src/api/api-savings';
import { Tip } from 'clipsal-cortex-types/src/api/api-tips';
import { Fetchable } from 'clipsal-cortex-types/src/common/common-utility-types';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';

import { RootState } from '../../app/store';
import { get } from '../../common/api/api-helpers';

export interface Loading {
  isLoading: boolean;
  hasFetchedOnce: boolean;
}

export type SavingsPerDateMap = Record<string, SavingsAPIData>;

export type SavingsData = {
  savingsPerDay: SavingsPerDateMap;
  tips: Fetchable<Tip[]>;
  savingsToDate: Fetchable<SavingsAPIData[]>;
  milestones: Fetchable<Milestone[]>;
  investments: Investments & Loading;
  lastSevenDays: Fetchable<SavingsAPIData[]>;
};

export const fetchSavings = createAsyncThunk<SavingsPerDateMap, Date | undefined, { state: RootState }>(
  'savings/savingsPerDay',
  async (date, { getState }) => {
    const state = getState();

    let uri = `/v1/sites/${state.site.site_id}/savings`;

    // If we're getting data for a specific day, put that in the query parameters.
    if (date) {
      uri += `?start_date=${formatDate(date)}&end_date=${formatDate(date)}`;
    }

    const savingsData = await get<SavingsAPIData[]>(uri);

    return savingsData.reduce<SavingsPerDateMap>((acc, curr) => {
      acc[curr.date] = curr;
      return acc;
    }, {});
  }
);

export const fetchLastSevenDaysSavings = createAsyncThunk<SavingsAPIData[], void, { state: RootState }>(
  'savings/fetchLastSevenDaysSavings',
  async (_, { getState }) => {
    const { site } = getState();

    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    return await get<SavingsAPIData[]>(
      `/v1/sites/${site.site_id}/savings?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`
    );
  }
);

export const fetchSavingsPerDayBetweenDates = createAsyncThunk<
  SavingsPerDateMap,
  { start: Date; end: Date },
  { state: RootState }
>('savings/fetchSavingsPerDayBetweenDates', async ({ start, end }, { getState }) => {
  const { site } = getState();

  const savingsData = await get<SavingsAPIData[]>(
    `/v1/sites/${site.site_id}/savings?start_date=${formatDate(start)}&end_date=${formatDate(end)}`
  );

  return savingsData.reduce<SavingsPerDateMap>((acc, curr) => {
    acc[curr.date] = curr;
    return acc;
  }, {});
});

export const fetchSavingsPerDaySinceInstall = createAsyncThunk<SavingsAPIData[], void, { state: RootState }>(
  'savings/savingsSinceInstall',
  async (_, { getState }) => {
    const { site } = getState();
    return await get<SavingsAPIData[]>(`/v1/sites/${site.site_id}/savings?cumulative=true`);
  }
);

export const fetchMilestones = createAsyncThunk<Milestone[], void, { state: RootState }>(
  'savings/fetchMilestones',
  async (_, { getState }) => {
    const { site } = getState();
    return await get<Milestone[]>(`/v1/sites/${site.site_id}/milestones`);
  }
);

export const fetchTips = createAsyncThunk<Tip[], void, { state: RootState }>(
  'savings/tips',
  async (_, { getState }) => {
    const { site } = getState();
    return await get<Tip[]>(`/v1/sites/${site.site_id}/tips`);
  }
);

export const fetchInvestments = createAsyncThunk<Investments, void, { state: RootState }>(
  'savings/investments',
  async (_, { getState }) => {
    const { site } = getState();
    return await get<Investments>(`/v1/sites/${site.site_id}/investments`);
  }
);

const initialState: SavingsData = {
  investments: {
    investments: [],
    projected_payoff_date: '',
    total_investment: 0,
    total_savings: 0,
    start_date: '',
    end_date: '',
    payback_remaining: 0,
    isLoading: false,
    hasFetchedOnce: false, // @TODO: refactor nicely at some other point
  },
  lastSevenDays: {
    hasFetched: false,
    data: [],
    errorType: null,
  },
  savingsPerDay: {},
  tips: {
    hasFetched: false,
    data: [],
    errorType: null,
  },
  savingsToDate: {
    hasFetched: false,
    data: [],
    errorType: null,
  },
  milestones: {
    hasFetched: false,
    data: [],
    errorType: null,
  },
};

export const savingsSlice = createSlice({
  name: 'savings',
  initialState,
  reducers: {
    clearSavingsData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavings.fulfilled, (state, action) => {
        state.savingsPerDay = { ...state.savingsPerDay, ...action.payload };
      })
      .addCase(fetchTips.fulfilled, (state, action) => {
        state.tips = { hasFetched: true, data: action.payload, errorType: action.payload.length ? null : 'EMPTY_DATA' };
      })
      .addCase(fetchTips.rejected, (state) => {
        state.tips = { ...state.tips, hasFetched: true, errorType: 'API_ERROR' };
      })
      .addCase(fetchSavingsPerDaySinceInstall.fulfilled, (state, action) => {
        state.savingsToDate = {
          hasFetched: true,
          data: action.payload,
          errorType: action.payload.length ? null : 'EMPTY_DATA',
        };
      })

      .addCase(fetchSavingsPerDaySinceInstall.rejected, (state) => {
        state.savingsToDate = { ...state.savingsToDate, hasFetched: true, errorType: 'API_ERROR' };
      })
      .addCase(fetchMilestones.fulfilled, (state, action) => {
        state.milestones = { hasFetched: true, data: action.payload, errorType: null };
      })
      .addCase(fetchInvestments.pending, (state) => {
        state.investments.isLoading = true;
      })
      .addCase(fetchInvestments.fulfilled, (state, action) => {
        state.investments = { ...state.investments, ...action.payload };
        state.investments.isLoading = false;
        state.investments.hasFetchedOnce = true;
      })
      .addCase(fetchInvestments.rejected, (state) => {
        state.investments.isLoading = false;
        state.investments.hasFetchedOnce = true;
      })
      .addCase(fetchSavingsPerDayBetweenDates.fulfilled, (state, action) => {
        state.savingsPerDay = { ...state.savingsPerDay, ...action.payload };
      })
      .addCase(fetchLastSevenDaysSavings.fulfilled, (state, action) => {
        state.lastSevenDays = {
          hasFetched: true,
          data: action.payload,
          errorType: action.payload.length ? null : 'EMPTY_DATA',
        };
      })
      .addCase(fetchLastSevenDaysSavings.rejected, (state) => {
        state.lastSevenDays = {
          ...state.lastSevenDays,
          hasFetched: true,
          errorType: 'API_ERROR',
        };
      });
  },
});

export const { clearSavingsData } = savingsSlice.actions;

export const selectInvestments = (state: RootState) => {
  return state.savings.investments;
};

export const selectTips = (state: RootState) => {
  return state.savings.tips;
};

export const selectMilestones = (state: RootState) => {
  return state.savings.milestones;
};

export const selectSavingsPerDay = (state: RootState) => {
  return state.savings.savingsPerDay;
};

export const selectSavingsToDate = (state: RootState) => {
  return state.savings.savingsToDate;
};

export const selectLastSevenDaysSavings = (state: RootState) => {
  return state.savings.lastSevenDays;
};

export const selectSavingsForDate = (date: Date) => {
  return createSelector([(state: RootState) => state.savings.savingsPerDay], (savingsPerDay) => {
    return savingsPerDay[formatDate(date)];
  });
};

export default savingsSlice.reducer;
