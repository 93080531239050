import React, { useCallback, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { Ticket, TicketToSave, ZendeskAttachment, ZendeskUser } from 'clipsal-cortex-types/src/api/api-zendesk';

import uploadAttachmentImage from '../../assets/images/upload_attachment.svg';
import { post } from '../../common/api/api-helpers';
import { selectSite } from '../site/siteSlice';
import { selectUser } from '../user/userSlice';

const submitTicketSchema = yup.object().shape({
  subject: yup.string().required(),
  description: yup.string().required(),
});

type SubmitTicketFormData = {
  subject: string;
  description: string;
};

type SubmitTicketProps = {
  zendeskUser: ZendeskUser | null;
  onChangeUser: (user: ZendeskUser) => void;
  onChangeCurrentlyViewingTicket: (ticket: Ticket) => void;
};

export type UploadedFile = {
  name: string;
  url: string;
  fileData: string; // Only relevent for non-persisted images
  type: string;
};

export function SubmitTicket({ onChangeCurrentlyViewingTicket }: SubmitTicketProps) {
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SubmitTicketFormData>({
    resolver: yupResolver(submitTicketSchema),
  });
  const toast = useToast();
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const handleFileUpload = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading was aborted');
        };
        reader.onerror = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading has failed');
        };
        reader.onload = async () => {
          const fileData = reader.result as string;

          setUploadedFiles([
            ...uploadedFiles,
            {
              name: file.name,
              url: fileData,
              fileData,
              type: file.type,
            },
          ]);
        };

        reader.readAsDataURL(file);
      });
    },
    [toast, uploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: ['image/*', 'application/pdf'],
    multiple: true,
  });

  async function handleSubmit({ subject, description }: SubmitTicketFormData) {
    try {
      const body: TicketToSave = {
        body: description,
        description,
        subject,
        tenant: user.tenantID.toString(),
      };

      try {
        // Add uploaded files to the ticket
        const savedUploadedFiles = await Promise.all(
          uploadedFiles.map((f) =>
            post<ZendeskAttachment>(`/v1/upload_file_to_zendesk`, {
              file_base64: f.fileData,
              file_name: f.name,
              content_type: f.type,
            })
          )
        );

        if (savedUploadedFiles.length) {
          body.attachment_tokens = savedUploadedFiles.map((savedFile) => savedFile.token);
        }
      } catch (e) {
        toast({
          title: `Error uploading attachments`,
          description: 'Please contact support directly about this issue.',
          status: 'error',
          isClosable: true,
        });
      }

      // A user will be auto-created when a ticket is created. This takes some time, so we ping the endpoint based
      // on an interval at the top level.
      const ticket = await post<Ticket>(`/v1/sites/${site.site_id}/create_ticket`, body);

      toast({
        title: `Successfully created ticket #${ticket.id}`,
        description: "We'll get back to you as soon as we can.",
        status: 'success',
        isClosable: true,
      });

      onChangeCurrentlyViewingTicket(ticket);
    } catch (e) {
      toast({
        title: 'Error creating ticket',
        description: 'Please contact support directly about this issue.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  async function handleDeleteFile(file: UploadedFile) {
    setUploadedFiles(uploadedFiles.filter((existingFile) => existingFile.url !== file.url));
  }

  const existingFilesContents = (
    <Box>
      <Heading mt={2} size="md">
        Files
      </Heading>
      <Flex flexWrap="wrap">
        {uploadedFiles.map((file, i) => (
          <Center w="100px" h="100px" position="relative" key={i}>
            <Center
              as="button"
              type="button"
              onClick={() => handleDeleteFile(file)}
              h="25px"
              w="25px"
              bg="black"
              rounded={100}
              position="absolute"
              top={0}
              right={0}
            >
              <CloseIcon fontSize="xs" color="white" />
            </Center>
            <Image
              rounded={15}
              w="80px"
              h="80px"
              objectFit={'cover'}
              src={file.type.includes('image') ? file.url : uploadAttachmentImage}
              alt={'file'}
            />
          </Center>
        ))}

        <Center w="100px" h="100px">
          <Center
            rounded={15}
            justifySelf={'center'}
            flexDirection="column"
            border="2px"
            borderStyle="dashed"
            borderColor="#86B5D1"
            bg="rgba(134, 181, 209, 0.2)"
            w="80px"
            h="80px"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Image mb={2} src={uploadAttachmentImage} alt="Upload icon" w="50%" />{' '}
          </Center>
        </Center>
      </Flex>
    </Box>
  );

  const noFilesContents = (
    <Center flexDirection={'column'} mt={3}>
      <Heading size="md">Upload a file (optional)</Heading>
      <Center
        flexDirection="column"
        border="2px"
        borderStyle="dashed"
        rounded={5}
        borderColor="#86B5D1"
        bg="rgba(134, 181, 209, 0.2)"
        h="300px"
        w="80%"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Image mb={1} src={uploadAttachmentImage} alt="Upload a file" w="20%" />
        <Text mb={3}>Upload a file here.</Text>
        <Button colorScheme="white" rounded={20} w="40%" variant="outline" mr={3} type="button">
          Upload
        </Button>
      </Center>
    </Center>
  );

  return (
    <Box data-testid="submit-ticket-form" as={'form'} onSubmit={handleFormSubmit(handleSubmit)}>
      <FormControl mb={2} isInvalid={!!errors.subject} width={'100%'}>
        <FormLabel>Subject</FormLabel>
        <Input data-testid="ticket-subject" {...register('subject')} placeholder={'Unable to upload a bill'} />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.description} width={'100%'}>
        <FormLabel>Description</FormLabel>
        <Textarea
          data-testid="ticket-description"
          {...register('description')}
          placeholder={'The bill upload keeps failing'}
        />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      {uploadedFiles.length ? existingFilesContents : noFilesContents}

      <Button
        type="submit"
        mt={6}
        width={'100%'}
        alignSelf={'center'}
        rounded={20}
        colorScheme="dusk100"
        data-testid="submit-ticket-data-btn"
        isLoading={isSubmitting}
      >
        Submit
      </Button>
    </Box>
  );
}
