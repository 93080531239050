import React, { useState } from 'react';
import { Button, ButtonProps, Center, useColorModeValue, useDisclosure } from '@chakra-ui/react';

import TimePicker from 'clipsal-cortex-ui/src/components/time-picker/TimePicker';
import { capitalizeFirst } from 'clipsal-cortex-utils/src/formatting/formatting';

import Dialog from '../../../../../common/components/Dialog';
import { TOUTime } from './types';

type Props = {
  type: 'START' | 'END';
  onChange: (newValue: TOUTime) => void;
  value: TOUTime;
  buttonProps?: ButtonProps & { ['data-testid']?: string };
  ['data-testid']?: string;
};

const MINUTES_5_MIN_INTERVAL = Array.from(Array(12)).map((_, i) => {
  const minuteNumber = i * 5;
  return minuteNumber < 10 ? `0${minuteNumber}` : minuteNumber;
});

export default function TimeSelectSlider(props: Props) {
  const { value, onChange, type, buttonProps } = props;
  // Track the value in this specific component, to avoid updating elsewhere until the user saves.
  const [localValue, setLocalValue] = useState<TOUTime>(value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timePickerGradientColor = useColorModeValue('253, 253, 253', '23, 25, 35');
  const timePickerBorderColor = useColorModeValue('black', 'white');
  const { hours, minutes, amOrPm } = localValue;

  function getDisplayedTime() {
    const { minutes, amOrPm } = value;
    let { hours } = value;
    hours = hours === 0 ? 12 : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${formattedMinutes} ${amOrPm}`;
  }

  return (
    <>
      <Button
        border="1px solid"
        borderColor="rgba(60, 60, 67, 0.3)"
        size="sm"
        onClick={onOpen}
        color={'#57BB59'}
        bg="backgroundLightMode.500"
        _dark={{
          bg: 'customGray.500',
        }}
        {...buttonProps}
      >
        {getDisplayedTime()}
      </Button>

      <Dialog
        title={`Edit ${capitalizeFirst(type.toLowerCase())} Time`}
        showCloseButton
        onClose={onClose}
        isOpen={isOpen}
        data-testid="time-select-dialog"
      >
        <Center>
          <TimePicker
            defaultHours={hours === 0 ? 12 : hours}
            defaultMinutes={minutes}
            defaultAmOrPm={amOrPm}
            onChange={(newValue) => {
              setLocalValue((prev) => ({
                ...prev,
                ...newValue,
              }));
            }}
            gradientColor={timePickerGradientColor}
            borderColor={timePickerBorderColor}
            minuteOptions={MINUTES_5_MIN_INTERVAL}
          />
        </Center>

        <Center mt={6}>
          <Button
            data-testid="submit-time-select-modal-form-btn"
            type="submit"
            w={['75%', '75%', '50%']}
            rounded={20}
            onClick={() => {
              onChange(localValue);
              onClose();
            }}
            colorScheme="dusk100"
          >
            Done
          </Button>
        </Center>
      </Dialog>
    </>
  );
}
