import React from 'react';
import { Box, Button, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { IoCheckmark } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { SMART_SAVE_STATUS_TO_TITLE, SmartSaveStatus } from './smart-save-helpers';
import { selectSmartSaveStatus } from './smartSaveSlice';
import { useSelectSmartSaveOption } from './use-select-smartsave-option';

export const SmartSaveOptionsSelect = () => {
  const borderColor = useColorModeValue('gray.300', 'gray.500');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'gray.600');
  const background = useColorModeValue('white', 'dusk100.800');

  const smartSaveStatus = useSelector(selectSmartSaveStatus);
  const { handleRemoveAllFromSmartSave, handleOneMonthTrialForSmartSave, handleTurnOnSmartSave } =
    useSelectSmartSaveOption();

  return (
    <Box>
      {Object.entries(SmartSaveStatus).map(([key, value], index, values) => {
        const isLastIndex = index === values.length - 1;
        const isSelected = smartSaveStatus === value;
        return (
          <Button
            key={key}
            as={'button'}
            borderRadius={0}
            w={'100%'}
            fontWeight={isSelected ? 600 : 400}
            onClick={() => {
              // If already selected, then close the drawer
              if (isSelected) return;

              const solarSaveStatusToEventHandler = {
                [SmartSaveStatus.ON]: handleTurnOnSmartSave,
                [SmartSaveStatus.TRIAL]: handleOneMonthTrialForSmartSave,
                [SmartSaveStatus.OFF]: handleRemoveAllFromSmartSave,
              };

              return solarSaveStatusToEventHandler[value]();
            }}
            borderBottom={isLastIndex ? undefined : '1px solid'}
            borderColor={borderColor}
            bg={background}
            _hover={{ bg: hoverColor }}
            justifyContent={'flex-start'}
            py={7}
            data-testid={`option-select-item-${key.toLowerCase()}`}
          >
            <Text>{SMART_SAVE_STATUS_TO_TITLE[value as SmartSaveStatus]}</Text>
            {isSelected && (
              <Icon
                as={IoCheckmark}
                ml="auto"
                color="green.500"
                w={6}
                h={6}
                mr={2}
                data-testid="option-selected-checkmark"
              />
            )}
          </Button>
        );
      })}
    </Box>
  );
};
