import { RateTier } from '../types';
import { TimeOfUse, TOURate, TOURateType, TOUSeason, TOUTariffFormData } from './types';

export const TOTAL_MS_IN_MINUTE = 60 * 1000;
export const TOTAL_MS_IN_DAY = 24 * 60 * TOTAL_MS_IN_MINUTE;

export const EMPTY_TIER_TEMPLATE: RateTier = {
  rate: null,
  upperLimitKWh: null,
};

export const EMPTY_TIME_OF_USE_TEMPLATE: TimeOfUse = {
  fromTime: {
    minutes: 0,
    hours: 0,
    amOrPm: 'AM',
  },
  toTime: {
    minutes: 0,
    hours: 0,
    amOrPm: 'AM',
  },
  applicablePeriods: [{ label: 'Everyday', value: 'EVERYDAY' }],
};

export const EMPTY_SEASON_TEMPLATE: TOUSeason = {
  seasonIndex: 0,
  name: 'Full Year',
  fromMonth: 0,
  fromDate: 1,
  toMonth: 11,
  toDate: 31,
  peakRate: getEmptyRateTemplate({
    type: 'PEAK',
    timesOfUse: [
      {
        fromTime: {
          minutes: 0,
          hours: 2,
          amOrPm: 'PM',
        },
        toTime: {
          minutes: 0,
          hours: 8,
          amOrPm: 'PM',
        },
        applicablePeriods: [{ label: 'Weekdays', value: 'WEEKDAYS' }],
      },
    ],
  }),
  offPeakRate: getEmptyRateTemplate({ type: 'OFF-PEAK', appliesAtAllOtherTimes: true }),
  shoulderRates: [],
};

export const TOU_FORM_DEFAULT_VALUE: TOUTariffFormData = {
  shouldApplySeasons: false,
  ratesAreInclusiveOfGST: true,
  seasons: [EMPTY_SEASON_TEMPLATE],
};

export const TOU_PERIOD_SELECT_OPTIONS: TimeOfUse['applicablePeriods'] = [
  { label: 'Weekdays', value: 'WEEKDAYS' },
  { label: 'Weekends', value: 'WEEKENDS' },
  { label: 'Everyday', value: 'EVERYDAY' },
  { label: 'Public Holidays', value: 'PUBLIC_HOLIDAYS' },
];

type EmptyRateTemplateParams = {
  type: TOURateType;
  appliesAtAllOtherTimes?: boolean;
  timesOfUse?: TimeOfUse[];
};

export function getEmptyRateTemplate({
  type,
  appliesAtAllOtherTimes = false,
  timesOfUse = [EMPTY_TIME_OF_USE_TEMPLATE],
}: EmptyRateTemplateParams): TOURate {
  return {
    type,
    appliesAtAllOtherTimes,
    timesOfUse,
    tiers: [EMPTY_TIER_TEMPLATE],
  };
}
