import React from 'react';
import { InputGroup } from '@chakra-ui/input';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputRightAddon,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import Dialog from '../../../../../../common/components/Dialog';
import { MAX_DOLLARS_PER_KWH_VALUE } from '../../../constants';
import { useTariffFormContext } from '../../../tariff-form-context';
import { SolarFeedInFormData } from './types';

const solarFeedInFormSchema = yup.object().shape({
  rate: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0)
    .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
    .typeError('This field is required')
    .required(),
});

export default function SolarFeedInFormDialog({ isOpen, onClose }: DialogProps) {
  const {
    formData: {
      additionalRatesAndDiscounts: { solarFeedIn },
    },
    onUpdateFormData,
  } = useTariffFormContext();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<SolarFeedInFormData>({
    resolver: yupResolver(solarFeedInFormSchema),
    defaultValues: solarFeedIn ?? {},
  });
  const { isOpen: isAlertDialogOpen, onClose: onCloseAlertDialog, onOpen: onOpenAlertDialog } = useDisclosure();

  async function handleSubmitSolarFeedInForm(solarFeedInFormData: SolarFeedInFormData) {
    onUpdateFormData((p) => ({
      ...p,
      additionalRatesAndDiscounts: { ...p.additionalRatesAndDiscounts, solarFeedIn: solarFeedInFormData },
    }));
    onClose();
  }

  function handleClearForm() {
    reset({});
    onUpdateFormData((p) => ({
      ...p,
      additionalRatesAndDiscounts: { ...p.additionalRatesAndDiscounts, solarFeedIn: null },
    }));
    onClose();
  }

  return (
    <>
      <Dialog showCloseButton title="Solar Feed-in Rates" isOpen={isOpen} onClose={onClose}>
        <Box
          data-testid="solar-feed-in-rate-form"
          mx={4}
          as="form"
          onSubmit={handleSubmit(handleSubmitSolarFeedInForm)}
        >
          <FormControl mt={2} isInvalid={!!errors?.rate}>
            <FormLabel mb={0} mr={0} fontWeight="bold">
              Solar Feed-in Rate
            </FormLabel>
            <InputGroup>
              <Input
                data-testid="solar-feed-in-rate-input"
                {...register('rate')}
                type={'number'}
                onWheel={(e) => e.currentTarget.blur()}
                min={0}
                step="0.0000000001"
              />
              <InputRightAddon>$ / kWh</InputRightAddon>
            </InputGroup>
            <FormErrorMessage data-testid="solar-feed-in-rate-error-message">{errors?.rate?.message}</FormErrorMessage>
          </FormControl>

          <Center flexDirection="column" mt={6}>
            <Button
              data-testid="submit-solar-feed-in-form-btn"
              type="submit"
              w={['75%', '75%', '50%']}
              rounded={20}
              colorScheme="dusk100"
            >
              Add
            </Button>

            <Button
              data-testid="clear-solar-feed-in-btn"
              onClick={onOpenAlertDialog}
              mt={2}
              variant="ghost"
              w={['75%', '75%', '50%']}
              colorScheme={'red'}
            >
              Clear All
            </Button>
          </Center>
        </Box>
      </Dialog>
      <AlertDialogModal
        header="Are you sure you want to clear your solar feed in rate?"
        subHeader=" "
        isOpen={isAlertDialogOpen}
        onClose={onCloseAlertDialog}
        confirmButtonTextColor={'customRed.500'}
        onConfirm={handleClearForm}
        confirmButtonName="Clear"
      />
    </>
  );
}
